import intersection from 'lodash/intersection';
import {
  Role,
  User,
} from '../../graphql-generate/graphql-types';

export enum PrivilegesList {
  'SEE_NUMBER_OPPORTUNITIES',
  'SEE_ADMIN_ALERT_BANNER',
  'SEE_NUMBER_MEMBERS',
  'SEE_MY_TEAM_MENU',
  'ASSIGN_NEW_MEMBERS',
  'ASSIGN_MEMBERS',
}

function ifUserHasOneOfRoles(user: User, roles: Role[]): boolean {
  return intersection(roles, user.roles).length > 0;
}

function canUserSeeAdminAlertBanner(user: User): boolean {
  return ifUserHasOneOfRoles(user, [Role.Admin]);
}

function canUserSeeMyTeamMenu(user: User): boolean {
  return ifUserHasOneOfRoles(user, [Role.Admin]);
}

function canUserAssingMembers(user: User): boolean {
  return ifUserHasOneOfRoles(user, [Role.Admin, Role.Pathfinder]);
}

function canUserAssignNewMembers(user: User): boolean {
  return ifUserHasOneOfRoles(user, [Role.Admin]);
}

function canUserSeeNumberMembers(user: User): boolean {
  return ifUserHasOneOfRoles(user, [Role.Pathfinder]);
}

function canUserSeeNumberOpportunities(user: User): boolean {
  return ifUserHasOneOfRoles(user, [Role.OppEx]);
}

type PrivilegeCheckHandlerType = Record<keyof typeof PrivilegesList, (user: User) => boolean >;
const PrivilegeCheckHandlers: PrivilegeCheckHandlerType = {
  SEE_NUMBER_OPPORTUNITIES: canUserSeeNumberOpportunities,
  SEE_ADMIN_ALERT_BANNER: canUserSeeAdminAlertBanner,
  SEE_NUMBER_MEMBERS: canUserSeeNumberMembers,
  SEE_MY_TEAM_MENU: canUserSeeMyTeamMenu,
  ASSIGN_NEW_MEMBERS: canUserAssignNewMembers,
  ASSIGN_MEMBERS: canUserAssingMembers,
};

const hasAccess = (user: User, privilege: keyof typeof PrivilegesList): boolean => PrivilegeCheckHandlers[privilege](user);

export default hasAccess;
