import './style.css';
import React from 'react';
import { Col } from 'antd';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';

const Logo = (): JSX.Element => (
  <Col className="header-logo">
    <div className="header-logo__icon">
      <LogoIcon />
    </div>
    <div className="header-logo__text">Pathfinder</div>
  </Col>
);

export default Logo;
