import React, { useMemo } from 'react';
import './style.css';
import isNull from 'lodash/isNull';
import { useQuery } from 'react-query';
import {
  useParams,
  useHistory,
  Link as RouterLink,
} from 'react-router-dom';
import {
  GlobalOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import Link from 'antd/lib/typography/Link';
import { ReactComponent as CardPayIcon } from '../../../../assets/icons/card-pay.svg';
import { ReactComponent as ProfileCardIcon } from '../../../../assets/icons/profile-card.svg';
import Card from '../../../../components/Card/Card';
import AdminMainTab from '../AdminMainTab/AdminMainTab';
import InfoAsideLayout from '../../../../components/InfoAsideLayout/InfoAsideLayout';
import Colors from '../../../../common/enums/Colors';
import DetailInfo from '../../../../common/interfaces/DetailInfo';
import getUserFullName from '../../../../common/utils/getUserFullName';
import formatLocationFromMemberDetails from '../../../../common/utils/formatLocationFromMemberDetails';

import { Members } from '../../../../api/Members';
import hasAccess from '../../../../common/helpers/hasAccess';
import AdminAssignedTab from '../AdminAssignedTab/AdminAssignedTab';
import getAllUserRoles from '../../../../common/utils/getAllUserRoles';

interface AdminParams {
  memberId: string;
  tagTeamId: string;
}

const AdminTeamMemberTabs = (): JSX.Element => {
  const history = useHistory();
  const { memberId, tagTeamId } = useParams<AdminParams>();
  const member = useQuery([Members.single.key, memberId], () => Members.single.exec(Number(memberId)));

  const tabs = useMemo(() => {
    const allowedTabs = [
      {
        link: 'account',
        label: 'Account',
        component: <AdminMainTab user={member?.data?.user} />,
      },
    ];
    const assignTabs = [{
      link: 'assigned',
      label: 'Assigned Members',
      component: <AdminAssignedTab
        memberName={member.data?.user.firstName ?? ''}
        assignedMembersPage
      />,
    }, {
      link: 'newassign',
      label: 'Assign New Members',
      component: <AdminAssignedTab memberName={member.data?.user.firstName ?? ''} />,
    }];
    if (member.data?.user && hasAccess(member.data.user, 'ASSIGN_MEMBERS')) {
      allowedTabs.push(...assignTabs);
    }
    return allowedTabs;
  }, [member]);

  const details = useMemo((): DetailInfo[] => {
    if (!member.data) return [];
    const localTimeString: string | null = null;
    let location: string | null = null;
    if (member.data.user?.city) {
      location = formatLocationFromMemberDetails(member.data.user.city.name, member.data.user.city.country?.name);
    }

    let email : string | null = null;
    if (member.data.user?.email) {
      email = member?.data?.user.email;
    }

    return [
      {
        id: 'role',
        label: getAllUserRoles(member.data.user),
        icon: <ProfileCardIcon fill={Colors.ICONS_BASE_COLOR} />,
      },
      {
        id: 'localTimeString',
        label: location,
        icon: <GlobalOutlined />,
      },
      {
        id: 'location',
        label: localTimeString,
        icon: <ClockCircleOutlined />,
      },
      {
        id: 'email',
        label: email,
        icon: <CardPayIcon style={{ color: Colors.ICONS_BASE_COLOR }} />,
      },
    ];
  }, [member]);

  const breadcrumb = tabs.find((item) => item.link === tagTeamId)?.label;

  return (
    <Card
      float
      className="admin-card"
    >
      <div className="admin-card__header">
        <div className="admin-card__header__breadcrumbs">
          <div className="admin-card__header__breadcrumbs__item">
            <RouterLink to="/admin/team">
              <Link>My team</Link>
            </RouterLink>
          </div>
          <div className="admin-card__header__breadcrumbs__divider">/</div>
          <div className="admin-card__header__breadcrumbs__item">
            {breadcrumb}
          </div>
        </div>
      </div>
      <div className="admin-card__content">
        <div className="admin-card__aside">
          <InfoAsideLayout
            loading={isNull(member)}
            avatarPalceholderType="user"
            details={details}
            avatar={member?.data?.user?.avatar}
            personTitle={`${getUserFullName(member.data?.user)}`}
          />
        </div>
        <div className="admin-card__main">
          <div className="admin-card__main__tabs">
            <Tabs
              animated={{
                inkBar: false,
                tabPane: false,
              }}
              defaultActiveKey={tagTeamId}
              onChange={(index) => history.replace(index)}
              type="card"
            >
              {tabs.map((item) => (
                <Tabs.TabPane
                  key={item.link}
                  tab={item.label}
                >
                  <Card className="admin-card__main__content">
                    {item.component}
                  </Card>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AdminTeamMemberTabs;
