/* eslint-disable */
const uploadFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

export default uploadFile;
/* eslint-enable */
