import React from 'react';
import './style.css';
import {
  Divider,
  Skeleton,
} from 'antd';

const InfoAsideLayoutShimmer = (): JSX.Element => (
  <>
    <div className="InfoAsideLayout-card__person">
      <div className="InfoAsideLayout-card__person__image">
        <Skeleton.Avatar
          className="InfoAsideLayout-card__person__image__shimmer"
          active
          shape="circle"
        />
      </div>
      <div className="InfoAsideLayout-card__person__title_shimmer">
        <Skeleton
          active
          paragraph={false}
        />
      </div>
      <div className="InfoAsideLayout-card__person__details InfoAsideLayout-card__person__details_shimmer">
        {[1, 2, 3, 4, 5]?.map((detail) => (
          <Skeleton
            key={detail}
            active
            title={false}
            avatar={{
              size: 'small', shape: 'circle',
            }}
            paragraph={{ rows: 1 }}
          />
        ))}
      </div>
    </div>
    <Divider />
    <div className="InfoAsideLayout-card__skills">
      <Skeleton
        active
        paragraph={{ rows: 5 }}
        title={{ width: 70 }}
      />
    </div>
    <Divider />
    <div className="InfoAsideLayout-card__community">
      <div className="InfoAsideLayout-card__community__stack">
        {[1, 2, 3, 4, 5].map((number) => (
          <Skeleton.Avatar
            className="InfoAsideLayout-card__community__stack__item"
            key={number}
            active
            shape="circle"
          />
        ))}
      </div>
    </div>
  </>
);
export default InfoAsideLayoutShimmer;
