import './style.css';
import {
  Dropdown,
  DropDownProps,
  Menu,
  Row,
  Col,
  Divider,
} from 'antd';
import React from 'react';
import { Text } from '../typography/typography';
import { ReactComponent as RightBoldArrow } from '../../assets/icons/bold-arrow.svg';

interface DropdownMenuInterface {
  children: React.ReactNode;
  list: {
    text: string;
    component?: React.ReactNode;
    title?: string;
    type?: string;
    icon?: React.ReactNode;
    action?: () => void;
  }[];
}

type DropdownMenuProps = Omit<DropDownProps, 'overlay' | 'arrow'> &
  DropdownMenuInterface;

const DropdownMenu = ({
  children,
  list,
  placement,
  ...otherProps
}: DropdownMenuProps): JSX.Element => {
  // Our arrow at the top of the menu has custom size so we have to use this correction
  // to align it on the right place
  let positionCorrectionOffset: [number, number] = [0, 0];
  if (placement === 'bottomLeft') {
    positionCorrectionOffset = [-27.2, 5];
  } else if (placement === 'bottomRight') {
    positionCorrectionOffset = [33.2, 5];
  }

  const items = list.length > 0 ? list : [{ text: 'No new notifications' }];

  const DEFAULT_ICON = <RightBoldArrow />;
  const menu = (
    <Menu className="dropdown-menu__content">
      {items.map((item, index) => {
        const title = item.title ? (
          <Text modifiers={['bold']}>{item.title}</Text>
        ) : null;
        const isLast = index !== items.length - 1;
        return (
          <Menu.Item
            onClick={() => item.action?.()}
            className="dropdown-menu__item"
            key={item.text}
          >
            {item.component ?? (
              <>
                <Row align="middle">
                  <Col span={20}>
                    <div className="dropdown-menu__text">
                      <p>{title}</p>
                      {item.type ? (
                        <p>
                          <Text>{item.type}</Text>
                        </p>
                      ) : null}
                      {item.text ? (
                        <p>
                          <Text>{item.text}</Text>
                        </p>
                      ) : null}
                    </div>
                  </Col>
                  <Col
                    span={4}
                    className="dropdown-menu__image"
                  >
                    {item.icon || DEFAULT_ICON}
                  </Col>
                </Row>
                {isLast && <Divider className="dropdown-menu_divider" />}
              </>
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Dropdown
      {...otherProps}
      arrow
      align={{ offset: positionCorrectionOffset }}
      placement={placement}
      overlay={menu}
      className="dropdown-menu"
      overlayClassName="dropdown-menu__overlay"
    >
      {children}
    </Dropdown>
  );
};
export default DropdownMenu;
