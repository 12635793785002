import { Spin } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../store/store';
import './style.css';

interface LoadingWrapperProps {
  children: React.ReactNode;
}

const LoadingWrapper = ({ children }: LoadingWrapperProps): JSX.Element | null => {
  const isLoading = useAppSelector(({ auth }) => auth.isAuthorizationPending);
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>{ children }</>
  );
};

export default LoadingWrapper;
