import React from 'react';
import './style.css';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Col,
  Divider,
  Row,
} from 'antd';
import format from 'date-fns/format';
import {
  Link,
  Text,
  Title,
} from '../../../../../../components/typography/typography';
import { ReactComponent as LinkIcon } from '../../../../../../assets/icons/external-link.svg';
import Colors from '../../../../../../common/enums/Colors';
import ProfileMainInfoShimmer from './components/ProfileMainInfoShimmer/ProfileMainInfoShimmer';
import getCurrentCareer from '../../../../../../common/utils/getCurrentCareer';
import { Member } from '../../../../../../graphql-generate/graphql-types';
import DateFormats from '../../../../../../common/enums/DateFormats';
import ProfileColumnItem from './components/ProfileColumnItem/ProfileColumnItem';

interface ProfileMainInfoProps {
  member: Member | null;
}
const ProfileMainInfo = ({ member }: ProfileMainInfoProps): JSX.Element => {
  if (!member) {
    return <ProfileMainInfoShimmer />;
  }
  const currentCareer = getCurrentCareer(member);
  return (
    <div className="profile-card__main__content">
      <h3 className="profile-card__main__content__title">
        <Title modifiers={['thin']}>About me</Title>
      </h3>
      <p>
        <Text modifiers={['thin']}>
          {member?.bio ?? ''}
          {' '}
        </Text>
      </p>
      <Row
        gutter={[48, 16]}
        className="profile-card__content__description-row"
      >
        <Col
          span={8}
          className="profile-card__content__description-column"
        >
          <div className="profile-card__content__description-column__title">
            <h3>
              <Title modifiers={['thin']}>Career</Title>
            </h3>
          </div>
          <ProfileColumnItem
            title="Current job"
            text={currentCareer?.jobTitle}
          />
          <ProfileColumnItem
            title="Industry"
            text={member.industry?.name}
          />
          <ProfileColumnItem
            title="Company"
            text={currentCareer?.companyName}
          />
        </Col>
        <Col
          span={8}
          className="profile-card__content__description-column"
        >
          <div className="profile-card__content__description-column__title">
            <h3>
              <Title modifiers={['thin']}>Links</Title>
            </h3>
          </div>
          {[1].map((number) => (
            <div
              key={number}
              className="profile-card__content__description-column__item"
            >
              <span className="profile-card__content__description-column__link">
                <Link>Member profile</Link>
                <LinkIcon className="profile-card__content__description-column__icon" />
              </span>
            </div>
          ))}
        </Col>
        <Col
          span={8}
          className="profile-card__content__description-column"
        >
          <div className="profile-card__content__description-column__title">
            <h3>
              <Title modifiers={['thin']}>Documents</Title>
            </h3>
          </div>
          {[1].map((number) => (
            <div
              key={number}
              className="profile-card__content__description-column__item profile-card__content__description-column__item_row"
            >
              <span className="profile-card__content__description-column__item__date">
                <Text modifiers={['thin']}>20 Jan 20</Text>
              </span>
              <span
                className="profile-card__content__description-column__link"
              >
                <Link>Resume</Link>
                <DownloadOutlined
                  style={{ color: Colors.CONTLORS_BLUE_COLOR }}
                  className="profile-card__content__description-column__icon"
                />
              </span>
            </div>
          ))}
        </Col>
      </Row>
      <Divider />
      <Row
        gutter={[48, 16]}
        className="profile-card__content__description-row"
      >
        <Col
          span={8}
          className="profile-card__content__description-column"
        >
          <div className="profile-card__content__description-column__title">
            <h3>
              <Title modifiers={['thin']}>Contact details</Title>
            </h3>
          </div>
          <ProfileColumnItem
            title="Last contacted"
            text="07/07/20"
          />
          <ProfileColumnItem
            title="Email"
            text={member.user?.email}
          />
          <ProfileColumnItem
            title="Next follow up call"
            text={null}
          />
        </Col>
        <Col
          span={8}
          className="profile-card__content__description-column"
        >
          <div className="profile-card__content__description-column__title">
            <h3><Title modifiers={['thin']}>Membership</Title></h3>
          </div>
          <ProfileColumnItem
            title="Date joined"
            text={format(
              new Date(member.createdDateTime),
              DateFormats.DATE_SLASH_FORMAT
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ProfileMainInfo;
