import React from 'react';
import './style.css';
import { Divider } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import ProfileMainInfo from './components/ProfileMainInfo/ProfileMainInfo';
import { Member } from '../../../../graphql-generate/graphql-types';

interface ProfileMainTabProps {
  member: Member | null;
}

const ProfileMainTab = ({ member }: ProfileMainTabProps): JSX.Element => (
  <div>
    <div className="profile-card__main__content__wrapper">
      <ProfileMainInfo member={member} />
    </div>
    <Divider></Divider>
    <footer className="profile-card__content__footer">
      <div className="profile-card__content__footer__buttons">
        {process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
          <RouterLink
            to={{
              pathname: `/members/profile/${member?.id}/edit`,
              state: 'edit',
            }}
            className="profile-card__content__footer__button"
            type="primary"
          >
            Edit profile
          </RouterLink>
        ) : null}

        <RouterLink
          to={{
            pathname: `/members/profile/${member?.id}/edit`,
            state: 'default',
          }}
          className="profile-card__content__footer__button"
          type="primary"
        >
          View detailed profile
        </RouterLink>
      </div>
    </footer>
  </div>
);

export default ProfileMainTab;
