import {
  AnyAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

interface GoogleState {
  isAuthenticated: boolean;
  isScriptLoaded: boolean;
  isAuthenticationPending: boolean;
}

interface SetSignedInAction extends AnyAction {
  payload: GoogleState['isAuthenticated'];
}

const initialState: GoogleState = {
  isAuthenticated: false,
  isScriptLoaded: false,
  isAuthenticationPending: true,
};

export const scriptInit = createAsyncThunk(
  'google-integration/script-init',
  (_, { dispatch }) => new Promise<void>((resolve) => {
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        scope: 'https://www.googleapis.com/auth/calendar',
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        ],
      }).then(() => {
        dispatch(
          googleSlice.actions.setSignedIn(
            window.gapi.auth2.getAuthInstance().isSignedIn.get()
          )
        );

        window.gapi.auth2.getAuthInstance().isSignedIn.listen((signedIn) => {
          dispatch(
            googleSlice.actions.setSignedIn(signedIn)
          );
        });

        resolve();
      });
    });
  })
);

export const googleSignIn = createAsyncThunk('google-integration/sign-in', () => window.gapi.auth2.getAuthInstance().signIn().then((currentUser) => currentUser.isSignedIn()));

export const googleSignOut = createAsyncThunk(
  'google-integration/sign-out',
  () => {
    if (window.gapi.auth2?.getAuthInstance()?.isSignedIn) {
      window.gapi.auth2.getAuthInstance().disconnect();
    }
    googleSlice.actions.setSignedIn(false);
  }
);

export const googleSlice = createSlice({
  name: 'google',
  initialState,
  reducers: {
    setSignedIn(state, action: SetSignedInAction) {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(scriptInit.pending, (state) => {
      state.isAuthenticationPending = true;
      state.isScriptLoaded = false;
    });
    builder.addCase(scriptInit.rejected, (state) => {
      state.isAuthenticationPending = false;
      state.isScriptLoaded = false;
    });
    builder.addCase(scriptInit.fulfilled, (state) => {
      state.isAuthenticationPending = false;
      state.isScriptLoaded = true;
    });

    builder.addCase(googleSignIn.pending, (state) => {
      state.isAuthenticationPending = true;
    });
    builder.addCase(googleSignIn.rejected, (state) => {
      state.isAuthenticationPending = false;
    });
    builder.addCase(googleSignIn.fulfilled, (state, action) => {
      state.isAuthenticated = action.payload;
      state.isAuthenticationPending = false;
    });

    builder.addCase(googleSignOut.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.isAuthenticationPending = false;
    });
  },
});
export const googleActions = googleSlice.actions;
