import { nanoid } from '@reduxjs/toolkit';

export const optionsNeed = [{
  id: nanoid(),
  name: 'Job opportunity',
},
{
  id: nanoid(),
  name: 'Board opportunity',
},
{
  id: nanoid(),
  name: 'Speaking opportunity',
}];

export const optionsActivity = [{
  id: nanoid(),
  name: 'Job opportunity',
},
{
  id: nanoid(),
  name: 'Board opportunity',
},
{
  id: nanoid(),
  name: 'Speaking opportunity',
}];
