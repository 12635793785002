import React, { useState } from 'react';
import {
  Form,
  Input,
} from 'antd';
import Auth from '@aws-amplify/auth';
import {
  Text,
  Title,
} from '../../components/typography/typography';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import LoginNotification from '../../common/interfaces/LoginNotification';
import NotificationTypes from '../../common/enums/NotificationTypes';
import NotificationMessages from '../../common/enums/NotificationMessages';
import REQUIRED_RULES from '../../common/validationRules/requiredRules';
import LoginLayoutError from '../../components/LoginLayout/components/LoginLayoutError/LoginLayoutError';

interface ForgotPasswordFormData {
  email: string;
}

function getPrettyErrorMessage(error: Error) {
  return (
    { UserNotFoundException: ErrorTypes.notFound }[error.name] || error.message
  );
}

enum ErrorTypes {
  notFound = 'User not found'
}

const ForgotPasswordForm = (): JSX.Element => {
  const [forgotPasswordForm] = Form.useForm();
  const [notification, setNotification] = useState<LoginNotification | null>();
  const [aboveErrorText, setAboveErrorText] = useState<string | null>(null);

  const resetErrors = () => {
    setAboveErrorText(null);
    if (notification?.type === NotificationTypes.ERROR) {
      setNotification(null);
    }
  };
  const onForgotPasswordFinish = async (data: ForgotPasswordFormData) => {
    try {
      await Auth.forgotPassword(data.email, { context: 'pathfinder' });
      setNotification({
        type: NotificationTypes.SUCCESS,
        text: NotificationMessages.PASSWORD_RESET_LINK_SENT,
      });
    } catch (error) {
      const message = getPrettyErrorMessage(error as Error);
      if (message === ErrorTypes.notFound) {
        setAboveErrorText(ErrorTypes.notFound);
      } else {
        setNotification({
          text: message,
          type: NotificationTypes.ERROR,
        });
      }
    }
  };

  return (
    <LoginLayout
      notification={notification}
    >
      <div className="login-card__content login-card__password-change">
        <>
          <div className="login-title">
            <Title modifiers={['thin']}>Forgot Your Password</Title>
          </div>
          {aboveErrorText ? <LoginLayoutError text={aboveErrorText} /> : null}
          <p className="login-subtitle">
            <Text modifiers={['thin']}>Enter the email address you used when you joined and we will send you instructions to reset your password</Text>
          </p>
          <Form
            form={forgotPasswordForm}
            name="normal_login"
            className="login-form"
            onKeyDown={() => {
              resetErrors();
            }}
            onFinish={onForgotPasswordFinish}
          >
            <Form.Item
              name="email"
              rules={REQUIRED_RULES}
            >
              <Input
                size="large"
                type="email"
                placeholder="Enter your email"
              />
            </Form.Item>
            <button
              type="submit"
              className="sign-field login-regular"
              disabled={
                !!forgotPasswordForm
                  .getFieldsError()
                  .filter(({ errors }) => errors.length).length
              }
            >
              <Text modifiers={['white', 'italic']}>Send Reset Instructions</Text>
            </button>
          </Form>
        </>
      </div>
    </LoginLayout>
  );
};

export default ForgotPasswordForm;
