import toFirstCapitalLetter from './toFirstCapitalLetter';

const formatLocationFromMemberDetails = (city?: string, country?: string): string => {
  let formattedCity = '';
  if (city && city.length > 0) {
    formattedCity = city;
  }
  let formattedCountry = '';
  if (country && country.length > 0) {
    formattedCountry = toFirstCapitalLetter(country);
  }
  return `${formattedCity}${formattedCountry ? ', ' : ' '}${formattedCountry}`;
};

export default formatLocationFromMemberDetails;
