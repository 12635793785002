import React from 'react';
import {
  Radio,
  Form,
  Input,
  Select,
  Tag,
} from 'antd';
import SelectOption from '../../../../common/interfaces/SelectOption';
import './style.css';

const { Option } = Select;
const { TextArea } = Input;

interface CareerProps {
  grade: SelectOption[];
  addSkills: SelectOption[];
  industrySector: SelectOption[];
  quallification: SelectOption[];
  academicInstitution: SelectOption[];
  PageType: 'add' | 'edit';
  isEditMode: 'default' | 'edit';
  titleLayout: {
    wrapperCol: {
      offset: number,
    }
  };
  skills?: {
    id: number;
    name: string;
  }[];
  value?: {
    grade: string;
    linkedin: string;
    jobTitle: string;
    addSkills: string;
    companyName: string;
    typeSpecifics: string;
    quallification: string;
    isEntrepreneur: string;
    industrySector: string;
    companyIndustry: string;
    academicInstitution: string;
  }
}

const Career = ({
  value,
  grade,
  skills,
  PageType,
  isEditMode,
  addSkills,
  titleLayout,
  industrySector,
  quallification,
  academicInstitution,
}: CareerProps): JSX.Element => (
  <>
    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Bio</h2>
    </Form.Item>

    <Form.Item
      name="Description"
      label="Description:"
      initialValue={value?.typeSpecifics}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.typeSpecifics}</span>
      ) : (
        <TextArea
          rows={5}
          placeholder="Controlled autosize"
          className="Career-card__form_textarea"
        />
      )}
    </Form.Item>

    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Career</h2>
    </Form.Item>

    <Form.Item
      name="JobTitle"
      label="Job title:"
      initialValue={value?.jobTitle}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.jobTitle}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="CompanyName"
      label="Company name:"
      initialValue={value?.companyName}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.companyName}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="CompanyIndustry"
      label="Company Industry:"
      initialValue={value?.companyIndustry}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.companyIndustry}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="IndustrySector"
      label="Industry sector:"
      initialValue={value?.industrySector}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.industrySector}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {industrySector.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="IsEntrepreneur"
      label="Is Entrepreneur:"
      initialValue={value?.isEntrepreneur}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.isEntrepreneur}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Male">
            Male
          </Radio.Button>
          <Radio.Button value="Female">
            Female
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="Linkedin"
      label="Linkedin:"
      initialValue={value?.linkedin}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.linkedin}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Skills</h2>
    </Form.Item>

    <Form.Item
      label="Add Skills:"
      name="AddSkills"
      initialValue={value?.addSkills}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <></>
      ) : (
        <Select
          allowClear
          mode="multiple"
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__multiple"
        >
          {addSkills.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
      <div className="Career-card__form__tabs">
        {skills?.map((item) => (
          <Tag
            closable
            key={item.id}
          >
            { item.name }
          </Tag>
        ))}
      </div>
    </Form.Item>

    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Education</h2>
    </Form.Item>

    <Form.Item
      name="Quallification"
      label="Quallification:"
      initialValue={value?.quallification}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.quallification}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {quallification.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="Grade"
      label="Grade:"
      initialValue={value?.grade}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.grade}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {grade.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="AcademicInstitution"
      label="Academic institution:"
      initialValue={value?.academicInstitution}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.academicInstitution}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {academicInstitution.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  </>
);

export default Career;
