import './style.css';
import React from 'react';
import { Layout } from 'antd';
import LayoutHeader from './LayoutHeader/LayoutHeader';
import LayoutSider from './LayoutSider/LayoutSider';
import LayoutFooter from './LayoutFooter/LayoutFooter';
const { Content } = Layout;

interface BasicLayoutProps {
  children?: React.ReactNode;
}

const BasicLayout = ({ children }: BasicLayoutProps): JSX.Element => (
  <Layout>
    <LayoutHeader />
    <Layout>
      <LayoutSider />
      <Content className="main">{ children }</Content>
    </Layout>
    <LayoutFooter />
  </Layout>
);

export default BasicLayout;
