import {
  Member,
  OrderType,
  QueryMembersArgs,
  QueryMembersConnection,
  UpdateMemberInput,
  UpdateMemberPayload,
  User,
} from '../graphql-generate/graphql-types';
import getSingleMember from './common/graphql/requests/getSingleMember';
import getViewer from './common/graphql/requests/getViewer';
import { getNodesFromConnection } from '../common/utils/getNodesFromConnection';
import getMembersConnection from './common/graphql/requests/getMembersConnection';
import updateMembersInput from './common/graphql/requests/updateMembersInput';

enum MembersPredefinedFilters {
  NEW = 'NEW',
  ALL = 'ALL'
}

const getMemberByFilter = async (filterName: MembersPredefinedFilters, numberOfMembers = 10): Promise<Member[]> => {
  const predefinedFilters: Record<MembersPredefinedFilters, QueryMembersArgs> = {
    [MembersPredefinedFilters.NEW]: {
      first: numberOfMembers, orderBy: { createdDateTime: OrderType.Desc },
    },
    [MembersPredefinedFilters.ALL]: { first: numberOfMembers },
  };
  const membersArgs = predefinedFilters[filterName];
  const members = getNodesFromConnection(await getMembersConnection(membersArgs));
  return members;
};

const getMembersByParamsFilter = async (params: QueryMembersArgs): Promise<QueryMembersConnection> => {
  const membersConnection = await getMembersConnection(params);
  return membersConnection;
};

const getMemberRecommendations = async (id: string) => {
  const members = getNodesFromConnection(await getMembersConnection({ first: 10 }));
  return members.filter((member) => String(member.id) !== id);
};

const Members = {
  index: {
    key: 'MEMBERS_LIST',
    exec: (): Promise<QueryMembersConnection> => getMembersConnection({ first: 10 }),
  },
  updateMember: {
    key: 'MEMBERS_UPDATE',
    exec: (updateMemberInput: UpdateMemberInput): Promise<UpdateMemberPayload> => updateMembersInput(updateMemberInput),
  },
  recommendations: {
    key: 'MEMBERS_RECOMMENDATIONS',
    exec: (id: string): Promise<Member[]> => getMemberRecommendations(id),
  },
  single: {
    key: 'MEMBERS_SINGLE',
    exec: (id: number): Promise<Member | null> => getSingleMember(id),
  },
  filterBy: {
    key: 'MEMBERS_FILTER_BY',
    exec: (filterName: MembersPredefinedFilters): Promise<Member[]> => getMemberByFilter(filterName),
  },
  filterByParams: {
    key: 'MEMBERS_FILTER_BY_PARAMS',
    exec: (params: QueryMembersArgs): Promise<QueryMembersConnection> => getMembersByParamsFilter(params),
  },
  viewer: {
    key: 'MEMBER_VIEWER',
    exec: (): Promise<User> => getViewer(),
  },
};

export {
  Members,
  MembersPredefinedFilters as MembersFilters,
};
