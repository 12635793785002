import './style.css';
import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import INotesLayoutNotification from '../../interfaces/NotesLayoutNotification';

interface NotesLayoutNotificationProps {
  notification: INotesLayoutNotification;
  onClose: () => void;
}

const NotesLayoutNotification = ({ notification, onClose }: NotesLayoutNotificationProps): JSX.Element => (
  <ul className="notes-notifications">
    <li className="notes-notifications__item">
      <div className={`notes-layout-notification notes-layout-notification_${notification.type}`}>
        <span className="notes-layout-notification__text">{notification.text}</span>
        <button
          onClick={onClose}
          type="button"
          aria-label="Close notification"
          className="notes-layout-notification__icon"
        >
          <CloseCircleOutlined />
        </button>
      </div>
    </li>
  </ul>
);

export default NotesLayoutNotification;
