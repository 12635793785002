import './style.css';
import React from 'react';
import { ReactComponent as ListNoData } from '../../assets/icons/list-no-data.svg';

interface ListNoResultsProps {
  text?: string
}

const ListNoResults = ({ text }: ListNoResultsProps): JSX.Element => (
  <div className="list-no-results">
    <div className="list-no-results__image"><ListNoData /></div>
    <div className="list-no-results__text">{text ?? 'No results'}</div>
  </div>
);

export default ListNoResults;
