import React from 'react';
import { PageInfo } from '../../graphql-generate/graphql-types';
import PaginationButton from './components/PaginationButton/PaginationButton';
import PaginationsCrumbs from './components/PaginationCrumbs/PaginationsCrumbs';
import './style.css';

enum PaginationDirections {
  NEXT = 'next',
  PREV = 'prev',
  RESET = 'reset'
}

export interface ChangePageData {
  pageNumber: number;
  after?: string,
  before?: string,
}

interface OneStepPaginationProps {
  pageInfo: PageInfo | null;
  total: number;
  pageSize: number;
  currentPage: number;
  onChangePage?: (changePageData: ChangePageData) => void;
  className: string;
}

const OneStepPagination = ({ pageInfo, total, pageSize, currentPage, onChangePage, className }: OneStepPaginationProps): JSX.Element => {
  const numberOfPages = Math.ceil(total / pageSize);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numberOfPages;

  const showPrevPageButton = (currentPage > 2) && !isFirstPage;
  const showNextPageButton = (currentPage < numberOfPages) && !isLastPage;
  const showCurrentPageButton = !isFirstPage;

  const showCrubsBefore = currentPage > 3;
  const showCrubsAfter = currentPage < numberOfPages - 1;

  const changePage = (direction: PaginationDirections): void => {
    let result: ChangePageData = { pageNumber: currentPage };
    if (onChangePage && pageInfo) {
      if (direction === PaginationDirections.NEXT && pageInfo.hasNextPage) {
        result.pageNumber = currentPage + 1;
        result.after = pageInfo.endCursor ?? undefined;
      }
      if (direction === PaginationDirections.PREV && pageInfo.hasPreviousPage) {
        result.pageNumber = currentPage - 1;
        result.before = pageInfo.startCursor ?? undefined;
      }
      if (direction === PaginationDirections.RESET) {
        result = { pageNumber: 1 };
      }
      onChangePage(result);
    }
  };

  const prevPageButton = (
    <PaginationButton
      type="number"
      buttonNumber={currentPage - 1}
      onClick={() => changePage(PaginationDirections.PREV)}
    />
  );
  const currentPageButton = (
    <PaginationButton
      type="number"
      active
      buttonNumber={currentPage}
    />
  );
  const nextPageButton = (
    <PaginationButton
      type="number"
      buttonNumber={currentPage + 1}
      onClick={() => changePage(PaginationDirections.NEXT)}
    />
  );

  if (total <= pageSize) {
    return <></>;
  }

  return (
    <ul className={`one-step-pagination ${className}`}>
      <PaginationButton
        disabled={currentPage === 1}
        type="prev"
        onClick={() => changePage(PaginationDirections.PREV)}
      />
      <PaginationButton
        type="number"
        active={currentPage === 1}
        buttonNumber={1}
        onClick={() => changePage(PaginationDirections.RESET)}
      />
      { showCrubsBefore ? <PaginationsCrumbs /> : null }
      { showPrevPageButton ? prevPageButton : null }
      { showCurrentPageButton ? currentPageButton : null}
      { showNextPageButton ? nextPageButton : null}
      { showCrubsAfter ? <PaginationsCrumbs /> : null }

      <PaginationButton
        disabled={currentPage === numberOfPages}
        type="next"
        onClick={() => changePage(PaginationDirections.NEXT)}
      />
    </ul>
  );
};

export default OneStepPagination;
