import React from 'react';
import { Skeleton } from 'antd';
import './style.css';

const ProfileNotesListCardShimmer = (): JSX.Element => (
  <div className="profile-notes-list-card-shimmer">
    <Skeleton active />
  </div>
);

export default ProfileNotesListCardShimmer;
