import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import './style.css';
import {
  Col,
  Row,
  Select,
} from 'antd';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../store/store';
import { DataText } from '../../../../components/typography/typography';
import MembersMyTeam from '../../../../common/interfaces/membersMyTeam';
import formatLocationFromMemberDetails from '../../../../common/utils/formatLocationFromMemberDetails';
import { Members } from '../../../../api/Members';
import { getMemberFiltersEntities } from '../../../../store/entities';
import DetailsSelect from '../../common/interfaces/DetailsSelect';
import getUserFullName from '../../../../common/utils/getUserFullName';
import { getNodesFromConnection } from '../../../../common/utils/getNodesFromConnection';
import AdminMyTeamTabShimmer from './components/AdminMyTeamTabShimmer/AdminMyTeamTabShimmer';
import OneStepPagination, { ChangePageData } from '../../../../components/OneStepPagination/OneStepPagination';
import getSafeMembersConnectionAgrs from '../../../../common/utils/getSafeMembersConnectionArgs';
import { MemberFilter } from '../../../../graphql-generate/graphql-types';
import ListNoResults from '../../../../components/ListNoResults/ListNoResults';
import getAllUserRoles from '../../../../common/utils/getAllUserRoles';

const { Option } = Select;

const AdminMyTeamTab = (): JSX.Element => {
  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [afterCursor, setAfterCursor] = useState<string | null>(null);
  const [beforeCursor, setBeforeCursor] = useState<string | null>(null);
  const [countryFilterId, setCountryFilterId] = useState<number | null>(null);
  const [cityFilterId, setCityFilterId] = useState<number | null>(null);
  const entities = useAppSelector((state) => getMemberFiltersEntities(state.entities));

  const memberFilters = useMemo((): MemberFilter => ({
    countryIds: countryFilterId ? [countryFilterId] : [],
    cityIds: cityFilterId ? [cityFilterId] : [],
  }), [countryFilterId, cityFilterId]);

  const membersConnectionQuery = useQuery(
    [Members.filterByParams.key, memberFilters, beforeCursor, afterCursor],
    () => Members.filterByParams.exec(getSafeMembersConnectionAgrs({
      before: beforeCursor,
      after: afterCursor,
      filterBy: memberFilters,
    }, PAGE_SIZE))
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [memberFilters]);

  const computedMemberList: MembersMyTeam[] = useMemo(() => {
    if (!membersConnectionQuery.data) {
      return [];
    }
    const membersList = getNodesFromConnection(membersConnectionQuery?.data);
    const newMemberList: MembersMyTeam[] = [];

    membersList.forEach((member) => {
      const name = getUserFullName(member.user);

      let location = '';
      if (member.user?.city) {
        location = formatLocationFromMemberDetails(member.user?.city?.name, member.user?.city?.country?.name);
      }
      newMemberList.push({
        id: member.id,
        name,
        location,
        noMembers: null,
        role: getAllUserRoles(member.user),
      });
    });
    return newMemberList;
  }, [membersConnectionQuery]);

  const changePaginationPage = (changePageData: ChangePageData) => {
    if (!membersConnectionQuery.data) {
      return;
    }
    setAfterCursor(changePageData.after ?? null);
    setBeforeCursor(changePageData.before ?? null);
    setCurrentPage(changePageData.pageNumber);
    window.scrollTo(0, 0);
  };

  const detailsSelect = useMemo((): DetailsSelect<number>[] => [{
    id: 'Role',
    title: 'Role',
    optionItems: [],
  }, {
    id: 'Country',
    title: 'Country',
    onChange: (id?: number) => setCountryFilterId(id ?? null),
    optionItems: entities?.countries ?? [],
  }, {
    id: 'City',
    title: 'City',
    onChange: (id?: number) => setCityFilterId(id ?? null),
    optionItems: entities?.cities ?? [],
  }, {
    id: 'No of Members',
    title: 'No of Members',
    optionItems: [],
  }], [entities]);

  return (
    <div className="Admin__main Admin-myteam__main">
      <div className="Admin-myteam__main__container">
        <div className="Admin-myteam__selects">
          {detailsSelect.map((item) => (
            <div
              key={item.id}
              className="Admin-myteam__selects-item"
            >
              <span className="Admin-myteam__selects__title">{item.title}</span>
              <Select
                allowClear
                size="small"
                defaultActiveFirstOption={false}
                onChange={item.onChange}
                placeholder="All"
                className="Admin-myteam__select"
                dropdownClassName="Admin-myteam__dropdown"
              >
                {item.optionItems.map((itemOption) => (
                  <Option
                    key={itemOption.id}
                    value={itemOption.id}
                    label={itemOption.name}
                  >
                    {itemOption.name}
                  </Option>
                ))}
              </Select>
            </div>
          ))}
        </div>
        <div className="Admin-myteam__members">
          <Row
            align="middle"
            className="Admin-myteam__members__item"
          >
            <Col
              span={7}
              className="Admin-myteam__members__item_name"
            >
              Name
            </Col>
            <Col
              span={4}
              className="Admin-myteam__members__item_name"
            >
              Role
            </Col>
            <Col
              span={5}
              className="Admin-myteam__members__item_name"
            >
              Location
            </Col>
            <Col
              span={4}
              className="Admin-myteam__members__item_name"
            >
              No of Members
            </Col>
            <Col
              span={4}
              className="Admin-myteam__members__item_name"
            >
            </Col>
          </Row>
          {(membersConnectionQuery.isSuccess) ? (
            computedMemberList.map((item) => (
              <Row
                align="middle"
                className="Admin-myteam__members__item"
                key={item.id}
              >
                <Col
                  span={7}
                  className="Admin-myteam__members__item_name"
                >
                  <DataText>{item.name}</DataText>
                </Col>
                <Col
                  span={4}
                  className="Admin-myteam__members__item_name"
                >
                  <DataText>{item.role}</DataText>
                </Col>
                <Col
                  span={5}
                  className="Admin-myteam__members__item_name"
                >
                  <DataText>{item.location}</DataText>
                </Col>
                <Col
                  span={4}
                  className="Admin-myteam__members__item_name"
                >
                  <DataText>{item.noMembers}</DataText>
                </Col>
                <Col
                  span={4}
                  className="Admin-myteam__members__item_name"
                >
                  <Link
                    className="Admin-myteam__members__item__button"
                    to={`/admin/team/${item.id}/account`}
                  >
                    View
                  </Link>
                </Col>
              </Row>
            ))
          ) : ((membersConnectionQuery.isError) ? null : <AdminMyTeamTabShimmer showItems={10} />)}

          {(membersConnectionQuery.isSuccess && computedMemberList.length === 0) ? (
            <div className="Admin-assigned__members__no-results">
              <ListNoResults />
            </div>
          ) : null}
        </div>
      </div>
      <footer className="Admin-myteam__footer">
        <div>
          <OneStepPagination
            pageInfo={membersConnectionQuery.data?.pageInfo ?? null}
            className="Admin-myteam__list-pagination"
            total={membersConnectionQuery.data?.totalCount ?? 0}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onChangePage={changePaginationPage}
          />
        </div>
      </footer>
    </div>
  );
};

export default AdminMyTeamTab;
