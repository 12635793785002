import './style.css';
import React from 'react';
import {
  Button,
  Divider,
} from 'antd';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-event.svg';
import {
  Link,
  Text,
} from '../typography/typography';
interface ListSchedueProps {
  events: gapi.client.calendar.Event[];
}

const ListSchedue = ({ events }: ListSchedueProps): JSX.Element => (
  <ul className="list-schedule">
    {events.map(({ id, summary, start, attendees = [] }, index) => {
      const member = attendees[0];
      const time = start.dateTime ? format(parseISO(start.dateTime), 'hh:mm aa') : 'N/A';

      return (
        <React.Fragment key={id}>
          <li className="list-schedule__item">
            <div className="list-schedule__time">
              <Text modifiers={['thin', 'gray']}>{time}</Text>
            </div>
            <div className="list-schedule__icon">
              <CalendarIcon />
            </div>
            <div className="list-schedule__title">
              <Text>{summary}</Text>
            </div>
            <div className="list-schedule__description">
              <Text modifiers={['thin']}>You have a call with</Text>
              {' '}
              <Link>{member.displayName || member.email}</Link>
            </div>
            <div className="list-schedule__button">
              <Button
                type="link"
                href="#"
              >
                View profile
              </Button>
            </div>
          </li>
          {index !== events.length - 1 ? <Divider /> : null}
        </React.Fragment>
      );
    })}
  </ul>
);

export default ListSchedue;
