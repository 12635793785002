import { Badge } from 'antd';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import subMonths from 'date-fns/subMonths';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isBefore from 'date-fns/isBefore';
import moment from 'moment';
import {
  useGetEventsByMonth,
  UseGetEventsByMonthOpts,
  useCreateEvent,
} from '../../api/schedule';
import { googleSignIn } from '../../store/google/google.slice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../store/store';
import { isSignedInSelector } from '../../store/google/google.selectors';

const DEFAULT_MAX_NUMBER_OF_RESULT = 3;

export type GetDateScheduleOpts = {
  maxNumberOfResult? : number
};

export type UseGetDateSchedule = {
    events: gapi.client.calendar.Event[],
    total: number;
};

export function useTodaySchedule({ maxNumberOfResult = DEFAULT_MAX_NUMBER_OF_RESULT }: GetDateScheduleOpts = { }): UseGetDateSchedule {
  const now = new Date();
  const { events } = useGetDateSchedule(now, { maxNumberOfResult: 0 });

  const filteredEvents = events.filter(({ start, end }) => {
    if (!start || !start.dateTime) {
      return false;
    }

    if (!end || !end.dateTime) {
      return false;
    }

    return isBefore(now, parseISO(end.dateTime));
  });

  const result = maxNumberOfResult && filteredEvents.length > maxNumberOfResult ? filteredEvents.slice(0, maxNumberOfResult) : filteredEvents;

  return {
    events: result, total: result.length,
  };
}

export function useGetDateSchedule(date: Date, { maxNumberOfResult = DEFAULT_MAX_NUMBER_OF_RESULT } : GetDateScheduleOpts = {}) : UseGetDateSchedule {
  const isSignedIn = useAppSelector(isSignedInSelector);
  const currentMonthEvents = useGetEventsByMonth(date.getFullYear(), date.getMonth(), { enabled: isSignedIn });

  const events = useMemo(() => {
    if (!currentMonthEvents.data) return [];

    return currentMonthEvents.data.filter(({ start, end }) => {
      if (!start || !start.dateTime) {
        return false;
      }

      if (!end || !end.dateTime) {
        return false;
      }

      const eventDate = parseISO(end.dateTime);

      return isSameDay(eventDate, date);
    });
  }, [currentMonthEvents.data, date]);

  return {
    events: maxNumberOfResult && events.length > maxNumberOfResult ? events.slice(0, maxNumberOfResult) : events,
    total: events.length,
  };
}

function useGetCalendarEvents(selectedDate: Date, { enabled } : UseGetEventsByMonthOpts) {
  const nextMonthDate = addMonths(selectedDate, 1);
  const prevMonthDate = subMonths(selectedDate, 1);
  const prevMonthResult = useGetEventsByMonth(prevMonthDate.getFullYear(), prevMonthDate.getMonth(), { enabled });
  const selectedMonthResult = useGetEventsByMonth(selectedDate.getFullYear(), selectedDate.getMonth(), { enabled });
  const nextMonthResult = useGetEventsByMonth(nextMonthDate.getFullYear(), nextMonthDate.getMonth(), { enabled });

  const calendarViewEvents = useMemo(() => {
    const items = [...(prevMonthResult.data || []), ...(selectedMonthResult.data || []), ...(nextMonthResult.data || [])];

    return items.filter((event) => {
      if (event.recurrence) return false; // TEMP

      return true;
    });
  }, [prevMonthResult.data, selectedMonthResult.data, nextMonthResult.data]);

  return { calendarEvents: calendarViewEvents };
}

export type UseGetCalendarEventsReturn = {
  selectedDate: Date,
  handleChange: (value: moment.Moment) => void,
  handlePanelChange: (value: moment.Moment) => void,
  handleDateCellRender: (value: moment.Moment) => JSX.Element | null,
};

export function useCalendar() : UseGetCalendarEventsReturn {
  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const isSignedIn = useAppSelector(isSignedInSelector);
  const { calendarEvents } = useGetCalendarEvents(selectedDate, { enabled: isSignedIn });

  const handleChange = useCallback((date: moment.Moment) => {
    setSelectedDate(date.toDate());
  }, []);

  const handlePanelChange = useCallback((date: moment.Moment) => {
    setSelectedDate(date.toDate());
  }, []);

  const handleDateCellRender = useCallback((value: moment.Moment) => {
    const date = value.toDate();
    const dateEvents = calendarEvents.filter((event) => {
      if (!event.start?.dateTime) return false;

      return isSameDay(date, parseISO(event.start.dateTime));
    });

    if (dateEvents.length === 0) {
      return null;
    }

    return (
      <ul className="events">
        {dateEvents.map((item) => (
          <li key={item.id}>
            <Badge
              status="default"
              text={item.summary}
            />
          </li>
        ))}
      </ul>
    );
  }, [calendarEvents]);

  return {
    selectedDate,
    handleChange,
    handlePanelChange,
    handleDateCellRender,
  };
}

export function useAutoGoogleSignIn(): void {
  const dispatch = useAppDispatch();

  const isSignedIn = useAppSelector(isSignedInSelector);

  useEffect(() => {
    if (!isSignedIn) {
      dispatch(googleSignIn());
    }
  }, [dispatch, isSignedIn]);
}

// TODO: Clean up temporary hook for testing
/* eslint-disable */
export function useNewEvent() {
/* eslint-enable */
  const { mutateAsync } = useCreateEvent();
  const [selectedDate, setDate] = useState(() => moment(new Date()));
  const [range, setRange] = useState<[moment.Moment, moment.Moment]>();
  const handleDateChange = useCallback((value) => {
    setDate(value);
  }, [setDate]);
  const handleRangeChange = useCallback((value) => {
    setRange(value);
  }, [setRange]);
  const handleCreateEvent = useCallback(() => {
    if (!range) return;
    const [selectedStart, selectedEnd] = range;
    const date = selectedDate.toDate();
    const [year, month, day] = [date.getFullYear(), date.getMonth(), date.getDate()];
    const start = new Date(year, month, day, selectedStart.toDate().getHours(), selectedStart.toDate().getMinutes(), 0, 0);
    const end = new Date(year, month, day, selectedEnd.toDate().getHours(), selectedEnd.toDate().getMinutes(), 0, 0);

    mutateAsync({
      summary: 'Follow up with Test User',
      start,
      end,
      member: {
        displayName: 'Test User',
        email: 'luisib9@gmail.com',
      },
    }).then(() => {
      setRange(undefined);
      setDate(moment(new Date()));
    });
  }, [mutateAsync, selectedDate, range, setDate, setRange]);

  const disabled = Boolean(!(range && range.length === 2 && selectedDate));

  return {
    date: selectedDate, range, disabled, handleDateChange, handleRangeChange, handleCreateEvent,
  };
}
