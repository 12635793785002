import React from 'react';
import { Divider } from 'antd';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import {
  Link,
  Text,
} from '../../../components/typography/typography';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-event.svg';
import { useGetDateSchedule } from '../Planner.hooks';

export type ItineraryProps = { selectedDate: Date };

// eslint-disable-next-line react/prop-types
export const Itinerary: React.FC<ItineraryProps> = ({ selectedDate }) => {
  const { events } = useGetDateSchedule(selectedDate, { maxNumberOfResult: 0 });
  const subTitle = isToday(selectedDate) ? 'Your schedule for today' : `Your schedule for the ${format(selectedDate, "do 'of' LLL yyyy")}`;

  return (
    <>
      <span className="planner-card__aside__title">Itenary</span>
      <span className="planner-card__aside__text">{subTitle}</span>
      <ul className="planner-list__schedule">
        {events.map(({ id, summary, start, attendees = [] }) => {
          const member = attendees[0];
          const time = start.dateTime ? format(parseISO(start.dateTime), 'hh:mm aa') : 'N/A';
          return (
            <React.Fragment key={id}>
              <li className="planner-list__schedule__item">
                <div className="planner-list__schedule__time">
                  <Text modifiers={['thin', 'gray']}>{time}</Text>
                </div>
                <div className="planner-list__schedule__icon">
                  <CalendarIcon />
                </div>
                <div className="planner-list__schedule__title">
                  <Text modifiers={['bold']}>{summary}</Text>
                  <Text modifiers={['thin']}>
                    You have a call with
                  </Text>
                  <div className="planner-list__schedule__description"><Link>{member.displayName || member.email}</Link></div>
                </div>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
      <Divider />
    </>
  );
};
