import connections from '../common/dummyData/Profile/dummyDataConnections';
import Connection from '../common/interfaces/Opportunity';

const Connections = {
  index: {
    key: 'CONNECTIONS_LIST',
    exec: (): Promise<Connection[]> => Promise.resolve(connections),
  },
};

export { Connections };
