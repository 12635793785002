import './style.css';
import React from 'react';
import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  HomeOutlined,
  HomeFilled,
  QuestionCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ReactComponent as BageIcon } from '../../../assets/icons/bage.svg';
import { ReactComponent as MembersIcon } from '../../../assets/icons/members.svg';
import { ReactComponent as PlannerIcon } from '../../../assets/icons/planner.svg';
import { ReactComponent as PiechartIcon } from '../../../assets/icons/piechart.svg';
import { Text } from '../../typography/typography';
import Colors from '../../../common/enums/Colors';

interface MenuItem {
  text: string;
  icon: React.ReactNode;
  iconActive: React.ReactNode;
  link: string;
}

const menuList: MenuItem[] = [
  {
    text: 'Home',
    icon: <HomeFilled style={{ color: Colors.ICONS_BASE_COLOR }} />,
    iconActive: <HomeOutlined style={{ color: Colors.WHITE }} />,
    link: '/home',
  },
  {
    text: 'Members',
    icon: <MembersIcon />,
    iconActive: <MembersIcon fill={Colors.WHITE} />,
    link: '/members',
  },
];

const betaVerionsListItems: MenuItem[] = [
  {
    text: 'Opportunities',
    icon: <BageIcon />,
    iconActive: <BageIcon fill={Colors.WHITE} />,
    link: '/opportunities',
  },
  {
    text: 'Dashboard',
    icon: <PiechartIcon />,
    iconActive: <PiechartIcon fill={Colors.WHITE} />,
    link: '/dashboard',
  },
  {
    text: 'Planner',
    icon: <PlannerIcon />,
    iconActive: <PlannerIcon fill={Colors.WHITE} />,
    link: '/planner',
  },
  {
    text: 'FAQ',
    icon: <QuestionCircleFilled style={{ color: Colors.ICONS_BASE_COLOR }} />,
    iconActive: <QuestionCircleOutlined style={{ color: Colors.WHITE }} />,
    link: '/faq',
  },
];

if (process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable') {
  menuList.push(...betaVerionsListItems);
}

const LayoutSider = (): JSX.Element => (
  <Layout.Sider className="aside">
    <nav className="nav-menu">
      <ul>
        {menuList.map(({ icon, link, iconActive, text }) => (
          <li key={text}>
            <NavLink
              className="nav-menu__item"
              activeClassName="nav-menu__link-active"
              to={link}
            >
              <div className="nav-icon">{icon}</div>
              <div className="nav-icon nav-icon-active">{iconActive}</div>
              <div className="nav-text">
                <Text modifiers={['accent']}>{text}</Text>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  </Layout.Sider>
);

export default LayoutSider;
