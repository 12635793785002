import React from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
} from 'antd';
import {
  Auth,
  CognitoUser,
} from '@aws-amplify/auth';
import {
  Text,
  Title,
} from '../../../../components/typography/typography';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-slider-left.svg';
import Colors from '../../../../common/enums/Colors';
import REQUIRED_RULES from '../../../../common/validationRules/requiredRules';

interface NewPasswordFormProps {
  onSuccess: () => void;
  onAbort: () => void;
  authUser: CognitoUser | null;
}

interface ChangePasswordFormData {
  password: string;
}

const NewPasswordForm = ({ authUser, onSuccess, onAbort }: NewPasswordFormProps): JSX.Element => {
  const [passwordChangeForm] = Form.useForm();

  const onPasswordChangeFinish = async (data: ChangePasswordFormData) => {
    if (!authUser) {
      onAbort();
      return;
    }
    try {
      await Auth.completeNewPassword(authUser, data.password);
      onSuccess();
    } catch (error) {
      const formData = passwordChangeForm.getFieldsValue();
      passwordChangeForm.setFields([
        {
          name: 'password',
          errors: [error.message],
          value: formData.password,
        },
      ]);
    }
  };

  return (
    <div className="login-card__content login-card__password-change">
      <button
        type="button"
        className="login-card__return-button"
        onClick={() => onAbort()}
      >
        <ArrowLeft fill={Colors.ICONS_KHAKI} />
      </button>
      <>
        <div className="login-title">
          <Title modifiers={['thin']}></Title>
        </div>
        <Form
          form={passwordChangeForm}
          name="normal_login"
          className="login-form"
          onFinish={onPasswordChangeFinish}
        >
          <p>
            <Text>
              Please type your personal password instead of temporary
              one.
            </Text>
          </p>
          <Form.Item
            name="password"
            rules={REQUIRED_RULES}
          >
            <Input.Password
              size="large"
              prefix={<UnlockOutlined style={{ color: Colors.ICONS_KHAKI }} />}
              type="password"
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <button
            type="submit"
            className="sign-field login-regular"
            disabled={
              !!passwordChangeForm
                .getFieldsError()
                .filter(({ errors }) => errors.length).length
            }
          >
            <Text modifiers={['white']}>Submit new password</Text>
          </button>
        </Form>
      </>
    </div>
  );
};

export default NewPasswordForm;
