import React, {
  useEffect,
  useRef,
} from 'react';
import './style.css';
import { Popconfirm } from 'antd';
import { ReactComponent as RightOutlined } from '../../../../assets/icons/right-outlined.svg';
import { ReactComponent as СheckMark } from '../../../../assets/icons/check-mark.svg';
import { NeedType } from '../../../../graphql-generate/graphql-types';

interface NotesAsideItemProps {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onUndoDelete: (id: number) => void;
  id: number;
  title: string;
  isSoftDeleted?: boolean,
  subtitle?: NeedType;
  specifics?: string;
  disabledEdit: boolean;
  idWorkNote?: number;
}

const NotesAsideItem = ({
  onEdit,
  onDelete,
  onUndoDelete,
  id,
  title,
  subtitle,
  specifics,
  idWorkNote,
  disabledEdit,
  isSoftDeleted,
}: NotesAsideItemProps):JSX.Element => {
  // renders

  const element = useRef<HTMLLIElement>(null);
  const isCompleted = title && subtitle && specifics && idWorkNote !== id;
  let classNameIcon = 'notesAsideItem__card__item_icon';
  let classNameTitle = 'notesAsideItem__card__item_text_title';

  if (isCompleted) {
    classNameIcon += ' notesAsideItem__card__item_icon_complete';
    classNameTitle += ' notesAsideItem__card__item_text_title_complete';
  }

  if (isSoftDeleted) {
    classNameTitle += ' notesAsideItem__card__item_text_title_deleted';
  }

  useEffect(() => {
    if (idWorkNote === id) {
      element.current?.focus();
    }
  }, [id, idWorkNote]);

  return (
    <>
      <li
        tabIndex={-1}
        ref={element}
        className="notesAsideItem__card__wrapper"
      >
        <div className="notesAsideItem__card__item_wrapper">
          <div className={classNameIcon}>
            {(isCompleted) ? (
              <СheckMark />
            ) : (
              <RightOutlined />
            )}
          </div>
          <div className="notesAsideItem__card__item_text">
            <span className={classNameTitle}>{title}</span>
            <span className="notesAsideItem__card__item_text_subtitle">{subtitle?.name}</span>
          </div>
        </div>
        <div className="notesAsideItem__card__item_buttons">
          <button
            onClick={() => onEdit(id)}
            disabled={disabledEdit}
            type="button"
            className="notesAsideItem__card__item_edit"
          >
            edit
          </button>
          { isSoftDeleted
            ? (
              <button
                onClick={() => onUndoDelete(id)}
                type="button"
                className="notesAsideItem__card__item_delete"
              >
                undo
              </button>
            )
            : (
              <Popconfirm
                onConfirm={() => onDelete(id)}
                okText="Yes"
                cancelText="No"
                title="Are you sure you want to delete this note?"
                className="notesAsideItem__card__item_delete"
              >
                <button
                  type="button"
                  className="notesAsideItem__card__item_delete"
                >
                  delete
                </button>

              </Popconfirm>
            )}
        </div>
      </li>
    </>
  );
};

export default NotesAsideItem;
