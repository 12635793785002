import React, { useMemo } from 'react';
import './style.css';
import {
  Col,
  Row,
  Radio,
  Divider,
} from 'antd';
import Recommendation from '../../../../../../../../common/interfaces/Opportunity';
import { DataText } from '../../../../../../../../components/typography/typography';

interface DetailModal {
  id: string;
  label: string | null;
  items: {
    title: string;
    text: string | null;
  }[];
  radioItems?:{
    title: string;
    content: React.ReactNode;
  }[];
  footerItem?: {
    text?: string;
    title?: string;
  }
}

interface ViewOpportunityModalItemProps {
  opportunity: Recommendation;
}

const optionsStatus = [
  {
    label: 'Open', value: 'Open',
  },
  {
    label: 'Closed', value: 'Closed',
  },
];
const optionsShared = [
  {
    label: 'Yes', value: 'Yes',
  },
  {
    label: 'No', value: 'No',
  },
];
const optionsMatchMade = [
  {
    label: 'Yes', value: 'Yes',
  },
  {
    label: 'No', value: 'No',
  },
];

const ViewOpportunityModalItem = ({ opportunity }: ViewOpportunityModalItemProps): JSX.Element => {
  const detailModal = useMemo((): DetailModal[] => [
    {
      id: 'OpportunityName',
      label: null,
      items: [{
        title: 'Location', text: opportunity.companyCity,
      },
      {
        title: 'Status', text: opportunity.companyStatus || null,
      },
      {
        title: 'Closing date', text: opportunity.closingDate || null,
      }],
    },
    {
      id: 'OpportunityStatus',
      label: 'Opportunity status',
      items: [{
        title: 'Date submitted', text: opportunity.dateSubmitted || null,
      },
      {
        title: 'Number of connections', text: opportunity.numberOfConnections || null,
      },
      {
        title: 'Match updated at', text: opportunity.matchUpdated || null,
      }],
      radioItems: [{
        title: 'Status',
        content: <Radio.Group
          size="small"
          disabled
          options={optionsStatus}
          defaultValue="Closed"
          optionType="button"
          className="radio__group__button"
        />,
      },
      {
        title: 'Shared',
        content: <Radio.Group
          size="small"
          disabled
          options={optionsShared}
          defaultValue="No"
          optionType="button"
          className="radio__group__button"
        />,
      },
      {
        title: 'Match made',
        content: <Radio.Group
          size="small"
          disabled
          options={optionsMatchMade}
          defaultValue="Yes"
          optionType="button"
          className="radio__group__button"
        />,
      }],
      footerItem: {
        title: opportunity.DetailsConnectionTitle,
        text: opportunity.DetailsConnectionDescription,
      },
    },
    {
      id: 'Resources',
      label: 'Resources',
      items: [{
        title: 'Documents', text: opportunity.documents || null,
      },
      {
        title: 'Application email', text: opportunity.applicationEmail || null,
      },
      {
        title: 'Web application', text: opportunity.webApplication || null,
      }],
    },
    {
      id: 'OpportunityProvider',
      label: 'Opportunity provider',
      items: [{
        title: 'Name', text: opportunity.providerName || null,
      },
      {
        title: 'Email', text: opportunity.providerEmail || null,
      },
      {
        title: 'Company', text: opportunity.providerCompany || null,
      }],
    },
    {
      id: 'OpportunitySharer',
      label: 'Opportunity sharer',
      items: [{
        title: 'Name', text: opportunity.sharerName || null,
      },
      {
        title: 'Email', text: opportunity.sharerEmail || null,
      },
      {
        title: 'Affiliation', text: opportunity.sharerAffiliation || null,
      }],
    },
    {
      id: 'OpportunityRequirements',
      label: 'Opportunity requirements',
      items: [{
        title: 'Years of working experience', text: opportunity.yearsOfWorkingExperience || null,
      },
      {
        title: 'Other requirements', text: opportunity.otherRequirements || null,
      },
      {
        title: 'Comments', text: opportunity.requirementsComments || null,
      }],
    },
  ], [opportunity]);

  return (
    <>
      {detailModal.map((item) => (
        <div
          key={item.id}
          className="viewOpportunity__main-item"
        >
          <span className="viewOpportunity__main-item__row_title">{item.label}</span>
          {(item.radioItems) && (
            <Row className="viewOpportunity__main-item__row viewOpportunity__row_radio">
              {item.radioItems.map((itemRadio) => (
                <Col
                  span={8}
                  key={itemRadio.title}
                  className="viewOpportunity__main-item__col"
                >
                  <span className="viewOpportunity_modal__main-item__col-title">{itemRadio.title}</span>
                  {itemRadio.content}
                </Col>
              ))}
            </Row>
          )}
          <Row className="viewOpportunity__main-item__row">
            {item.items.map((itemCol) => (
              <Col
                span={8}
                key={itemCol.title}
                className="viewOpportunity__main-item__col"
              >
                <span className="viewOpportunity_modal__main-item__col-title">{itemCol.title}</span>
                <DataText className="viewOpportunity_modal__main-item__col-text">{itemCol.text}</DataText>
              </Col>
            ))}
          </Row>
          {(item.footerItem) && (
            <div className="viewOpportunity_modal__main-item_footer">
              <span className="viewOpportunity_modal__main-item__col-title">{item.footerItem.title}</span>
              <span className="viewOpportunity_modal__main-item__col-text">{item.footerItem.text}</span>
            </div>
          )}
          {(item.id === 'OpportunityRequirements') ? (
            <></>
          ) : (
            <Divider />
          )}
        </div>
      ))}
    </>
  );
};

export default ViewOpportunityModalItem;
