/* eslint-disable */
//THIS FILE IS GENERATED BY "graphql-codegen". PLEASE DO NOT EDIT
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};

export type AcademicInstitution = {
  __typename?: 'AcademicInstitution';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type AcceptConnectionInvitationInput = {
  memberIdToConnectTo: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptConnectionInvitationPayload = {
  __typename?: 'AcceptConnectionInvitationPayload';
  member?: Maybe<Member>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActivityType = {
  __typename?: 'ActivityType';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export enum ArrangedCallType {
  Google = 'Google'
}

export type Article = {
  __typename?: 'Article';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  author?: Maybe<Member>;
  commentsCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  shareCount?: Maybe<Scalars['Int']>;
  saveCount?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type AssignMembersToPathfinderInput = {
  pathfinderId: Scalars['Int'];
  memberIds: Array<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignMembersToPathfinderPayload = {
  __typename?: 'AssignMembersToPathfinderPayload';
  pathfinderMemberConnections?: Maybe<Array<Maybe<PathfinderMemberConnection>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  link: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type AttachmentInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  link: Scalars['String'];
};

export enum AttachmentScope {
  Photo = 'photo',
  Attachment = 'attachment'
}

export type Career = {
  __typename?: 'Career';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  jobTitle?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  industry?: Maybe<Array<Industry>>;
  isEntrepreneur?: Maybe<Scalars['Boolean']>;
  linkedIn?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  isCurrentJob?: Maybe<Scalars['Boolean']>;
};

export type ChangeEmailInput = {
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeEmailPayload = {
  __typename?: 'ChangeEmailPayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeMembershipTypeInput = {
  membershipTypeId: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeMembershipTypePayload = {
  __typename?: 'ChangeMembershipTypePayload';
  member: Member;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  /** The DateTime when the last message was sent to this channel */
  lastMessageDateTime?: Maybe<Scalars['DateTime']>;
  /** The list of users that belong to this channel */
  users: Array<User>;
  /** The list of messages sent to this channel */
  messages?: Maybe<MessageConnection>;
  /** The number of messages that are unread by the viewer */
  unreadMessageCount: Scalars['Int'];
};


export type ChannelMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<ChannelEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Channel;
};

export type City = {
  __typename?: 'City';
  id: Scalars['Int'];
  countryId: Scalars['Int'];
  name: Scalars['String'];
  country: Country;
  geolocation?: Maybe<Geolocation>;
};

export enum ConnectContext {
  Rec = 'rec',
  Profile = 'profile'
}

export type ConnectWithMemberInput = {
  memberIdToConnectTo: Scalars['Int'];
  context?: Maybe<ConnectContext>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConnectWithMemberPayload = {
  __typename?: 'ConnectWithMemberPayload';
  member?: Maybe<Member>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Int'];
  name: Scalars['String'];
  isoCode?: Maybe<Scalars['String']>;
  cities?: Maybe<Array<City>>;
};

export type CreateChannelInput = {
  userId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateChannelPayload = {
  __typename?: 'CreateChannelPayload';
  channel: Channel;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMemberNotesInput = {
  memberId: Scalars['Int'];
  notesToCreate: Array<NoteCreateInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMemberNotesPayload = {
  __typename?: 'CreateMemberNotesPayload';
  success: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  notes: Array<Note>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePresignedPostInput = {
  fileName: Scalars['String'];
  scope: AttachmentScope;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePresignedPostPayload = {
  __typename?: 'CreatePresignedPostPayload';
  /** The URL that has to be used as the target of the POST request. */
  uploadUrl: Scalars['String'];
  /** The list of form fields that have to be included in the POST request. */
  fields: Array<FormDataField>;
  /** The uploaded file url. */
  fileUrl: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSupportTicketInput = {
  ticketType: SupportTicketType;
  message: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSupportTicketPayload = {
  __typename?: 'CreateSupportTicketPayload';
  ticketId: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};



export type DeleteMemberNotesInput = {
  notesToDelete: Array<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMemberNotesPayload = {
  __typename?: 'DeleteMemberNotesPayload';
  success: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  notes: Array<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMessageInput = {
  messageId: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  message?: Maybe<Message>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Education = {
  __typename?: 'Education';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  qualificationId?: Maybe<Scalars['Int']>;
  academicInstitutionId?: Maybe<Scalars['Int']>;
  grade?: Maybe<Scalars['String']>;
  courseLink?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  qualification?: Maybe<Qualification>;
  academicInstitution?: Maybe<AcademicInstitution>;
};

export type Event = {
  __typename?: 'Event';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  type?: Maybe<EventType>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  host?: Maybe<Scalars['String']>;
  hostOnline?: Maybe<Scalars['Boolean']>;
  eventPlatform?: Maybe<EventPlatform>;
  eventLink?: Maybe<Scalars['String']>;
  eventPasscode?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<Scalars['String']>>;
  attachments?: Maybe<Array<Attachment>>;
  additionalInfo?: Maybe<Scalars['String']>;
};

export enum EventPlatform {
  GoogleMeet = 'GoogleMeet',
  Slack = 'Slack',
  Zoom = 'Zoom',
  Discord = 'Discord'
}

export enum EventType {
  KnowledgeRoom = 'KnowledgeRoom',
  AskMeAnything = 'AskMeAnything',
  TheLivingRoom = 'TheLivingRoom',
  ThePathway = 'ThePathway',
  Other = 'Other'
}

export enum ExperienceLevel {
  EntryLevel = 'EntryLevel',
  Intermediate = 'Intermediate',
  MidLevel = 'MidLevel',
  Senior = 'Senior'
}

export type Flag = {
  __typename?: 'Flag';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FormDataField = {
  __typename?: 'FormDataField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum FreeTrialStep {
  Confirmed = 'Confirmed',
  Reapply = 'Reapply',
  Tbd = 'TBD',
  None = 'None'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

export type Geolocation = {
  __typename?: 'Geolocation';
  lng?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
};

export type Industry = {
  __typename?: 'Industry';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  skills: Array<Skill>;
  sector?: Maybe<Sector>;
};

export enum Interest {
  MeetPotentialMentor = 'MeetPotentialMentor',
  MeetPotentialMentee = 'MeetPotentialMentee',
  ReceivePeerSupport = 'ReceivePeerSupport',
  MeetCloseMembers = 'MeetCloseMembers',
  ExpandNetworkWithin = 'ExpandNetworkWithin',
  ExpandNetworkOutside = 'ExpandNetworkOutside'
}

export type Invitation = {
  __typename?: 'Invitation';
  fromMemberId: Scalars['String'];
  toMemberId: Scalars['String'];
};

export type MarkReadInput = {
  channelId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkReadPayload = {
  __typename?: 'MarkReadPayload';
  channel?: Maybe<Channel>;
  viewer?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Describes a user of the Member Portal */
export type Member = {
  __typename?: 'Member';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  theroomId: Scalars['String'];
  userId: Scalars['String'];
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Interest>>;
  freeTrialNextStep?: Maybe<FreeTrialStep>;
  isISAParticipant?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<Array<Flag>>;
  membershipLevelId?: Maybe<Scalars['Int']>;
  membershipLevel?: Maybe<MembershipLevel>;
  educations?: Maybe<Array<Education>>;
  membershipType?: Maybe<MembershipType>;
  /** ex: Premium Paying */
  membershipTypes?: Maybe<Array<MembershipType>>;
  links?: Maybe<Array<MemberLink>>;
  docs?: Maybe<Array<MemberAttachment>>;
  careers?: Maybe<Array<Career>>;
  notes?: Maybe<NoteConnection>;
  skills?: Maybe<Array<Skill>>;
  skillsToLearn?: Maybe<Array<Skill>>;
  industry?: Maybe<Industry>;
  user: User;
  /** Return a list of members that are connected with this member */
  connections?: Maybe<MemberConnection>;
  /** The list of mutual connection between this member and viewer */
  mutualConnections?: Maybe<MemberConnection>;
  /** Return a list of users that have requested to connect with this member */
  invitations?: Maybe<MemberConnection>;
  recommendedMembers?: Maybe<MemberRecommendationConnection>;
  connectionStatusWithViewer?: Maybe<MemberConnectionStatus>;
};


/** Describes a user of the Member Portal */
export type MemberNotesArgs = {
  order?: Maybe<NoteOrder>;
  filter?: Maybe<NoteFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Describes a user of the Member Portal */
export type MemberConnectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Describes a user of the Member Portal */
export type MemberMutualConnectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Describes a user of the Member Portal */
export type MemberInvitationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/** Describes a user of the Member Portal */
export type MemberRecommendedMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type MemberAttachment = {
  __typename?: 'MemberAttachment';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type MemberConnection = {
  __typename?: 'MemberConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<MemberEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum MemberConnectionStatus {
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  InvitationReceived = 'InvitationReceived',
  InvitationSent = 'InvitationSent'
}

export type MemberEdge = {
  __typename?: 'MemberEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Member;
};

export type MemberFilter = {
  q?: Maybe<Scalars['String']>;
  isConnection?: Maybe<Scalars['Boolean']>;
  cityIds?: Maybe<Array<Scalars['Int']>>;
  countryIds?: Maybe<Array<Scalars['Int']>>;
  industryIds?: Maybe<Array<Scalars['Int']>>;
  skillIds?: Maybe<Array<Scalars['Int']>>;
  sectorIds?: Maybe<Array<Scalars['Int']>>;
  membershipLevelIds?: Maybe<Array<Scalars['Int']>>;
  needTypeIds?: Maybe<Array<Scalars['Int']>>;
  yearsOfExperience?: Maybe<YearsOfExperienceFilter>;
  assignedToPathfinderWithId?: Maybe<Scalars['Int']>;
};

export type MemberFilterOption = {
  __typename?: 'MemberFilterOption';
  type?: Maybe<MemberFilterType>;
  optionId?: Maybe<Scalars['Int']>;
  matchCount?: Maybe<Scalars['Int']>;
};

export enum MemberFilterType {
  City = 'City',
  Industry = 'Industry',
  Skill = 'Skill'
}

export type MemberLink = {
  __typename?: 'MemberLink';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MemberOrderBy = {
  createdDateTime: OrderType;
};

export type MemberRecommendation = {
  __typename?: 'MemberRecommendation';
  /** The recommendation item id/email */
  recId: Scalars['String'];
  member: Member;
};

export type MemberRecommendationConnection = {
  __typename?: 'MemberRecommendationConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<MemberRecommendationEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MemberRecommendationEdge = {
  __typename?: 'MemberRecommendationEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: MemberRecommendation;
};

export type MembershipLevel = {
  __typename?: 'MembershipLevel';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  isDeprecated?: Maybe<Scalars['Boolean']>;
};

export type MembershipType = {
  __typename?: 'MembershipType';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  isDeprecated: Scalars['Boolean'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['Int'];
  userId: Scalars['String'];
  channelId: Scalars['String'];
  createdDateTime: Scalars['DateTime'];
  deletedDateTime?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  /** The user that sent the message */
  user?: Maybe<User>;
  /** The channel where the message was sent */
  channel?: Maybe<Channel>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<MessageEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Message;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignMembersToPathfinder: AssignMembersToPathfinderPayload;
  unassignPathfinderToMember: UnassignPathfinderToMemberPayload;
  updateMember: UpdateMemberPayload;
  requestEmailChange: RequestEmailChangePayload;
  changeEmail: ChangeEmailPayload;
  changeMembershipType: ChangeMembershipTypePayload;
  connectWithMember: ConnectWithMemberPayload;
  acceptConnectionInvitation: AcceptConnectionInvitationPayload;
  unconnectWithMember: UnconnectWithMemberPayload;
  createPresignedPost: CreatePresignedPostPayload;
  trackEvents: TrackEventsPayload;
  createSupportTicket: CreateSupportTicketPayload;
  sendMessage: SendMessagePayload;
  createChannel: CreateChannelPayload;
  markRead: MarkReadPayload;
  deleteMessage: DeleteMessagePayload;
  createMemberNotes: CreateMemberNotesPayload;
  updateMemberNotes: UpdateMemberNotesPayload;
  deleteMemberNotes: DeleteMemberNotesPayload;
};


export type MutationAssignMembersToPathfinderArgs = {
  input: AssignMembersToPathfinderInput;
};


export type MutationUnassignPathfinderToMemberArgs = {
  input: UnassignPathfinderToMemberInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationRequestEmailChangeArgs = {
  input: RequestEmailChangeInput;
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationChangeMembershipTypeArgs = {
  input: ChangeMembershipTypeInput;
};


export type MutationConnectWithMemberArgs = {
  input: ConnectWithMemberInput;
};


export type MutationAcceptConnectionInvitationArgs = {
  input: AcceptConnectionInvitationInput;
};


export type MutationUnconnectWithMemberArgs = {
  input: UnconnectWithMemberInput;
};


export type MutationCreatePresignedPostArgs = {
  input: CreatePresignedPostInput;
};


export type MutationTrackEventsArgs = {
  input: TrackEventsInput;
};


export type MutationCreateSupportTicketArgs = {
  input: CreateSupportTicketInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationMarkReadArgs = {
  input: MarkReadInput;
};


export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};


export type MutationCreateMemberNotesArgs = {
  input: CreateMemberNotesInput;
};


export type MutationUpdateMemberNotesArgs = {
  input: UpdateMemberNotesInput;
};


export type MutationDeleteMemberNotesArgs = {
  input: DeleteMemberNotesInput;
};

export type NeedType = {
  __typename?: 'NeedType';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  member?: Maybe<Member>;
  type?: Maybe<NoteType>;
  needTypeId?: Maybe<Scalars['Int']>;
  needType?: Maybe<NeedType>;
  activityTypeId?: Maybe<Scalars['Int']>;
  activityType?: Maybe<ActivityType>;
  specifics?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Attachment>>;
  url?: Maybe<Scalars['String']>;
  arrangeCall: Scalars['Boolean'];
  arrangedCallId?: Maybe<Scalars['String']>;
  arrangedCallType?: Maybe<ArrangedCallType>;
};

export type NoteConnection = {
  __typename?: 'NoteConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<NoteEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type NoteCreateInput = {
  arrangeCall: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  specifics: Scalars['String'];
  type: NoteType;
  status: NoteStatus;
  needTypeId?: Maybe<Scalars['Int']>;
  activityTypeId?: Maybe<Scalars['Int']>;
  /** Attachments as an empty array will remove all existing note attachments */
  attachments?: Maybe<Array<AttachmentInput>>;
  arrangedCallId?: Maybe<Scalars['String']>;
  arrangedCallType?: Maybe<ArrangedCallType>;
};

export type NoteEdge = {
  __typename?: 'NoteEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Note;
};

export type NoteFilter = {
  noteId?: Maybe<Scalars['Int']>;
  type?: Maybe<NoteType>;
};

export enum NoteOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum NoteStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export enum NoteType {
  Need = 'Need',
  Activity = 'Activity'
}

export type NoteUpdateInput = {
  id: Scalars['Int'];
  arrangeCall: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  specifics: Scalars['String'];
  type: NoteType;
  status: NoteStatus;
  needTypeId?: Maybe<Scalars['Int']>;
  activityTypeId?: Maybe<Scalars['Int']>;
  /** Attachments as an empty array will remove all existing note attachments */
  attachments?: Maybe<Array<AttachmentInput>>;
  arrangedCallId?: Maybe<Scalars['String']>;
  arrangedCallType?: Maybe<ArrangedCallType>;
};

export type Notification = {
  __typename?: 'Notification';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  when?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  notificationType?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  sharedByMember?: Maybe<Member>;
  opportunityProvider?: Maybe<Member>;
  title?: Maybe<Scalars['String']>;
  opportunityType?: Maybe<OpportunityType>;
  companyName?: Maybe<Scalars['String']>;
  companyCity?: Maybe<City>;
  details?: Maybe<Scalars['String']>;
  isRemoteWork?: Maybe<Scalars['Boolean']>;
  experienceLevel?: Maybe<ExperienceLevel>;
  otherRequirements?: Maybe<Scalars['String']>;
  otherComments?: Maybe<Scalars['String']>;
  applicationEmail?: Maybe<Scalars['String']>;
  websiteAppLink?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Attachment>>;
  expire?: Maybe<Scalars['DateTime']>;
};

export enum OpportunityType {
  Job = 'Job',
  Membership = 'Membership',
  Mentorship = 'Mentorship',
  Funding = 'Funding',
  Training = 'Training',
  Competition = 'Competition',
  Collaboration = 'Collaboration'
}

export enum OrderType {
  Asc = 'asc',
  Desc = 'desc'
}

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor?: Maybe<Scalars['String']>;
};

/** Describes a user of the Pathfinder Portal */
export type Pathfinder = {
  __typename?: 'Pathfinder';
  userId: Scalars['String'];
};

export type PathfinderMemberConnection = {
  __typename?: 'PathfinderMemberConnection';
  pathfinderId: Scalars['Int'];
  memberId: Scalars['Int'];
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  member?: Maybe<Member>;
};

export enum PathfinderMemberConnectionOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Qualification = {
  __typename?: 'Qualification';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** The currently authenticated user. */
  viewer: User;
  member: Member;
  /** A list of Member Portal users. */
  members?: Maybe<QueryMembersConnection>;
  userRecentlyViewedMembers: UserRecentlyViewedMembersResult;
  pathfinderMemberConnections?: Maybe<Array<Maybe<Member>>>;
  countries?: Maybe<Array<Country>>;
  cities?: Maybe<Array<City>>;
  channel?: Maybe<Channel>;
  industries?: Maybe<Array<Industry>>;
  needType?: Maybe<Array<NeedType>>;
  activityType?: Maybe<Array<ActivityType>>;
  membershipType?: Maybe<Array<MembershipType>>;
  membershipLevel?: Maybe<Array<MembershipLevel>>;
  skills?: Maybe<Array<Skill>>;
  sectors?: Maybe<Array<Sector>>;
  supportTicketTypeOptions: Array<SupportTicketTypeOption>;
  users?: Maybe<Array<User>>;
};


export type QueryMemberArgs = {
  id: Scalars['Int'];
};


export type QueryMembersArgs = {
  filterBy?: Maybe<MemberFilter>;
  orderBy?: Maybe<MemberOrderBy>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryUserRecentlyViewedMembersArgs = {
  count?: Maybe<Scalars['Int']>;
};


export type QueryPathfinderMemberConnectionsArgs = {
  pathfinderId: Scalars['Int'];
  sortOrder?: Maybe<PathfinderMemberConnectionOrder>;
};


export type QueryChannelArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  role?: Maybe<Role>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryMembersConnection = {
  __typename?: 'QueryMembers_Connection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<MemberEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  filters: Array<MemberFilterOption>;
};

export type RequestEmailChangeInput = {
  requestedEmail: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RequestEmailChangePayload = {
  __typename?: 'RequestEmailChangePayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'Admin',
  OppEx = 'OppEx',
  Pathfinder = 'Pathfinder',
  Member = 'Member'
}

export type Schedule = {
  __typename?: 'Schedule';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  when?: Maybe<Scalars['DateTime']>;
  withWhom?: Maybe<Member>;
};

export type Sector = {
  __typename?: 'Sector';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SendMessageInput = {
  channelId: Scalars['String'];
  text: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendMessagePayload = {
  __typename?: 'SendMessagePayload';
  edge?: Maybe<MessageEdge>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Skill = {
  __typename?: 'Skill';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  industry?: Maybe<Array<Industry>>;
};

export enum SupportTicketType {
  Pathfinder = 'Pathfinder',
  ShareOpportunity = 'ShareOpportunity',
  GeneralSupport = 'GeneralSupport',
  TechnicalSupport = 'TechnicalSupport',
  MembershipDetails = 'MembershipDetails',
  Events = 'Events',
  Courses = 'Courses',
  Payments = 'Payments',
  Nomination = 'Nomination'
}

export type SupportTicketTypeOption = {
  __typename?: 'SupportTicketTypeOption';
  value: Scalars['String'];
  name: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TrackEventsInput = {
  events: Array<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TrackEventsPayload = {
  __typename?: 'TrackEventsPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnassignPathfinderToMemberInput = {
  pathfinderId: Scalars['Int'];
  memberId: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnassignPathfinderToMemberPayload = {
  __typename?: 'UnassignPathfinderToMemberPayload';
  pathfinderId: Scalars['Int'];
  memberId: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnconnectWithMemberInput = {
  memberIdToConnectTo: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnconnectWithMemberPayload = {
  __typename?: 'UnconnectWithMemberPayload';
  member?: Maybe<Member>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberInput = {
  id?: Maybe<Scalars['Int']>;
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['Int']>;
  tosAgreedDateTime?: Maybe<Scalars['DateTime']>;
  skillIds?: Maybe<Array<Scalars['Int']>>;
  skillIdsToLearn?: Maybe<Array<Scalars['Int']>>;
  interests?: Maybe<Array<Interest>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberNotesInput = {
  notesToUpdate: Array<NoteUpdateInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberNotesPayload = {
  __typename?: 'UpdateMemberNotesPayload';
  success: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  notes: Array<Note>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberPayload = {
  __typename?: 'UpdateMemberPayload';
  member?: Maybe<Member>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdBy?: Maybe<User>;
  createdDateTime?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  modifiedDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  email: Scalars['String'];
  requestedEmail?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  altEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  avatar?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  messagingDisabled?: Maybe<Scalars['Boolean']>;
  tosAgreedDateTime?: Maybe<Scalars['DateTime']>;
  city?: Maybe<City>;
  /** The details of the member associated with this user */
  member?: Maybe<Member>;
  channels: ChannelConnection;
  pathfinder?: Maybe<Pathfinder>;
  totalUnreadMessageCount?: Maybe<Scalars['Int']>;
  unreadChannelCount?: Maybe<Scalars['Int']>;
  isViewer: Scalars['Boolean'];
};


export type UserChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type UserRecentlyViewedMembersResult = {
  __typename?: 'UserRecentlyViewedMembersResult';
  members?: Maybe<Array<Member>>;
  successful?: Maybe<Scalars['Boolean']>;
};

export type YearsOfExperienceFilter = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type FullMemberFragment = { __typename?: 'Member', id: number, userId: string, title?: Maybe<string>, bio?: Maybe<string>, createdDateTime?: Maybe<any>, careers?: Maybe<Array<{ __typename?: 'Career', jobTitle?: Maybe<string>, isCurrentJob?: Maybe<boolean>, startDate?: Maybe<any>, endDate?: Maybe<any>, companyName?: Maybe<string> }>>, industry?: Maybe<{ __typename?: 'Industry', id: number, name: string }>, membershipTypes?: Maybe<Array<{ __typename?: 'MembershipType', id: number, name: string }>>, membershipLevel?: Maybe<{ __typename?: 'MembershipLevel', id: number, name: string }>, skills?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, skillsToLearn?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, user: { __typename?: 'User', id: string, avatar?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, gender?: Maybe<Gender>, altEmail?: Maybe<string>, dateOfBirth?: Maybe<any>, tosAgreedDateTime?: Maybe<any>, email: string, roles?: Maybe<Array<Role>>, city?: Maybe<{ __typename?: 'City', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }> } };

export type FullUserFragment = { __typename?: 'User', id: string, avatar?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, gender?: Maybe<Gender>, altEmail?: Maybe<string>, dateOfBirth?: Maybe<any>, tosAgreedDateTime?: Maybe<any>, email: string, roles?: Maybe<Array<Role>>, city?: Maybe<{ __typename?: 'City', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }> };

export type ListMemberFragment = { __typename?: 'Member', id: number, bio?: Maybe<string>, careers?: Maybe<Array<{ __typename?: 'Career', jobTitle?: Maybe<string>, isCurrentJob?: Maybe<boolean>, startDate?: Maybe<any>, endDate?: Maybe<any>, companyName?: Maybe<string> }>>, membershipTypes?: Maybe<Array<{ __typename?: 'MembershipType', id: number, name: string }>>, membershipLevel?: Maybe<{ __typename?: 'MembershipLevel', id: number, name: string }>, industry?: Maybe<{ __typename?: 'Industry', id: number, name: string }>, skills?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, user: { __typename?: 'User', avatar?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, roles?: Maybe<Array<Role>>, city?: Maybe<{ __typename?: 'City', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }> } };

export type CreatePresignedPostMutationVariables = Exact<{
  createInput: CreatePresignedPostInput;
}>;


export type CreatePresignedPostMutation = { __typename?: 'Mutation', createPresignedPost: { __typename?: 'CreatePresignedPostPayload', uploadUrl: string, fileUrl: string, fields: Array<{ __typename?: 'FormDataField', key: string, value: string }> } };

export type CreateMemberNotesMutationVariables = Exact<{
  createInput: CreateMemberNotesInput;
}>;


export type CreateMemberNotesMutation = { __typename?: 'Mutation', createMemberNotes: { __typename?: 'CreateMemberNotesPayload', success: boolean, errorMessage?: Maybe<string> } };

export type DeleteMemberNotesMutationVariables = Exact<{
  deleteMemberNotesInput: DeleteMemberNotesInput;
}>;


export type DeleteMemberNotesMutation = { __typename?: 'Mutation', deleteMemberNotes: { __typename?: 'DeleteMemberNotesPayload', success: boolean, errorMessage: string } };

export type UpdateMemberNotesMutationVariables = Exact<{
  updateInput: UpdateMemberNotesInput;
}>;


export type UpdateMemberNotesMutation = { __typename?: 'Mutation', updateMemberNotes: { __typename?: 'UpdateMemberNotesPayload', success: boolean, errorMessage?: Maybe<string> } };

export type GetEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEntitiesQuery = { __typename?: 'Query', countries?: Maybe<Array<{ __typename?: 'Country', id: number, name: string }>>, cities?: Maybe<Array<{ __typename?: 'City', id: number, name: string }>>, sectors?: Maybe<Array<{ __typename?: 'Sector', id: number, name: string }>>, skills?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, needType?: Maybe<Array<{ __typename?: 'NeedType', id: number, name: string }>>, membershipLevel?: Maybe<Array<{ __typename?: 'MembershipLevel', id: number, name: string }>> };

export type MemberNotesConnectionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MemberNotesConnectionQuery = { __typename?: 'Query', member: { __typename?: 'Member', notes?: Maybe<{ __typename?: 'NoteConnection', totalCount?: Maybe<number>, pageInfo: { __typename?: 'PageInfo', endCursor?: Maybe<string>, startCursor?: Maybe<string> }, edges: Array<{ __typename?: 'NoteEdge', node: { __typename?: 'Note', id: number, specifics?: Maybe<string>, needTypeId?: Maybe<number>, type?: Maybe<NoteType>, createdDateTime?: Maybe<any>, url?: Maybe<string>, attachments?: Maybe<Array<{ __typename?: 'Attachment', id: number, name?: Maybe<string>, link: string }>>, needType?: Maybe<{ __typename?: 'NeedType', id: number, name: string }> } }> }> } };

export type MembersConnectionQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterBy?: Maybe<MemberFilter>;
  orderBy?: Maybe<MemberOrderBy>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type MembersConnectionQuery = { __typename?: 'Query', members?: Maybe<{ __typename?: 'QueryMembers_Connection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: Maybe<string>, startCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'MemberEdge', node: { __typename?: 'Member', id: number, bio?: Maybe<string>, careers?: Maybe<Array<{ __typename?: 'Career', jobTitle?: Maybe<string>, isCurrentJob?: Maybe<boolean>, startDate?: Maybe<any>, endDate?: Maybe<any>, companyName?: Maybe<string> }>>, membershipTypes?: Maybe<Array<{ __typename?: 'MembershipType', id: number, name: string }>>, membershipLevel?: Maybe<{ __typename?: 'MembershipLevel', id: number, name: string }>, industry?: Maybe<{ __typename?: 'Industry', id: number, name: string }>, skills?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, user: { __typename?: 'User', avatar?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, roles?: Maybe<Array<Role>>, city?: Maybe<{ __typename?: 'City', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }> } } }> }> };

export type SingleMemberQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SingleMemberQuery = { __typename?: 'Query', member: { __typename?: 'Member', id: number, userId: string, title?: Maybe<string>, bio?: Maybe<string>, createdDateTime?: Maybe<any>, careers?: Maybe<Array<{ __typename?: 'Career', jobTitle?: Maybe<string>, isCurrentJob?: Maybe<boolean>, startDate?: Maybe<any>, endDate?: Maybe<any>, companyName?: Maybe<string> }>>, industry?: Maybe<{ __typename?: 'Industry', id: number, name: string }>, membershipTypes?: Maybe<Array<{ __typename?: 'MembershipType', id: number, name: string }>>, membershipLevel?: Maybe<{ __typename?: 'MembershipLevel', id: number, name: string }>, skills?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, skillsToLearn?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, user: { __typename?: 'User', id: string, avatar?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, gender?: Maybe<Gender>, altEmail?: Maybe<string>, dateOfBirth?: Maybe<any>, tosAgreedDateTime?: Maybe<any>, email: string, roles?: Maybe<Array<Role>>, city?: Maybe<{ __typename?: 'City', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }> } } };

export type GetViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViewerQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, avatar?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, gender?: Maybe<Gender>, altEmail?: Maybe<string>, dateOfBirth?: Maybe<any>, tosAgreedDateTime?: Maybe<any>, email: string, roles?: Maybe<Array<Role>>, member?: Maybe<{ __typename?: 'Member', id: number, userId: string, title?: Maybe<string>, bio?: Maybe<string>, careers?: Maybe<Array<{ __typename?: 'Career', jobTitle?: Maybe<string>, isCurrentJob?: Maybe<boolean>, startDate?: Maybe<any>, endDate?: Maybe<any>, companyName?: Maybe<string> }>>, industry?: Maybe<{ __typename?: 'Industry', id: number, name: string }>, membershipTypes?: Maybe<Array<{ __typename?: 'MembershipType', id: number, name: string }>>, membershipLevel?: Maybe<{ __typename?: 'MembershipLevel', id: number, name: string }>, skills?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>>, skillsToLearn?: Maybe<Array<{ __typename?: 'Skill', id: number, name: string }>> }>, city?: Maybe<{ __typename?: 'City', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }> } };

export type UpdateMemberMutationVariables = Exact<{
  updateInput: UpdateMemberInput;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', updateMember: { __typename?: 'UpdateMemberPayload', clientMutationId?: Maybe<string> } };
