import React from 'react';
import '../../style.css';
import {
  Divider,
  Skeleton,
} from 'antd';

const ProfileMainInfoShimmer = (): JSX.Element => (
  <div className="profile-card__main__content">
    <h3 className="profile-card__main__content__title">
      <Skeleton
        active
        title={{ width: 140 }}
        paragraph={false}
      />
    </h3>
    <div>
      <Skeleton
        active
        title={false}
      />
    </div>
    <div className="profile-card__content__description-row">
      <div className="profile-card__content__description-column">
        <Skeleton active />
        <Skeleton
          active
          title={false}
        />
      </div>
      <div className="profile-card__content__description-column">
        <Skeleton active />
        <Skeleton
          active
          title={false}
        />
      </div>
      <div className="profile-card__content__description-column">
        <Skeleton active />
        <Skeleton
          active
          title={false}
        />
      </div>
    </div>
    <Divider />
    <div className="profile-card__content__description-row">
      <div className="profile-card__content__description-column">
        <Skeleton active />
        <Skeleton title={false} />
      </div>
      <div className="profile-card__content__description-column">
        <Skeleton active />
        <Skeleton title={false} />
      </div>
      <div className="profile-card__content__description-column">
        <Skeleton active />
        <Skeleton
          active
          title={false}
        />
      </div>
    </div>
  </div>
);
export default ProfileMainInfoShimmer;
