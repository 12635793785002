import React from 'react';
import {
  Skeleton,
  Divider,
} from 'antd';

const MembersListShimmer = (): JSX.Element => (
  <div className="membersList__shimmer">
    {[1, 2, 3, 4, 5, 6, 7].map((item) => (
      <div key={item}>
        <Skeleton
          paragraph={{ rows: 4 }}
          active
          avatar={{ size: 'large' }}
        >
        </Skeleton>
        <Divider />
      </div>
    ))}
  </div>

);
export default MembersListShimmer;
