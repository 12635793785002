import opportunities from '../common/dummyData/Opportunities/dummyDataOpportunities';
import { Opportunity } from '../graphql-generate/graphql-types';

enum OpportunitiesFilters {
  NEW = 'new',
  ALL = '',
}

const getOpportunities = async () => opportunities;
const getSingleOpportunity = async (id: number) => opportunities.find((item) => item.id === id) ?? null;
const getOpportunitiesFilterBy = async (filterName: OpportunitiesFilters) => {
  if (filterName === 'new') {
    const newOpportunities = [...opportunities];
    return newOpportunities.reverse();
  }
  return opportunities;
};

const Opportunities = {
  index: {
    key: 'OPPORTUNITIES_LIST',
    exec: (): Promise<Opportunity[]> => getOpportunities(),
  },
  single: {
    key: 'OPPORTUNITIES_SINGLE',
    exec: (id: string): Promise<Opportunity | null> => getSingleOpportunity(+id),
  },
  filterBy: {
    key: 'OPPORTUNITIES_FILTER_BY',
    exec: (filterName: OpportunitiesFilters): Promise<Opportunity[]> => getOpportunitiesFilterBy(filterName),
  },
};

export {
  Opportunities,
  OpportunitiesFilters,
};
