import React, { useMemo } from 'react';
import './style.css';
import { format } from 'date-fns';
import {
  GlobalOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { isNull } from 'lodash';
import InfoAsideLayout from '../../../../components/InfoAsideLayout/InfoAsideLayout';
import DetailInfo from '../../../../common/interfaces/DetailInfo';
import { ReactComponent as MedalIcon } from '../../../../assets/icons/medal.svg';
import { ReactComponent as ProfileCardIcon } from '../../../../assets/icons/profile-card.svg';
import { ReactComponent as CakeIcon } from '../../../../assets/icons/cake.svg';
import { ReactComponent as LogoArrowIcon } from '../../../../assets/icons/small-pathfinder-arrow.svg';
import { ReactComponent as InboxIcon } from '../../../../assets/icons/inbox.svg';
import Colors from '../../../../common/enums/Colors';
import { Member } from '../../../../graphql-generate/graphql-types';
import formatLocationFromMemberDetails from '../../../../common/utils/formatLocationFromMemberDetails';
import getCurrentCareer from '../../../../common/utils/getCurrentCareer';
import getUserFullName from '../../../../common/utils/getUserFullName';
import DateFormats from '../../../../common/enums/DateFormats';

interface ProfileAsideProps {
  member: Member | null;
}
const ProfileAside = ({ member }: ProfileAsideProps): JSX.Element => {
  const tagLists = [
    {
      title: 'Experienced in', tags: member?.skills ?? [],
    },
    {
      title: 'Interested in', tags: member?.skillsToLearn ?? [],
    },
  ];
  const details = useMemo((): DetailInfo[] => {
    if (!member) return [];
    const memberCurrentCareer = getCurrentCareer(member);

    const localTimeString: string | null = null;
    // if (member?.localTime) {
    //   const localTime = addHours(new Date(), parseInt(member.localTime, 10));
    // localTimeString = `Local time: ${format(localTime, DateFormats.DEFAULT_TIME_FORMAT)}`;
    // }
    let location: string | null = null;
    if (member.user?.city) {
      location = formatLocationFromMemberDetails(member.user.city.name, member.user.city.country?.name);
    }
    let pathfinderName: string | null = null;
    if (member.user?.pathfinder) {
      pathfinderName = `Pathfinder: ${member.user.pathfinder.userId}`;
    }
    let birthday: string | null = null;
    if (member.user?.dateOfBirth) {
      birthday = format(new Date(member.user.dateOfBirth), DateFormats.DEFAULT_DATE_FORMAT);
    }
    let email : string | null = null;
    if (member.user?.email) {
      email = member.user.email;
    }

    return [
      {
        id: 'job_title',
        label: memberCurrentCareer?.jobTitle ?? null,
        icon: <ProfileCardIcon fill={Colors.ICONS_BASE_COLOR} />,
      },
      {
        id: 'email',
        label: email,
        icon: <InboxIcon />,
      },
      {
        id: 'birthday',
        label: birthday,
        icon: <CakeIcon />,
      },
      {
        id: 'location',
        label: location,
        icon: <GlobalOutlined style={{ color: Colors.ICONS_BASE_COLOR }} />,
      },
      {
        id: 'localTimeString',
        label: localTimeString,
        icon: <ClockCircleOutlined style={{ color: Colors.ICONS_BASE_COLOR }} />,
      },
      {
        id: 'membershipLevel',
        label: member?.membershipLevel?.name ?? null,
        icon: <MedalIcon style={{ color: Colors.ICONS_BASE_COLOR }} />,
      },
      {
        id: 'pathfinderName',
        label: pathfinderName,
        icon: <LogoArrowIcon />,
      },
    ];
  }, [member]);
  return (
    <InfoAsideLayout
      loading={isNull(member)}
      details={details}
      tagLists={tagLists}
      avatarPalceholderType="user"
      personTitle={getUserFullName(member?.user)}
      avatar={member?.user?.avatar}
    />
  );
};
export default ProfileAside;
