import typographyFactory from '../common/typographyFactory';
import './style.css';

type LinkModifiers = ['golden'];
interface LinkProps extends React.HTMLProps<HTMLSpanElement> {
  children?: React.ReactNode
  modifiers?: LinkModifiers[number][]
}

const Link = (props: LinkProps): JSX.Element => typographyFactory<LinkProps>(props, 'default-link');

export default Link;
