import {
  optionsActivity,
  optionsNeed,
} from '../common/dummyData/Notes/dummyDataOptions';
import SelectOption from '../common/interfaces/SelectOption';
import {
  CreateMemberNotesInput,
  CreateMemberNotesPayload,
  DeleteMemberNotesPayload,
  NoteConnection,
  UpdateMemberNotesInput,
  UpdateMemberNotesPayload,
} from '../graphql-generate/graphql-types';
import deleteMembersNotes from './common/graphql/requests/deleteMemberNotes';
import getMemberNotesConnection from './common/graphql/requests/getMemberNotesConnection';
import createMemberNotes from './common/graphql/requests/createMemberNotes';
import updateMemberNotes from './common/graphql/requests/updateMemberNotes';

const NotesOptions = {
  createNotes: {
    key: 'NOTES_CREATE',
    exec: (createNotesInput: CreateMemberNotesInput): Promise<CreateMemberNotesPayload> => createMemberNotes(createNotesInput),
  },
  deleteNotes: {
    key: 'NOTES_DELETE',
    exec: (notedToDeleteIds: number[]): Promise<DeleteMemberNotesPayload> => deleteMembersNotes(notedToDeleteIds),
  },
  updateNotes: {
    key: 'NOTES_UPDATE',
    exec: (updateNoteInput: UpdateMemberNotesInput): Promise<UpdateMemberNotesPayload> => updateMemberNotes(updateNoteInput),
  },
  getMemberNotes: {
    key: 'NOTES_GET_MEMBER_NOTES',
    exec: (memberId: number): Promise<NoteConnection | null> => getMemberNotesConnection(memberId),
  },
  activities: {
    key: 'NOTES_OPTIONS_ACTIVITIES',
    exec: (): Promise<SelectOption[]> => Promise.resolve(optionsActivity),
  },
  needs: {
    key: 'NOTES_OPTIONS_NEEDS',
    exec: (): Promise<SelectOption[]> => Promise.resolve(optionsNeed),
  },
};

export { NotesOptions };
