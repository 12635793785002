import { nanoid } from '@reduxjs/toolkit';
import Recommendation from '../../interfaces/Opportunity';

const recommendations: Recommendation[] = [
  {
    id: nanoid(),
    title: 'Product Manager',
    opportunityType: 'Internship',
    companyName: 'Facebook',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
  {
    id: nanoid(),
    title: 'Presetation 2021',
    opportunityType: 'Event',
    companyName: 'Event Co',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
  {
    id: nanoid(),
    title: 'Product Manager',
    opportunityType: 'Board Opportunity',
    companyName: 'AWS',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
  {
    id: nanoid(),
    title: 'Product Manager',
    opportunityType: 'ALI',
    companyName: 'Organisation',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
  {
    id: nanoid(),
    title: 'Product Manager',
    opportunityType: 'ALI',
    companyName: 'Organisation',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
  {
    id: nanoid(),
    title: 'Product Manager',
    opportunityType: 'ALI',
    companyName: 'Organisation',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
  {
    id: nanoid(),
    title: 'Product Manager',
    opportunityType: 'ALI',
    companyName: 'Organisation',
    companyCity: 'Nairobi, Kenya',
    companyStatus: 'Open',
    created: 'Jan 01 2021',
    companyLogo: '',
    details: '',
    companyJob: 'Job',
    description: 'Economist to work in their Education and Youth Empowerment Unit',
    tags: [{
      id: nanoid(), name: 'UI Design',
    }, {
      id: nanoid(), name: 'UX Design',
    }, {
      id: nanoid(), name: 'Figma',
    }, {
      id: nanoid(), name: 'User experience',
    }, {
      id: nanoid(), name: 'Ant Design',
    }, {
      id: nanoid(), name: 'HTML',
    }, {
      id: nanoid(), name: 'CSS',
    }],
    closingDate: 'Jan 10 2021',
    dateSubmitted: 'Jan 10 2021',
    matchUpdated: 'Jan 10 2021 9:04pm GMT',
    numberOfConnections: '10 | View ',
    documents: 'job.pdf',
    applicationEmail: 'apply@here.com',
    webApplication: 'applyhere.com/job',
    providerName: 'Paul Harper',
    providerEmail: 'pharp@email.com',
    providerCompany: 'AWS',
    sharerName: 'Paul Ferry',
    sharerEmail: 'pferry@email.com',
    sharerAffiliation: 'Member / ALX / ALG',
    yearsOfWorkingExperience: '6-10',
    otherRequirements: 'pferry@email.com',
    requirementsComments: 'Follow up email sent to Apost the event.',
    DetailsConnectionTitle: 'Details about connection or match',
    DetailsConnectionDescription: 'A couple of the attendees told Matthew they found out about the webinar from The Room.',
  },
];

export default recommendations;
