import './style.css';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useParams,
  useHistory,
  Link as RouterLink,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import { GlobalOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { ReactComponent as TerminalIcon } from '../../assets/icons/terminal.svg';
import DetailInfo from '../../common/interfaces/DetailInfo';
import Card from '../../components/Card/Card';
import { Link } from '../../components/typography/typography';
import { ReactComponent as ShareIcon } from '../../assets/icons/share-button.svg';
import ProfileAside from './components/ProfileAside/ProfileAside';
import ProfileMainTab from './components/ProfileMainTab/ProfileMainTab';
import ProfileNotesTab from './components/ProfileNotesTab/ProfileNotesTab';
import ShareModal from './components/ProfileRecommendationsListCard/components/ShareModal/ShareModal';
import ProfileRecommendationsTab from './components/ProfileRecommendationsTab/ProfileRecommendationsTab';

import { Recommendations } from '../../api/Recommendations';
import { Connections } from '../../api/Connections';
import { Members } from '../../api/Members';
import formatLocationFromMemberDetails from '../../common/utils/formatLocationFromMemberDetails';
import getCurrentCareer from '../../common/utils/getCurrentCareer';
import getUserFullName from '../../common/utils/getUserFullName';
import { NotesOptions } from '../../api/NotesOptions';
import { NoteType } from '../../graphql-generate/graphql-types';
import ProfileParams from './interfaces/ProfileParams';

const Profile = (): JSX.Element => {
  const history = useHistory();
  const { memberId, tagId } = useParams<ProfileParams>();
  const [activeTab, setActiveTab] = useState<string>('');
  const [showShareMember, setShowShareMember] = useState(false);

  const onSetActiveTab = (index: string) => {
    tabs.forEach((item) => {
      if (item.id === index) {
        history.replace(`${item.link}`);
      }
    });
    setActiveTab(index);
  };

  // Fake data
  const apiRequests = useMemo(() => ({
    recommendationMembers: {
      key: Members.recommendations.key,
      exec: () => Members.recommendations.exec(memberId),
    },
    activities: {
      key: NotesOptions.getMemberNotes.key,
      exec: () => NotesOptions.getMemberNotes.exec(Number(memberId)),
    },
    needs: {
      key: NotesOptions.getMemberNotes.key,
      exec: () => NotesOptions.getMemberNotes.exec(Number(memberId)),
    },
    connections: {
      key: Connections.index.key,
      exec: () => Connections.index.exec(),
    },
    recommendations: {
      key: Recommendations.index.key,
      exec: () => Recommendations.index.exec(),
    },
  }), [memberId]);

  const memberQuery = useQuery([Members.single.key, memberId], () => Members.single.exec(+memberId));

  const tabs = useMemo(() => {
    const tabsList = [
      {
        id: '0',
        link: 'general',
        label: 'Profile',
        component: <ProfileMainTab member={memberQuery.data ?? null} />,
      },
      {
        id: '3',
        link: 'recommendations',
        label: 'Recommendations',
        component: (
          <ProfileRecommendationsTab
            email={memberQuery.data?.user?.email ?? ''}
            showShareButton
            fetchMembers={apiRequests.recommendationMembers}
            fetchRecommendations={apiRequests.recommendations}
          />
        ),
      },
      {
        id: '4',
        link: 'shared',
        label: 'Shared',
        component: (
          <ProfileRecommendationsTab
            email={memberQuery.data?.user?.email ?? ''}
            fetchMembers={apiRequests.recommendationMembers}
            fetchRecommendations={apiRequests.connections}
          />
        ),
      },
    ];
    const betaFeaturesTabs = [
      {
        id: '1',
        link: 'activity',
        label: 'Activity',
        component: (
          <ProfileNotesTab
            fetchActivities={apiRequests.activities}
            notesType={NoteType.Activity}
          />
        ),
      },
      {
        id: '2',
        link: 'needs',
        label: 'Needs',
        component: (
          <ProfileNotesTab
            showTags
            fetchActivities={apiRequests.needs}
            notesType={NoteType.Need}
          />
        ),
      },
    ];
    if (process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable') {
      tabsList.splice(1, 0, ...betaFeaturesTabs);
    }
    return tabsList;
  }, [apiRequests, memberQuery.data]);

  useEffect(() => {
    if (!tagId) {
      history.replace(`${memberId}/general`);
    } else {
      tabs.forEach((item) => {
        if (item.link === tagId) {
          setActiveTab(item.id);
        }
      });
    }
  }, [tagId, memberId, history, tabs]);

  const memberAvatar = (
    memberQuery.data?.user?.avatar ? (
      <img
        className="members-list-card__avatar__image"
        src={memberQuery.data?.user?.avatar}
        alt={`${getUserFullName(memberQuery.data.user)}'s avatar`}
      />
    ) : (
      null
    )
  );

  const shareModalDetails = useMemo(() : DetailInfo[] => {
    let location: string | null = null;
    if (!memberQuery.data) return [];
    const memberCurrentCareer = getCurrentCareer(memberQuery.data);
    if (memberQuery.data?.user?.city) {
      location = formatLocationFromMemberDetails(memberQuery.data.user.city.name, memberQuery.data.user.city.country?.name);
    }

    return [
      {
        id: 'job_name',
        label: memberCurrentCareer?.jobTitle ?? '',
        icon: <TerminalIcon />,
      },
      {
        id: 'location',
        label: location,
        icon: <GlobalOutlined />,
      },
    ];
  }, [memberQuery.data]);

  const newNoteButton = process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
    <RouterLink
      to={{
        pathname: `/members/${memberId}/notes/new/`,
        state: tagId === 'activity' ? ('Activity') : ('Need'),
      }}
      className="profile-card__main__tabs__link"
    >
      + New Note
    </RouterLink>
  ) : <></>;

  return (
    <Card
      className="profile-card"
      float
    >
      <div className="profile-card__header">
        <div className="profile-card__header__breadcrumbs">
          <div className="profile-card__header__breadcrumbs__item profile-card__header__breadcrumbs__item_active">
            <RouterLink to="/members">
              <Link>Members</Link>
            </RouterLink>
          </div>
          <div className="profile-card__header__breadcrumbs__divider">/</div>
          <div className="profile-card__header__breadcrumbs__item">
            {getUserFullName(memberQuery.data?.user)}
          </div>
        </div>
        <div>
          <button
            onClick={() => setShowShareMember(true)}
            type="button"
            className="profile-card__header__share"
          >
            <ShareIcon />
            Share profile
          </button>
          <ShareModal
            onOk={() => setShowShareMember(false)}
            onCancel={() => setShowShareMember(false)}
            visible={showShareMember}
            title="Share Member profile"
            buttonText="Share member"
            typeModal="member"
            email=""
            id="Profile"
            shareName={getUserFullName(memberQuery.data?.user)}
            avatar={memberAvatar}
            description={memberQuery.data?.bio ?? ''}
            details={shareModalDetails}
            tags={memberQuery.data?.skills ?? []}
          />
        </div>
      </div>
      <div className="profile-card__content">
        <div className="profile-card__aside">
          <ProfileAside member={memberQuery.data ?? null} />
        </div>
        <div className="profile-card__main">
          <div className="profile-card__main__tabs">
            <Tabs
              animated={{
                inkBar: false,
                tabPane: false,
              }}
              activeKey={activeTab}
              onChange={onSetActiveTab}
              tabBarExtraContent={{ right: newNoteButton }}
              type="card"
            >
              {tabs.map((item) => (
                <Tabs.TabPane
                  key={item.id}
                  tab={item.label}
                >
                  <Card className="profile-card__main__content">
                    {item.component}
                  </Card>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default Profile;
