import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { useAppSelector } from '../../../store/store';

interface PrivateRouteProps extends RouteProps {
  authenticationPath: string;
}

const PrivateRoute = ({ authenticationPath, ...rest }: PrivateRouteProps): JSX.Element => {
  const authState = useAppSelector(({ auth }) => auth);

  if (authState.user) {
    return <Route {...rest} />;
  }

  return (
    <Redirect
      to={{ pathname: authenticationPath }}
    />
  );
};

export default PrivateRoute;
