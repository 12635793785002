import React from 'react';
import './style.css';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalProps,
  Row,
  Select,
} from 'antd';
import {
  DataText,
  Title,
} from '../../../../../../components/typography/typography';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info.svg';
import AssignedMembers from '../../../../../../common/interfaces/AssignedMembers';

interface ShareModalProps extends ModalProps {
  memberName: string;
  assignedMembers : AssignedMembers[];
  assignedMembersPage?: boolean;
}

const AssignedModal = ({ onOk, assignedMembers, memberName, assignedMembersPage, ...rest }: ShareModalProps): JSX.Element => (
  <Modal
    {...rest}
    forceRender
    footer={[
      <Button
        onClick={onOk}
        disabled={assignedMembers.length === 0}
        type="primary"
        className="Admin-assigned__modal__footer__button"
        key="submit"
      >
        {(assignedMembersPage) ? ('Reassign Members') : ('Assign members')}
      </Button>,
    ]}
    className="base-modal Assigned-modal"
    width={592}
    title={(
      <Title modifiers={['semiBold', 'grey']}>
        {(assignedMembersPage) ? ('Reassign Members') : ('Assign New Members ')}
      </Title>
    )}
  >
    <Alert
      message={`Please confirm you wish to ${assignedMembersPage ? 'Reassign' : 'Assign'} the following members${(assignedMembersPage && memberName) ? '' : (` to ${memberName}`)}.`}
      icon={<InfoIcon />}
      type="info"
      showIcon
      className="Admin-assigned__modal__info"
    />
    <div className="Admin-assigned__members">
      <Row
        align="middle"
        className="Admin-assigned__members__item"
      >
        <Col
          span={14}
          className="Admin-assigned__members__item_name"
        >
          Name
        </Col>
        <Col
          className="Admin-assigned__members__item_name"
        >
          Location
        </Col>
      </Row>
      {assignedMembers.map((item) => (
        <Row
          align="middle"
          className="Admin-assigned__members__item"
          key={item.id}
        >
          <Col
            span={14}
            className="Admin-assigned__members__item_name"
          >
            <DataText>{item.name}</DataText>
          </Col>
          <Col
            className="Admin-assigned__members__item_name"
          >
            <DataText>{item.location}</DataText>
          </Col>
        </Row>
      ))}
    </div>
    {(assignedMembersPage) && (
      <div
        className="Admin-assigned__selects-item"
      >
        <span className="Admin-assigned__selects__title">Reassign to</span>
        <Select
          defaultActiveFirstOption={false}
          showSearch
          placeholder="Enter Pathfinder name"
          className="Admin-assigned__modal__select"
          dropdownClassName="Admin-assigned__dropdown"
        >
        </Select>
      </div>
    )}
  </Modal>
);

export default AssignedModal;
