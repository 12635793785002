import { Skeleton } from 'antd';
import React from 'react';
import './style.css';

const skeletonParagraph = (
  <Skeleton
    active
    title={false}
    paragraph={{ rows: 2 }}
  />
);

const ProfileRecommendationsListCardShimmer = (): JSX.Element => (
  <div className="profile-card__recommendation__list__shimmer">
    <div className="profile-card__recommendation__list-item">
      {skeletonParagraph}
    </div>
    <div className="profile-card__recommendation__list-item">
      {skeletonParagraph}
    </div>
    <div className="profile-card__recommendation__list-item">
      {skeletonParagraph}
    </div>
    <div className="profile-card__recommendation__list-item profile-card__recommendation__list-item_small">
      <Skeleton.Button active />
    </div>
  </div>
);

export default ProfileRecommendationsListCardShimmer;
