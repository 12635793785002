import React from 'react';
import './style.css';

const PaginationsCrumbs = ({ ...rest }: React.HTMLProps<HTMLLIElement>): JSX.Element => (
  <li
    {... rest}
    className="pagination-crumbs"
  >
    <div className="pagination-crumbs__elipsis">•••</div>
  </li>
);

export default PaginationsCrumbs;
