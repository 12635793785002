import { loader } from 'graphql.macro';
import {
  QueryMemberArgs,
  Member,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/queries/getSingleMember.gql');

interface IMemberRequestData {
  member: Member
}

export default async function getSingleMember(id: number): Promise<Member> {
  const membersRequest = await graphQLClient.request<IMemberRequestData, QueryMemberArgs>(query, { id });
  return membersRequest.member;
}
