import React from 'react';
import typographyFactory from '../common/typographyFactory';
import './style.css';

type TitleModifiers = ['large', 'big', 'thin', 'semiBold', 'bold', 'italic', 'grey'];
interface TitleProps {
  children?: React.ReactNode,
  modifiers?: TitleModifiers[number][],
}

const Title = (props: TitleProps): JSX.Element => typographyFactory<TitleProps>(props, 'default-title');

export default Title;
