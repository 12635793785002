import Auth, { CognitoUser } from '@aws-amplify/auth';
import { AnyAction } from 'redux';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { User } from '../graphql-generate/graphql-types';
import graphQLClient from '../api/common/graphql/base';
import { Members } from '../api/Members';

interface AuthState {
  user: User | null,
  isAuthorizationPending: boolean;
  federatedError: string | null;
}

interface SetUserAction extends AnyAction {
  payload: AuthState['user'];
}

interface SetFederatedError extends AnyAction {
  payload: AuthState['federatedError']
}

const initialState: AuthState = {
  user: null,
  isAuthorizationPending: true,
  federatedError: null,
};

export const authorizeUser = createAsyncThunk('auth/authorize', async (providedCognitoUser: CognitoUser | null | undefined, { rejectWithValue }) => {
  let cognitoUser = providedCognitoUser;
  // Try to fetch cognito user if it was not provided
  if (cognitoUser === undefined) {
    cognitoUser = await Auth.currentAuthenticatedUser();
  }
  const idToken = cognitoUser?.getSignInUserSession()?.getIdToken().getJwtToken();
  if (idToken) {
    graphQLClient.setHeader('authorization', idToken);
    const user = await Members.viewer.exec();
    if (user) {
      return user;
    }
  }

  // Logout google account if logged in
  if (window.gapi.auth2?.getAuthInstance()?.isSignedIn) {
    window.gapi.auth2.getAuthInstance().disconnect();
  }
  throw rejectWithValue('User not authorized');
});

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state: AuthState, action: SetUserAction) {
      state.user = action.payload;
    },
    removeUser(state: AuthState) {
      state.user = null;
    },
    setFederatedError(state: AuthState, action: SetFederatedError) {
      state.federatedError = action.payload;
    },
    removeFederatedError(state: AuthState) {
      state.federatedError = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(authorizeUser.pending, (state) => {
      state.isAuthorizationPending = true;
    });
    builder.addCase(authorizeUser.rejected, (state) => {
      // TODO: Define rejected state properly
      state.isAuthorizationPending = false;
    });
    builder.addCase(authorizeUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthorizationPending = false;
    });
  },
});
export const authActions = slice.actions;
export default slice;
