import './style.css';
import { Input } from 'antd';
import {
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { FilterItem } from '../../../../common/interfaces/FiltersList';
import Colors from '../../../../common/enums/Colors';

interface SearchCheckboxProps {
  options?: FilterItem[];
  values?: number[];
  inputValue?: string;
  onChangeInputValue?: (value: string) => void;
  onChange?: (filterdIds: number[]) => void;
  showSearch?: boolean;
}

interface FilterItemCheckbox {
  data: FilterItem;
  checked?: boolean;
}

const SearchCheckbox = ({
  options,
  showSearch,
  values,
  inputValue = '',
  onChangeInputValue,
  onChange,
  ...rest
}: SearchCheckboxProps): JSX.Element => {
  const [allCheckboxValue, setAllCheckboxValue] = useState(true);
  const [checkboxes, setCheckboxes] = useState<FilterItemCheckbox[]>([]);

  const normalizeCheckbox = (item: FilterItem): FilterItemCheckbox => ({
    data: {
      id: item.id,
      name: item.name,
    },
    checked: false,
  });

  // Type cast options list,
  // set active checkboxes from "values" props
  // and maintain "All" checkbox state
  useEffect(() => {
    const normalized: FilterItemCheckbox[] = [];
    setAllCheckboxValue(true);
    options?.forEach((item) => {
      const normalizedItem = normalizeCheckbox(item);
      if (values?.includes(normalizedItem.data.id)) {
        normalizedItem.checked = true;
        setAllCheckboxValue(false);
      }
      normalized.push(normalizedItem);
    });

    setCheckboxes(normalized);
  }, [options, values]);

  const filteredOptions = useMemo(() => {
    if (showSearch === false) return checkboxes;
    if (inputValue.length < 3) {
      return checkboxes.filter((item) => item.checked);
    }
    return checkboxes.filter(
      (item) => item.checked || item.data.name.toLowerCase().startsWith(inputValue.toLocaleLowerCase())
    );
  }, [inputValue, checkboxes, showSearch]);

  const changeOptions = (value: FilterItemCheckbox[]) => {
    onChange?.(
      value.filter((item) => item.checked).map((item) => item.data.id)
    );
    if (!values) setCheckboxes(value); // Do not change checkboxes if "values" provided
  };

  const clearCheckboxes = () => {
    const newCheckboxes = checkboxes.map((item) => ({
      ...item, checked: false,
    }));
    changeOptions(newCheckboxes);
  };

  const changeCheckbox = (id: number, value: boolean) => {
    setAllCheckboxValue(false);
    const newCheckboxes = [...checkboxes];
    const checkboxToChange = newCheckboxes.find((item) => item.data.id === id);
    if (checkboxToChange) checkboxToChange.checked = value;
    changeOptions(newCheckboxes);
  };

  const onChangeAllCheckbox = (event: CheckboxChangeEvent) => {
    const value = event.target.checked;
    if (value) {
      setAllCheckboxValue(value);
      clearCheckboxes();
    }
  };

  const setOnChangeInputValue = (value: string) => {
    if (onChangeInputValue) onChangeInputValue(value);
  };

  let allCheckbox = null;
  if (showSearch === false) {
    allCheckbox = (
      <Checkbox
        checked={allCheckboxValue}
        onChange={(e) => onChangeAllCheckbox(e)}
      >
        All
      </Checkbox>
    );
  }

  const iconStyle = {
    color: Colors.ICONS_LIGHT_GREY_COLOR, fontSize: '12px',
  };
  let clearIcon = <span />;
  if (inputValue) {
    clearIcon = (
      <CloseOutlined
        onClick={() => setOnChangeInputValue('')}
        style={iconStyle}
      />
    );
  }
  const searchIcon = <SearchOutlined style={iconStyle} />;

  return (
    <div
      className="members-view__aside-filters-panel__searchbox"
      {...rest}
    >
      {showSearch === false ? null : (
        <Input
          className="members-view__aside-filters-panel__searchbox__input"
          size="middle"
          onChange={(e) => setOnChangeInputValue(e.target.value)}
          value={inputValue}
          prefix={searchIcon}
          suffix={clearIcon}
          placeholder="Enter the first 3 letters"
        />
      )}

      <fieldset className="members-view__aside-filters-item">
        {filteredOptions.length ? (
          <div className="members-view__aside-filters-list">
            {allCheckbox}
            {filteredOptions.map(({ data, checked }) => (
              <Checkbox
                onChange={(e) => changeCheckbox(data.id, e.target.checked)}
                key={data.id}
                value={data.id}
                checked={checked}
              >
                {data.name}
              </Checkbox>
            ))}
          </div>
        ) : null}
      </fieldset>
    </div>
  );
};

export default SearchCheckbox;
