import React from 'react';
import Text from '../Text/Text';

interface DataTextProps extends React.HTMLProps<HTMLSpanElement> {
  children?: React.ReactNode,
}

const DataText = ({ children, ...otherProps }: DataTextProps): JSX.Element => (
  <>
    {children
      ? <span {...otherProps}>{children}</span>
      : <span><Text modifiers={['italic', 'gray']}>No Data</Text></span>}
  </>

);

export default DataText;
