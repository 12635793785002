import React, { useState } from 'react';
import { useHistory } from 'react-router';
import NotificationMessages from '../../common/enums/NotificationMessages';
import NotificationTypes from '../../common/enums/NotificationTypes';
import LoginNotification from '../../common/interfaces/LoginNotification';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';

const getRedirectString = (seconds: number) => `You will be redirected to Login Page in ${seconds} seconds`;

const ChangePassword = (): JSX.Element => {
  const SECONDS_TO_REDIRECT = 5;
  const history = useHistory();
  const [notification, setNotification] = useState<LoginNotification>();

  const redirectLoginPage = () => history.push('/login');

  const runTimer = () => {
    for (let i = SECONDS_TO_REDIRECT; i >= 0; i--) {
      setTimeout(() => {
        setNotification({
          type: NotificationTypes.SUCCESS,
          text: `${NotificationMessages.SUCCESS_PASSWORD_CHANGE}\n${getRedirectString(i)}`,
        });
        if (i === 0) {
          redirectLoginPage();
        }
      }, 1000 * (SECONDS_TO_REDIRECT - i));
    }
  };

  const onSuccess = () => {
    runTimer();
  };

  return (
    <LoginLayout notification={notification}>
      <ResetPasswordForm onSuccess={onSuccess} />
    </LoginLayout>
  );
};

export default ChangePassword;
