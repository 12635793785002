import './style.css';
import React from 'react';
import Logo from '../BasicLayout/LayoutHeader/Logo';
import Card from '../Card/Card';
import LayoutFooter from '../BasicLayout/LayoutFooter/LayoutFooter';
import LoginNotification from '../../common/interfaces/LoginNotification';
import LoginLayoutNotification from './components/LoginLayoutNotification/LoginLayoutNotification';

interface LoginLayoutProps {
  notification?: LoginNotification | null;
  children: React.ReactNode;
}

const LoginLayout = ({ children, notification }: LoginLayoutProps): JSX.Element => (
  <div className="login-page">
    <header className="login-header">
      <Logo />
    </header>
    <div className="login-notifications">
      <div className="login-notifications__item">
        { notification ? (
          <LoginLayoutNotification notification={notification} />
        ) : null }
      </div>
    </div>
    <Card
      float
      className="login-card"
    >
      {children}
    </Card>
    <LayoutFooter />
  </div>
);

export default LoginLayout;
