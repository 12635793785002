import './style.css';
import { Carousel } from 'antd';
import {
  CarouselProps,
  CarouselRef,
} from 'antd/lib/carousel';
import React, {
  createRef,
  useEffect,
  ReactNodeArray,
  useMemo,
} from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-slider-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-slider-right.svg';
import Colors from '../../common/enums/Colors';
import CarouselShimmer from '../CarouselCard/components/CarouselCardShimmer/CarouselCardShimmer';
interface CardCarouselProps extends CarouselProps {
  buttons?: ReactNodeArray;
  slidesWithShadows?: boolean;
}
const CardCarousel = ({
  children,
  buttons,
  slidesWithShadows,
  ...rest
}: CardCarouselProps): JSX.Element => {
  const sliderRef = createRef<CarouselRef>();
  const settings: CarouselProps = {
    dots: false,
    speed: 300,
    swipeToSlide: true,
    slidesToScroll: 1,
    initialSlide: 0,
    slidesToShow: 4,
    draggable: true,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1741,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1500,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1380,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 1250,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 1050,
        settings: { slidesToShow: 1 },
      },
    ],
  };
  const checkIfLastSlide = () => {
    // react-slick slider works incorrect with non-integer slidesToShow value
    // so we need this little hack to handle last slide properly.
    const currentSlider = sliderRef.current;
    const targetSlide = currentSlider?.innerSlider?.state?.targetSlide;
    const slidesCount = currentSlider?.innerSlider?.props?.children?.length;
    const slidesToShow = Math.floor(
      currentSlider?.innerSlider?.props?.slidesToShow
    );
    return targetSlide + slidesToShow >= slidesCount;
  };

  const buttonsToRender = useMemo(() => buttons?.map((button) => ({
    id: nanoid(),
    component: button,
  })), [buttons]);

  useEffect(() => {
    sliderRef.current?.goTo(0, true);
  }, [children, sliderRef]);

  const nextSlide = () => {
    if (!checkIfLastSlide()) {
      sliderRef.current?.next();
    }
  };

  return (
    <div
      className={`carousel ${slidesWithShadows ? 'shadows-offset' : ''}`}
    >
      <Carousel
        useTransform
        ref={sliderRef}
        {...settings}
        {...rest}
      >
        {children ?? ([1, 2, 3].map((item) => (<CarouselShimmer key={item} />)))}
      </Carousel>
      <div className="carousel-buttons">
        <div className="carousel-links">
          { buttonsToRender?.map((item) => (
            <div
              key={item.id}
              className="carousel-links__item"
            >
              {item.component}
            </div>
          )) }
        </div>
        <div className="carousel-navigation">
          <button
            onClick={() => sliderRef.current?.prev()}
            className="carousel-navigation-button"
            aria-label="Previous slide"
            type="button"
          >
            <ArrowLeft fill={Colors.CONTLORS_BLUE_COLOR} />
          </button>
          <button
            onClick={() => nextSlide()}
            className="carousel-navigation-button"
            aria-label="Next slide"
            type="button"
          >
            <ArrowRight fill={Colors.CONTLORS_BLUE_COLOR} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default CardCarousel;
