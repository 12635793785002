import { Divider } from 'antd';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import format from 'date-fns/format';
import getCuttedText from '../../../../common/utils/getCuttedText';
import {
  DataText,
  Link,
  Text,
  Title,
} from '../../../../components/typography/typography';
import { ReactComponent as ClipIcon } from '../../../../assets/icons/clip.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ReactComponent as CompoundIcon } from '../../../../assets/icons/compound.svg';
import './style.css';
import { Note } from '../../../../graphql-generate/graphql-types';
import DateFormats from '../../../../common/enums/DateFormats';
import ProfileParams from '../../interfaces/ProfileParams';

export interface ProfileNotesListCardProps {
  activity: Note;
  showTags?: boolean;
  hideDivider?: boolean;
}

const ProfileNotesListCard = ({
  hideDivider,
  activity,
}: ProfileNotesListCardProps): JSX.Element => {
  const MAX_DESCRIPTION_LENGTH = 300;
  const [showMore, setShowMore] = useState(false);
  const { memberId } = useParams<ProfileParams>();
  const showButtonText = useMemo(() => showMore ? 'View less' : 'View more', [showMore]);
  const specifics = useMemo(() => activity.specifics ?? '', [activity]);
  const timestampText = useMemo(() => {
    const createdData = new Date(activity.createdDateTime);
    const dateFormat = format(createdData, DateFormats.DATE_SLASH_FORMAT);
    const timeFormat = format(createdData, DateFormats.DEFAULT_TIME_FORMAT);
    return `${timeFormat} | ${dateFormat}`;
  }, [activity]);
  const descriptionText = useMemo(() => showMore
    ? specifics
    : getCuttedText(specifics, MAX_DESCRIPTION_LENGTH), [specifics, showMore]);

  return (
    <li className="profile-card__activity__list-item">
      <div className="profile-card__activity__list-item__header">
        <div className="profile-card__activity__list-item__header__left-side">
          <div className="profile-card__activity__list-item__title">
            <div className="profile-card__activity__list-item__title__text">
              <Title modifiers={['thin']}><DataText>{activity.needType?.name}</DataText></Title>
            </div>
          </div>
        </div>
        <div className="profile-card__activity__list-item__header__right-side">
          {activity.createdDateTime ? (
            <div className="profile-card__activity__list-item__date">
              <Text modifiers={['gray', 'thin']}>{timestampText}</Text>
            </div>
          ) : null}
          <div className="profile-card__activity__list-item__edit">
            {(activity.arrangeCall) && (<Link><CalendarIcon /></Link>)}
            {(activity.url) && (<Link><CompoundIcon /></Link>)}
            {(!!activity.attachments?.length) && (<Link><ClipIcon /></Link>)}
            <RouterLink
              to={{ pathname: `/members/${memberId}/notes/edit/${activity.id}` }}
            >
              <Link>Edit</Link>
            </RouterLink>

          </div>
        </div>
      </div>
      <div className="profile-card__activity__list-item__description">
        <Text>{descriptionText}</Text>
      </div>
      <div className="profile-card__activity__list-item__description__view-more">
        {specifics.length > MAX_DESCRIPTION_LENGTH ? (
          <Link onClick={() => setShowMore(!showMore)}>{showButtonText}</Link>
        ) : null}
      </div>
      {hideDivider ? null : <Divider className="divider" />}
    </li>
  );
};

export default ProfileNotesListCard;
