import { UploadFile } from 'antd/lib/upload/interface';
import {
  NeedType,
  NoteStatus,
  NoteType,
} from '../../../graphql-generate/graphql-types';

interface UploadFileMock {
  uid: string,
  name: string,
  url: string,
  status: UploadFile['status'],
  type: UploadFile['type'],
}
export enum ChannelType {
  GoogleMeet,
  Other
}

export default interface NoteItem {
  // id here decided to be a number as is should be compatble with Note type from GQL-types.
  id: number
  type: NoteType;
  createdDateTime: string;
  subTypeId?: NeedType['id'];
  specifics?: string;
  url?: string;
  call?: string;
  attachments?: UploadFileMock[];
  uploadedFiles?: UploadFile[];
  needStatus?: NoteStatus;
  arrangeCall?: boolean;
  channel?: ChannelType;
  meetingUrl?: string;
  meetingDate?: moment.Moment;
  meetingTime?: moment.Moment;
}
