import React from 'react';
import {
  Radio,
  Form,
  Input,
  Select,
} from 'antd';
import SelectOption from '../../../../common/interfaces/SelectOption';

const { Option } = Select;

interface AffiliationProps {
  afiliationEntity: SelectOption[];
  affiliationCategory: SelectOption[];
  affiliationWithTheRoom: SelectOption[];
  PageType: 'add' | 'edit';
  isEditMode: 'default' | 'edit';
  titleLayout: {
    wrapperCol: {
      offset: number,
    }
  };
  value?: {
    aLGroupAlumni: string;
    afiliationEntity: string;
    affiliationDetails: string;
    affiliationCategory: string;
    affiliationWithTheRoom: string;
    futureCustomerIntervviews: string;
    alumniStatusOnMemberPortal: string;
    participatedInFrontfacingInterview: string;
  }
}

const Affiliation = ({
  value,
  PageType,
  isEditMode,
  titleLayout,
  afiliationEntity,
  affiliationCategory,
  affiliationWithTheRoom,
}: AffiliationProps): JSX.Element => (
  <>
    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Affiliation</h2>
    </Form.Item>

    <Form.Item
      name="AffiliationWithTheRoom"
      label="Affiliation with The Room:"
      initialValue={value?.affiliationWithTheRoom}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.affiliationWithTheRoom}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {affiliationWithTheRoom.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="AffiliationDetails"
      label="Affiliation details:"
      initialValue={value?.affiliationDetails}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.affiliationDetails}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="AfiliationEntity"
      label="Afiliation Entity:"
      initialValue={value?.afiliationEntity}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.afiliationEntity}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {afiliationEntity.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="AffiliationCategory"
      label="Affiliation Category:"
      initialValue={value?.affiliationCategory}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.affiliationCategory}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {affiliationCategory.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="ALGroupAlumni"
      label="AL Group Alumni?:"
      initialValue={value?.aLGroupAlumni}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.aLGroupAlumni}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Yes">
            Yes
          </Radio.Button>
          <Radio.Button value="No">
            No
          </Radio.Button>
          <Radio.Button value="Regional head">
            Regional head
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="AlumniStatusOnMemberPortal"
      label="Alumni status on member portal:"
      initialValue={value?.alumniStatusOnMemberPortal}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.alumniStatusOnMemberPortal}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Completed">
            Completed
          </Radio.Button>
          <Radio.Button value="None">
            None
          </Radio.Button>
          <Radio.Button value="Amethis Finance">
            Amethis Finance
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="ParticipatedInFrontfacingInterview"
      label="Participated in Frontfacing Interview:"
      initialValue={value?.participatedInFrontfacingInterview}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.participatedInFrontfacingInterview}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Interview">
            Interview
          </Radio.Button>
          <Radio.Button value="Webinar">
            Webinar
          </Radio.Button>
          <Radio.Button value="Podcast">
            Podcast
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="FutureCustomerIntervviews"
      label="Future customer intervviews:"
      initialValue={value?.futureCustomerIntervviews}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.futureCustomerIntervviews}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>
  </>
);

export default Affiliation;
