// TODO: Make width fit automaticly

import './style.css';
import React from 'react';
import {
  Select,
  SelectProps,
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ReactComponent as ArrowSelectDown } from '../../assets/icons/arrow-select-down.svg';

interface SelectMenuProps extends SelectProps<SelectValue>{
  list: {
    label: string,
    value: string
  }[],
}

const SelectMenu = ({ list, ...rest }: SelectMenuProps): JSX.Element => {
  const { Option } = Select;
  const options = list.map(({ label, value }) => (
    <Option
      className="select-menu__item"
      value={value}
      key={value}
    >
      {label}
    </Option>
  ));
  return (
    <div className="select-menu">
      <Select
        {...rest}
        dropdownAlign={{ offset: [0, 0] }}
        defaultValue={list[0].value}
        suffixIcon={<ArrowSelectDown />}
        dropdownClassName="select-menu__dropdown"
      >
        {options}
      </Select>
    </div>
  );
};

export default SelectMenu;
