import { loader } from 'graphql.macro';
import {
  UpdateMemberInput,
  UpdateMemberPayload,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/queries/memberUpdate.gql');

interface IUpdateMemberInput{
  updateInput: UpdateMemberInput;
}

export default async function updateMembersInput(input: UpdateMemberInput): Promise<UpdateMemberPayload> {
  const memberMutationRequest = await graphQLClient.request<UpdateMemberPayload, IUpdateMemberInput>(query, { updateInput: input });
  return memberMutationRequest;
}
