import React, {
  useCallback,
  useState,
} from 'react';
import {
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Divider,
  Form,
  Input,
} from 'antd';
import { useHistory } from 'react-router';
import {
  Auth,
  CognitoUser,
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Text,
  Title,
} from '../../../../components/typography/typography';
import googleLogo from '../../../../assets/icons/google_logo.png';
import Colors from '../../../../common/enums/Colors';
import REQUIRED_RULES from '../../../../common/validationRules/requiredRules';
import LoginLayoutError from '../../../../components/LoginLayout/components/LoginLayoutError/LoginLayoutError';
import {
  authActions,
  authorizeUser,
} from '../../../../store/auth';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../store/store';

interface MainFormAuthData {
  username: string;
  password: string;
  remember: boolean;
}

enum ErrorTypes {
  validation = 'Validation error',
  invalidCredentials = 'Incorrect username or password.'
}

interface MainFormProps {
  onNewPasswordChallangeHandler: (user: CognitoUser) => void;
}

const MainForm = ({ onNewPasswordChallangeHandler }: MainFormProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const federatedError = useAppSelector(({ auth }) => auth.federatedError);
  const [mainForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [aboveErrorText, setAboveErrorText] = useState<string | null>(null);

  function getPrettyErrorMessage(error: Error) {
    return (
      {
        InvalidParameterException: ErrorTypes.validation,
        UserNotFoundException: ErrorTypes.invalidCredentials,
        NotAuthorizedException: ErrorTypes.invalidCredentials,
      }[error.name] || error.message
    );
  }

  function setErrors(error: Error) {
    const errorMessage = getPrettyErrorMessage(error);
    setAboveErrorText(errorMessage);
  }
  const setErrorsCallback = useCallback(setErrors, []);

  async function signIn(data: MainFormAuthData) {
    try {
      setLoading(true);
      const authResponse = await Auth.signIn(data.username, data.password);
      const user = authResponse as CognitoUser;
      if (authResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        onNewPasswordChallangeHandler(user);
        setLoading(false);
      } else {
        await dispatch(authorizeUser(user));
        setLoading(false);
        history.push('/home');
        return;
      }
    } catch (error) {
      setErrorsCallback(error);
      setLoading(false);
    }
  }

  const onFinish = (data: MainFormAuthData) => {
    signIn({
      ...data,
      username: data.username.trim(),
      password: data.password.trim(),
    });
  };

  async function federatedSignIn() {
    try {
      if (federatedError) {
        await Auth.signOut();
      }
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } catch (error) {
      setErrorsCallback(error);
    }
  }

  return (
    <div className="login-card__content login-card__main-form">
      <div className="login-title">
        <Title modifiers={['thin']}>Sign in to your account</Title>
      </div>
      {aboveErrorText || federatedError ? (
        <LoginLayoutError text={aboveErrorText ?? federatedError ?? 'User not found'} />
      ) : null}
      <button
        type="button"
        onClick={() => !loading && federatedSignIn()}
        className="sign-field login-google"
      >
        <img
          className="login-google_image"
          src={googleLogo}
          alt=""
        />
        Sign in with Google
      </button>
      <div className="divider-container">
        <Divider>
          <Text>or</Text>
        </Divider>
      </div>
      <Form
        form={mainForm}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onKeyDown={() => {
          dispatch(authActions.removeFederatedError());
          setAboveErrorText(null);
        }}
      >
        <Form.Item
          name="username"
          rules={REQUIRED_RULES}
        >
          <Input
            size="large"
            prefix={<UserOutlined style={{ color: Colors.ICONS_KHAKI }} />}
            placeholder="Username"
            autoComplete="username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={REQUIRED_RULES}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: Colors.ICONS_KHAKI }} />}
            type="password"
            autoComplete="current-password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item className="sign-field__small">
          <div className="sign-row">
            <div></div>
            {/* <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox className="remember">Remember me</Checkbox>
          </Form.Item> */}
            <RouterLink
              className="forgot-password"
              to="/login/forgot-password"
            >
              <Link modifiers={['golden']}>Forgot password</Link>
            </RouterLink>
          </div>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <button
              type="submit"
              className="sign-field login-regular"
              disabled={loading}
            >
              <Text modifiers={['white']}>Sign in with email</Text>
            </button>
          )}
        </Form.Item>
        {/* <footer className="sign-field">
      <Text>New Pathfinder?&nbsp;</Text>
      <Link href="#" golden>
        Register now
      </Link>
    </footer> */}
      </Form>
    </div>
  );
};
export default MainForm;
