import { loader } from 'graphql.macro';
import {
  CreatePresignedPostInput,
  CreatePresignedPostPayload,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/mutations/createPresignedPost.gql');

interface ICreatePresignedPostInput {
  createInput: CreatePresignedPostInput
}

interface ICreatePresignedPostPayload {
  createPresignedPost: CreatePresignedPostPayload,
}

export default async function createPresignedPost(input: CreatePresignedPostInput): Promise<CreatePresignedPostPayload> {
  const createPresignedPostRequest = await graphQLClient.request<ICreatePresignedPostPayload, ICreatePresignedPostInput>(query, { createInput: input });
  return createPresignedPostRequest.createPresignedPost;
}
