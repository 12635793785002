import {
  AnyAction,
  createSlice,
} from '@reduxjs/toolkit';
import {
  City,
  Country,
  Industry,
  MembershipLevel,
  NeedType,
  Sector,
  Skill,
} from '../graphql-generate/graphql-types';

interface EntitiesState {
  entities: {
    cities?: City[];
    countries?: Country[];
    industrySectors?: Industry[];
    sectors?: Sector[];
    skills?: Skill[],
    membershipLevel?: MembershipLevel[];
    needType?: NeedType[];
  } | null;
}

interface SetEntitiesAction extends AnyAction {
  payload: EntitiesState['entities'];
}

const initialState: EntitiesState = { entities: null };

const slice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setEntities(state, action: SetEntitiesAction) {
      const alphabetSort = (first: string, second: string) => first.toLowerCase().localeCompare(second.toLowerCase());
      const cities = action.payload?.cities?.sort((a, b) => alphabetSort(a.name, b.name));
      state.entities = {
        ...state.entities,
        ...action.payload,
        cities,
      };
    },
  },
});

export const getMemberFiltersEntities = (state: EntitiesState): EntitiesState['entities'] => {
  const { entities } = state;
  return ({
    cities: entities?.cities,
    countries: entities?.countries,
    sectors: entities?.sectors,
    skills: entities?.skills,
    membershipLevel: entities?.membershipLevel,
    needType: entities?.needType,
  });
};

export const entitiesActions = slice.actions;
export default slice;
