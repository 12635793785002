import React from 'react';
import './style.css';
import {
  Col,
  Row,
  Skeleton,
} from 'antd';

const skeletonParagraph = (
  <Skeleton
    active
    title={false}
    paragraph={{ rows: 1 }}
  />
);

interface AdminMyTeamTabShimmerProps {
  showItems: number;
}

const AdminMyTeamTabShimmer = ({ showItems } : AdminMyTeamTabShimmerProps): JSX.Element => (
  <>
    {Array.from({ length: showItems }, (_, number) => number + 1).map((item) => (
      <Row
        align="middle"
        className="Admin-myteam__members__item Admin-myteam__members__item__Shimmer"
        key={item}
      >
        <Col
          span={7}
          className="Admin-myteam__members__item_name"
        >
          {skeletonParagraph}
        </Col>
        <Col
          span={4}
          className="Admin-myteam__members__item_name"
        >
          {skeletonParagraph}
        </Col>
        <Col
          span={5}
          className="Admin-myteam__members__item_name"
        >
          {skeletonParagraph}
        </Col>
        <Col
          span={4}
          className="Admin-myteam__members__item_name item__left__Shimmer"
        >
          {skeletonParagraph}
        </Col>
        <Col
          span={4}
          className="Admin-myteam__members__item_name"
        >
          <Skeleton.Button active />
        </Col>
      </Row>
    ))}
  </>
);

export default AdminMyTeamTabShimmer;
