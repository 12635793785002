import './style.css';
import React, {
  useCallback,
  useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'antd';
import { useQuery } from 'react-query';
import {
  useAppDispatch,
  useAppSelector,
} from '../../store/store';
import Card from '../../components/Card/Card';
import {
  Link,
  Title,
} from '../../components/typography/typography';
import CardCarousel from '../../components/CardCarousel/CardCarousel';
import CarouselCard from '../../components/CarouselCard/CarouselCard';
import SelectMenu from '../../components/SelectMenu/SelectMenu';
import ListSchedue from '../../components/ListSchedue/ListSchedue';
import {
  Members,
  MembersFilters,
} from '../../api/Members';
import {
  Opportunities,
  OpportunitiesFilters,
} from '../../api/Opportunities';
import { useTodaySchedule } from '../Planner/Planner.hooks';
import { googleSignIn } from '../../store/google/google.slice';
import { isSignedInSelector } from '../../store/google/google.selectors';

const Home = (): JSX.Element => {
  const user = useAppSelector(({ auth }) => auth.user);
  const isSignedIn = useAppSelector(isSignedInSelector);
  const dispatch = useAppDispatch();
  const [usersFilter, setUsersFilter] = useState<MembersFilters>(MembersFilters.NEW);
  const [opportunitiesFilter, setOpportunitiesFilter] = useState<OpportunitiesFilters>(OpportunitiesFilters.NEW);
  const { events } = useTodaySchedule();
  const handleSignInToGoogleClick = useCallback(() => {
    dispatch(googleSignIn());
  }, [dispatch]);

  const membersListQuery = useQuery([Members.filterBy.key, usersFilter], () => Members.filterBy.exec(usersFilter));
  const opportunitiesListQuery = useQuery(
    [Opportunities.filterBy.key, opportunitiesFilter],
    () => Opportunities.filterBy.exec(opportunitiesFilter),
    { enabled: process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' }
  );

  const linkButtonsMembers = [
    <RouterLink
      key="View all members"
      to="/members"
    >
      <Button
        style={{ borderRadius: '5px' }}
        type="primary"
      >
        View all members
      </Button>
    </RouterLink>,
  ];

  const linkButtonsOpportunities = [
    <Button
      key="Add opportunity"
      style={{ borderRadius: '5px' }}
      type="primary"
      ghost
    >
      Add opportunity
    </Button>,
    <Button
      key="View opportunities"
      style={{ borderRadius: '5px' }}
      type="primary"
    >
      View opportunities
    </Button>,
  ];

  const membersOptions = [
    {
      label: 'New Members', value: MembersFilters.NEW,
    },
    {
      label: 'Recent members', value: MembersFilters.ALL,
    },
  ];
  const opportunitiesOptions = [
    {
      label: 'New opportunities', value: OpportunitiesFilters.NEW,
    },
    {
      label: 'Recent opportunities', value: OpportunitiesFilters.ALL,
    },
  ];

  if (!user) return <></>;
  return (
    <Card
      float
      className="content"
    >
      <Card className="welcome">
        <h1 className="welcome__header">
          <Title modifiers={['large']}>
            {'Welcome '}
            {user?.firstName ?? user?.email ?? ''}
          </Title>
        </h1>
        <h3 className="welcome__subheader">
          <Title modifiers={['big']}>
            {!isSignedIn && (
              <>
                To see your schedule
                {' '}
                <Link onClick={handleSignInToGoogleClick}>Sign In to Google</Link>
              </>
            )}
            {isSignedIn && (events.length ? 'Your schedule for today' : 'Your schedule is clear today')}
          </Title>
        </h3>
        <ListSchedue
          events={events}
        />
        <RouterLink
          to="/planner"
          className="welcome__button"
        >
          View schedule
        </RouterLink>
      </Card>
      <div className="home-groups">
        <div className="home-slider">
          <div className="home-slider__title">
            <Title>Members</Title>
            {
              process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
                <SelectMenu
                  list={membersOptions}
                  onChange={(e) => setUsersFilter(e as MembersFilters)}
                  className="home-slider__filter"
                />
              ) : null
            }
          </div>
          <CardCarousel
            buttons={linkButtonsMembers}
            slidesWithShadows
          >
            {membersListQuery.data ? membersListQuery.data.map((member) => (
              <CarouselCard
                key={member.id}
                member={member}
              />
            )) : null}
          </CardCarousel>
        </div>
        { process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
          <div className="home-slider">
            <div className="home-slider__title">
              <Title>Opportunities</Title>
              <SelectMenu
                list={opportunitiesOptions}
                onChange={(e) => setOpportunitiesFilter(e as OpportunitiesFilters)}
                className="home-slider__filter"
              />
            </div>
            <CardCarousel
              buttons={linkButtonsOpportunities}
              slidesWithShadows
            >
              {opportunitiesListQuery.data ? opportunitiesListQuery.data.map((opportunity) => (
                <CarouselCard
                  key={opportunity.id}
                  oppotrunity={opportunity}
                />
              )) : null}
            </CardCarousel>
          </div>
        ) : null }
      </div>
    </Card>
  );
};

export default Home;
