import recommendations from '../common/dummyData/Profile/dummyDataRecommendations';
import Recommendation from '../common/interfaces/Opportunity';

const getAllRecommendations = async () => recommendations;

const Recommendations = {
  index: {
    key: 'RECOMMENDATIONS_LIST',
    exec: (): Promise<Recommendation[]> => getAllRecommendations(),
  },
};

export { Recommendations };
