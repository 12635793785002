import React, {
  useState,
  useMemo,
} from 'react';
import './style.css';
import {
  Col,
  Row,
  Divider,
  Button,
  message,
} from 'antd';
import format from 'date-fns/format';
import { ReactComponent as EditPenIcon } from '../../../../assets/icons/editPen.svg';
import {
  AttachmentScope,
  Career,
  User,
} from '../../../../graphql-generate/graphql-types';
import {
  DataText,
  Title,
} from '../../../../components/typography/typography';
import { Members } from '../../../../api/Members';
import DateFormats from '../../../../common/enums/DateFormats';
import getUserFullName from '../../../../common/utils/getUserFullName';
import formatLocationFromuserDetails from '../../../../common/utils/formatLocationFromMemberDetails';
import getCurrentCareer from '../../../../common/utils/getCurrentCareer';
import AdminEditProfileForm from './components/AdminEditProfileForm/AdminEditProfileForm';
import getAllUserRoles from '../../../../common/utils/getAllUserRoles';
import hasAccess from '../../../../common/helpers/hasAccess';
import { FormValues } from './interfaces/interface';
import { useAppDispatch } from '../../../../store/store';
import { authorizeUser } from '../../../../store/auth';
import { uploadAttachment } from '../../../../common/utils/createNoteInput';

interface AdminCartItemProps {
  user?: User | null;
}

interface DetailModal {
  id: string;
  items: {
    title: string;
    text: string | null;
  }[]
}

const AdminCartItem = ({ user } : AdminCartItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editProfileFormVisible, setEditProfileFormVisible] = useState(false);

  const onUpdateMember = async (formValues: FormValues | null) => {
    setEditProfileFormVisible(false);
    if (!user || !formValues) return;
    try {
      let userAvatarLink = user.avatar;
      if (formValues.uploadPhoto) {
        const userAvatarUploadData = await uploadAttachment(formValues.uploadPhoto[0], AttachmentScope.Photo);
        userAvatarLink = userAvatarUploadData.link;
      }
      Members.updateMember.exec({
        id: Number(user.id),
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        avatar: userAvatarLink,
      });
      await dispatch(authorizeUser());
    } catch (error) {
      message.error('Data update error!');
    }
  };

  const details = useMemo((): DetailModal[] => {
    if (!user) return [];
    let userCurrentCareer: Career | null = null;
    if (user?.member) {
      userCurrentCareer = getCurrentCareer(user.member);
    }

    let location: string | null = null;
    if (user?.city) {
      location = formatLocationFromuserDetails(user.city.name, user.city.country?.name);
    }

    let birthday: string | null = null;
    if (user?.dateOfBirth) {
      birthday = format(new Date(user.dateOfBirth), DateFormats.ADMIN_DATE_FORMAT);
    }

    let joined: string | null = null;
    if (userCurrentCareer?.startDate) {
      joined = format(new Date(userCurrentCareer.startDate), DateFormats.ADMIN_DATE_FORMAT);
    }

    let numberUsers = 'Number of users';
    if (user && hasAccess(user, 'SEE_NUMBER_MEMBERS')) {
      numberUsers = 'Number of members';
    }
    if (user && hasAccess(user, 'SEE_NUMBER_OPPORTUNITIES')) {
      numberUsers = 'Number of opportunities';
    }

    return ([
      {
        id: 'main',
        items: [
          {
            title: 'Job role',
            text: getAllUserRoles(user),
          },
          {
            title: 'Employment',
            text: 'Full time',
          },
          {
            title: 'Location',
            text: location,
          },
        ],
      },
      {
        id: 'secondary',
        items: [
          {
            title: 'Date of birth',
            text: birthday,
          },
          {
            title: 'Joined',
            text: joined,
          },
          {
            title: numberUsers,
            text: null,
          },
        ],
      },
    ]);
  }, [user]);

  return (
    <div className="Admin__main">
      {(editProfileFormVisible) ? (
        <AdminEditProfileForm
          onSave={onUpdateMember}
          onCancel={() => setEditProfileFormVisible(false)}
          user={user}
        />
      ) : (
        <>
          <div className="Admin__main-title">
            <div className="Admin__main-title__text">
              <Title modifiers={['big']}>
                {getUserFullName(user)}
                {', '}
              </Title>
              {(user && getAllUserRoles(user)) && (
                <span className="Admin__main-item__title_sub">{getAllUserRoles(user)}</span>
              )}
            </div>
            <Button
              type="link"
              onClick={() => setEditProfileFormVisible(true)}
              className="Admin__main-item__button"
            >
              edit
              <EditPenIcon />
            </Button>
          </div>
          {details.map((item) => (
            <Row
              key={item.id}
              className="Admin__main-item__row"
            >
              {item.items.map((itemCol) => (
                <Col
                  span={8}
                  key={itemCol.title}
                  className="Admin__main-item__col"
                >
                  <span className="Admin_modal__main-item__col-title">{itemCol.title}</span>
                  <DataText className="Admin_modal__main-item__col-text">{itemCol.text}</DataText>
                </Col>
              ))}
            </Row>
          ))}
          <Divider />
        </>
      )}
    </div>
  );
};

export default AdminCartItem;
