import './style.css';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Collapse,
  Button,
} from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { useQuery } from 'react-query';
import { Title } from '../../components/typography/typography';
import Card from '../../components/Card/Card';
import MembersListCard from '../../components/MembersListCard/MembersListCard';
import { Members } from '../../api/Members';
import { ReactComponent as TerminalIcon } from '../../assets/icons/terminal.svg';
import ShareModal from '../Profile/components/ProfileRecommendationsListCard/components/ShareModal/ShareModal';
import SearchCheckbox from './components/SelectSearchbox/SelectSearchbox';
import Colors from '../../common/enums/Colors';
import ListNoResults from '../../components/ListNoResults/ListNoResults';
import MembersListShimmer from '../../components/MembersListShimmer/MembersListShimmer';
import DetailInfo from '../../common/interfaces/DetailInfo';
import {
  Member,
  MemberFilter,
} from '../../graphql-generate/graphql-types';
import formatLocationFromMemberDetails from '../../common/utils/formatLocationFromMemberDetails';
import getCurrentCareer from '../../common/utils/getCurrentCareer';
import getUserFullName from '../../common/utils/getUserFullName';
import { useAppSelector } from '../../store/store';
import { getMemberFiltersEntities } from '../../store/entities';
import { getNodesFromConnection } from '../../common/utils/getNodesFromConnection';
import OneStepPagination, { ChangePageData } from '../../components/OneStepPagination/OneStepPagination';
import getSafeMembersConnectionAgrs from '../../common/utils/getSafeMembersConnectionArgs';

const MembersList = (): JSX.Element => {
  // TODO: Think about storing cursor data and filters in URL
  // TODO: Move filters to separate component
  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [afterCursor, setAfterCursor] = useState<string | null>(null);
  const [beforeCursor, setBeforeCursor] = useState<string | null>(null);
  const [sectorCheckboxes, setSectorCheckboxes] = useState<number[]>([]);
  const [membershipLevel, setMembershipLevel] = useState<number[]>([]);
  const [showShareMember, setShowShareMember] = useState(false);
  const [shareDataMember, setShareDataMember] = useState<Member>();
  const [needType, setNeedType] = useState<number[]>([]);
  const [country, setCountry] = useState<number[]>([]);
  const [city, setCity] = useState<number[]>([]);
  const [countryValue, setСountryValue] = useState('');
  const [cityValue, setСityValue] = useState('');

  const memberFilters = useMemo((): MemberFilter => ({
    membershipLevelIds: membershipLevel,
    needTypeIds: needType,
    countryIds: country,
    sectorIds: sectorCheckboxes,
    cityIds: city,
  }), [
    sectorCheckboxes,
    membershipLevel,
    needType,
    country,
    city,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [memberFilters]);

  const membersConnectionQuery = useQuery(
    [Members.filterByParams.key, memberFilters, beforeCursor, afterCursor],
    () => Members.filterByParams.exec(getSafeMembersConnectionAgrs({
      before: beforeCursor,
      after: afterCursor,
      filterBy: memberFilters,
    }, PAGE_SIZE))
  );

  const computedMemberList = useMemo(() => {
    if (!membersConnectionQuery.data) {
      return [];
    }
    return getNodesFromConnection(membersConnectionQuery?.data);
  }, [membersConnectionQuery]);

  const entities = useAppSelector((state) => getMemberFiltersEntities(state.entities));

  const clearFilters = () => {
    setCity([]);
    setCountry([]);
    setMembershipLevel([]);
    setNeedType([]);
    setSectorCheckboxes([]);
    setСountryValue('');
    setСityValue('');
  };

  const onSetShareDataMember = (member: Member) => {
    setShowShareMember(true);
    setShareDataMember(member);
  };

  const changePaginationPage = (changePageData: ChangePageData) => {
    if (!membersConnectionQuery.data) {
      return;
    }
    setAfterCursor(changePageData.after ?? null);
    setBeforeCursor(changePageData.before ?? null);
    setCurrentPage(changePageData.pageNumber);
    window.scrollTo(0, 0);
  };

  const memberAvatar = (
    shareDataMember?.user?.avatar ? (
      <img
        className="members-list-card__avatar__image"
        src={shareDataMember.user.avatar}
        alt={`${getUserFullName(shareDataMember.user)}'s avatar`}
      />
    ) : (
      null
    )
  );

  const shareModalDetailsMember = useMemo(() : DetailInfo[] => {
    if (!shareDataMember) return [];
    const shareMemberCurrentCareer = getCurrentCareer(shareDataMember);
    let location: string | null = null;
    if (shareDataMember?.user?.city) {
      location = formatLocationFromMemberDetails(shareDataMember.user.city.name, shareDataMember.user.city.country?.name);
    }

    return [
      {
        id: 'job_name',
        label: shareMemberCurrentCareer?.jobTitle ?? '',
        icon: <TerminalIcon />,
      },
      {
        id: 'location',
        label: location,
        icon: <GlobalOutlined />,
      },
    ];
  }, [shareDataMember]);

  return (
    <Card
      float
      className="members-view"
    >
      <aside className="members-view__aside">
        <div className="members-view__aside-title">
          <Title>Filter by</Title>
        </div>
        <div className="members-view__aside-filters">
          <div className="members-view__aside-filters-block-wrapper">
            <Collapse
              className="members-view__aside-filters-collapse"
              expandIconPosition="right"
              expandIcon={({ isActive }) => isActive ? (
                <MinusOutlined style={{ color: Colors.ICONS_DARK_GREY_COLOR }} />
              ) : (
                <PlusOutlined style={{ color: Colors.ICONS_DARK_GREY_COLOR }} />
              )}
              defaultActiveKey={['1']}
            >
              <Collapse.Panel
                className="members-view__aside-filters-panel"
                header="Sector"
                key="Sector"
              >
                <SearchCheckbox
                  showSearch={false}
                  options={entities?.sectors}
                  values={sectorCheckboxes}
                  onChange={(value) => setSectorCheckboxes(value)}
                />
              </Collapse.Panel>
              <Collapse.Panel
                className="members-view__aside-filters-panel"
                header="Status"
                key="Status"
              >
                <SearchCheckbox
                  showSearch={false}
                  options={entities?.membershipLevel}
                  values={membershipLevel}
                  onChange={(value) => setMembershipLevel(value)}
                />
              </Collapse.Panel>
              { process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
                <Collapse.Panel
                  className="members-view__aside-filters-panel"
                  header="Need type"
                  key="Need type"
                >
                  <SearchCheckbox
                    showSearch={false}
                    options={entities?.needType}
                    values={needType}
                    onChange={(value) => setNeedType(value)}
                  />
                </Collapse.Panel>
              ) : null}
              <Collapse.Panel
                className="members-view__aside-filters-panel"
                header="Country"
                key="Country"
              >
                <SearchCheckbox
                  showSearch
                  options={entities?.countries}
                  values={country}
                  inputValue={countryValue}
                  onChangeInputValue={setСountryValue}
                  onChange={(value) => setCountry(value)}
                />
              </Collapse.Panel>
              <Collapse.Panel
                className="members-view__aside-filters-panel"
                header="City"
                key="City"
              >
                <SearchCheckbox
                  showSearch
                  options={entities?.cities}
                  values={city}
                  inputValue={cityValue}
                  onChangeInputValue={setСityValue}
                  onChange={(value) => setCity(value)}
                />
              </Collapse.Panel>
            </Collapse>
            <div className="members-view__aside-filters-button-container">
              <Button
                onClick={() => clearFilters()}
                className="members-view__aside-filters-button"
                type="primary"
              >
                Clear filters
              </Button>
            </div>
          </div>
        </div>
      </aside>
      <div className="members-view__main">
        <div className="members-view__list">
          <div className="members-view__list-content">
            <div className="members-view__list-content-wrapper">
              <div className="members-view__list-content-header">
                {(membersConnectionQuery.status === 'success' && membersConnectionQuery?.data?.totalCount > 0) ? (
                  <span>
                    {'showing '}
                    {membersConnectionQuery.data.totalCount}
                    {' results'}
                  </span>
                ) : null}
                {(membersConnectionQuery.status === 'success' && membersConnectionQuery?.data?.totalCount === 0) ? (
                  <div className="members-view__list-content-header__no-results">
                    <ListNoResults />
                  </div>
                ) : null}
              </div>
              {membersConnectionQuery.status === 'loading'
                ? <MembersListShimmer />
                : (computedMemberList?.map((member) => (
                  <MembersListCard
                    onShowShare={() => onSetShareDataMember(member)}
                    showShareButton
                    key={member.id}
                    member={member}
                  />
                )))}
              <ShareModal
                onOk={() => setShowShareMember(false)}
                onCancel={() => setShowShareMember(false)}
                visible={showShareMember}
                title="Share Member profile"
                buttonText="Share member"
                typeModal="member"
                email=""
                id="Members"
                shareName={getUserFullName(shareDataMember?.user)}
                avatar={memberAvatar}
                description={shareDataMember?.bio ?? ''}
                details={shareModalDetailsMember}
                tags={shareDataMember?.skills ?? []}
              />
            </div>
          </div>
          <OneStepPagination
            className="members-view__list-pagination"
            pageInfo={membersConnectionQuery.data?.pageInfo ?? null}
            total={membersConnectionQuery.data?.totalCount ?? 0}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onChangePage={changePaginationPage}
          />
        </div>
      </div>
    </Card>
  );
};
export default MembersList;
