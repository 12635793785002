import React from 'react';
import {
  Radio,
  Form,
  Input,
  Select,
} from 'antd';
import SelectOption from '../../../../common/interfaces/SelectOption';
import './style.css';

const { Option } = Select;
const { TextArea } = Input;

interface CommunicationProps {
  pathfinder: SelectOption[];
  customerSegment: SelectOption[];
  engagementLevel: SelectOption[];
  missionOpportunitiesForm: SelectOption[];
  PageType: 'add' | 'edit';
  isEditMode: 'default' | 'edit';
  titleLayout: {
    wrapperCol: {
      offset: number,
    }
  };
  value?: {
    context: string;
    pathfinder: string;
    onNewsletter: string;
    cstomerSegment: string;
    engagementLevel: string;
    onboardingStatus: string;
    customerCallStatus: string;
    pfIntroCallCompleted: string;
    firstNameForNewsletter: string;
    missionOpportunitiesForm: string;
    mailingListRemovalDetails: string;
  }
}

const Communication = ({
  value,
  PageType,
  isEditMode,
  pathfinder,
  titleLayout,
  customerSegment,
  engagementLevel,
  missionOpportunitiesForm,
}: CommunicationProps): JSX.Element => (
  <>
    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Newsletter</h2>
    </Form.Item>

    <Form.Item
      name="OnNewsletter"
      label="On newsletter:"
      initialValue={value?.onNewsletter}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.onNewsletter}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Yes">
            Yes
          </Radio.Button>
          <Radio.Button value="No">
            No
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="FirstNameForNewsletter"
      label="First Name for Newsletter:"
      initialValue={value?.firstNameForNewsletter}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.firstNameForNewsletter}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="MailingListRemovalDetails"
      label="Mailing list removal details:"
      initialValue={value?.mailingListRemovalDetails}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.mailingListRemovalDetails}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="FirstNameForNewsletter"
      label="First Name for Newsletter:"
      initialValue={value?.firstNameForNewsletter}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.firstNameForNewsletter}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Engagement / status</h2>
    </Form.Item>

    <Form.Item
      name="Pathfinder"
      label="Pathfinder:"
      initialValue={value?.pathfinder}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.pathfinder}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {pathfinder.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="CustomerSegment"
      label="Customer Segment:"
      initialValue={value?.customerCallStatus}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.customerCallStatus}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {customerSegment.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="MissionOpportunitiesForm"
      label="Mission/Opportunities form?:"
      initialValue={value?.missionOpportunitiesForm}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.missionOpportunitiesForm}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {missionOpportunitiesForm.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="Context"
      label="Context:"
      initialValue={value?.context}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.context}</span>
      ) : (
        <TextArea
          rows={3}
          className="Communication-card__textarea"
        />
      )}
    </Form.Item>

    <Form.Item
      name="OnboardingStatus"
      label="Onboarding status:"
      initialValue={value?.onboardingStatus}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.onboardingStatus}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Sceduled">
            Sceduled
          </Radio.Button>
          <Radio.Button value="Completed">
            Completed
          </Radio.Button>
          <Radio.Button value="None">
            None
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="CustomerCallStatus"
      label="Customer Call Status:"
      initialValue={value?.customerCallStatus}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.customerCallStatus}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Completed">
            Completed
          </Radio.Button>
          <Radio.Button value="None">
            None
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="EngagementLevel"
      label="Engagement Level:"
      initialValue={value?.engagementLevel}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.engagementLevel}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {engagementLevel.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="PFIntroCallCompleted"
      label="PF Intro Call Completed?:"
      initialValue={value?.pfIntroCallCompleted}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.pfIntroCallCompleted}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Email sent">
            Email sent
          </Radio.Button>
          <Radio.Button value="Completed">
            Completed
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>
  </>
);

export default Communication;
