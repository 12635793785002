import React from 'react';
import './style.css';

interface LoginLayoutErrorProps {
  text: string
}

const LoginLayoutError = ({ text } : LoginLayoutErrorProps): JSX.Element => (
  <div className="login-page__error-alert">
    {text}
  </div>
);

export default LoginLayoutError;
