import React from 'react';
import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import './style.css';

interface PaginationButtonProps {
  buttonNumber?: number;
  disabled?: boolean;
  active?: boolean;
  type: 'number' | 'prev' | 'next'
  onClick?: () => void;
}

const PaginationButton = ({ buttonNumber, active, disabled, type, onClick, ...rest }: PaginationButtonProps): JSX.Element => {
  const clickAction = () => {
    if (disabled || !onClick) {
      return;
    }
    onClick();
  };

  const buttonModifiers = [];
  let buttonContent: JSX.Element = (<>{buttonNumber ?? ''}</>);
  if (type === 'prev') {
    buttonModifiers.push('pagination-button_prev');
    buttonContent = <LeftOutlined />;
  } else if (type === 'next') {
    buttonModifiers.push('pagination-button_next');
    buttonContent = <RightOutlined />;
  }

  if (disabled) {
    buttonModifiers.push('pagination-button_disabled');
  }

  if (active) {
    buttonModifiers.push('pagination-button_active');
  }
  return (
    <li
      {...rest}
      className={`pagination-button ${buttonModifiers.join(' ')}`}
    >
      <div
        className="pagination-button__content"
        tabIndex={0}
        onClick={clickAction}
        onKeyPress={clickAction}
        role="link"
      >
        {buttonContent}
      </div>
    </li>
  );
};

export default PaginationButton;
