import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import formatRFC3339 from 'date-fns/formatRFC3339';
import parseISO from 'date-fns/parseISO';
import isSameMonth from 'date-fns/isSameMonth';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { v4 } from 'uuid';
import compareAsc from 'date-fns/compareAsc';
import { nanoid } from '@reduxjs/toolkit';
import { queryClient } from '../App';

const PREFIX = process.env.REACT_APP_GOOGLE_EVENT_ID_PREFIX;

export type UseGetEventsByMonthOpts = {
  enabled?: boolean
};

function getMonthRangeFromDate(date: Date): [string, string] {
  return [formatRFC3339(startOfMonth(date)), formatRFC3339(endOfMonth(date))];
}

export function useGetEventsByMonth(year: number, month: number, { enabled }: UseGetEventsByMonthOpts): UseQueryResult<gapi.client.calendar.Event[], unknown> {
  const date = new Date(year, month);
  const [timeMin, timeMax] = getMonthRangeFromDate(date);
  return useQuery(
    ['schedule', 'events', 'range', timeMin, timeMax],
    async () => {
      const response = await window.gapi.client.calendar.events.list({
        calendarId: 'primary',
        timeMin,
        timeMax,
      });

      if (response.status !== 200) {
        throw response;
      }

      return response.result.items.filter(({ id }) => id.startsWith(PREFIX.replaceAll('-', ''))).sort(({ start: aStart }, { start: bStart }) => {
        if (!aStart.dateTime || !bStart.dateTime) return 0;

        return compareAsc(parseISO(aStart.dateTime), parseISO(bStart.dateTime));
      });
    },
    {
      refetchOnWindowFocus: isSameMonth(date, new Date()),
      staleTime: 60 * 1000,
      enabled,
    }
  );
}

export type CreateEventParams = {
  summary: string;
  start: Date;
  end: Date;
  googleMeet?: boolean;
  meetingUrl?: string;
  member: {
    email: string;
    displayName: string;
  };
};

export interface CreatedEventError<T> extends Error {
  meta?: T;
}

export function useCreateEvent(): UseMutationResult<gapi.client.calendar.Event, unknown, CreateEventParams, unknown> {
  return useMutation(async (params: CreateEventParams) => {
    const { start, end, summary, googleMeet, meetingUrl, member } = params;

    let conferenceData;

    if (googleMeet) {
      conferenceData = {
        createRequest: {
          conferenceSolutionKey: { type: 'hangoutsMeet' },
          requestId: nanoid(10),
        },
      };
    } else if (meetingUrl) {
      conferenceData = {
        conferenceSolution: {
          key: { type: 'addOn' },
          name: 'Conference',
        },
        entryPoints: [{
          entryPointType: 'video',
          label: meetingUrl,
          uri: meetingUrl,
        }],
      };
    }

    const response = await window.gapi.client.calendar.events.insert({
      calendarId: 'primary',
      sendNotifications: true,
      conferenceDataVersion: 1,
      resource: {
        id: `${PREFIX}-${v4()}`.replaceAll('-', ''),
        start: { dateTime: formatRFC3339(start) },
        end: { dateTime: formatRFC3339(end) },
        summary,
        attendees: [member],
        conferenceData,
      },
    });

    if (!response.result) {
      const error = new Error('Unable to create Calendar Event') as CreatedEventError<gapi.client.HttpRequestFulfilled<gapi.client.calendar.Event>>;
      error.meta = response;
      throw error;
    }

    return response.result;
  }, {
    onSuccess: (newEvent) => {
      if (newEvent.start.dateTime) {
        const date = parseISO(newEvent.start.dateTime);
        const [timeMin, timeMax] = getMonthRangeFromDate(date);
        queryClient.invalidateQueries(['schedule', 'events', 'range', timeMin, timeMax]);
      }
    },
  });
}

export function useDeleteEvent(): UseMutationResult<gapi.client.calendar.Event, unknown, string, unknown> {
  return useMutation(async (eventId: string) => {
    const response = await window.gapi.client.calendar.events.delete({
      calendarId: 'primary',
      eventId,
    });

    return response.result;
  }, {
    onSuccess: (deletedEvent) => {
      if (deletedEvent.start.dateTime) {
        const date = parseISO(deletedEvent.start.dateTime);
        const [timeMin, timeMax] = getMonthRangeFromDate(date);
        queryClient.invalidateQueries(['schedule', 'events', 'range', timeMin, timeMax]);
      }
    },
  });
}
