import './style.css';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { CognitoUser } from '@aws-amplify/auth';
import MainForm from './components/MainForm/MainForm';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import NewPasswordForm from './components/NewPasswordForm/NewPasswordForm';

type FormState = 'main' | 'passwordChange';

const Login = (): JSX.Element => {
  const history = useHistory();
  const [formStatus, setFormStatus] = useState<FormState>('main');
  const [authUser, setAuthUser] = useState<CognitoUser | null>(null); // Only for NEW_PASSWORD_CHALLENGE purposes
  const formWrap = useRef<HTMLDivElement>(null);
  function changeForm(status: FormState) {
    if (formWrap.current === null) return;
    if (status !== 'main') {
      formWrap.current.style.transform = 'translateX(-330px)';
    } else if (status === 'main') {
      formWrap.current.style.transform = 'translateX(0px)';
    }
    setFormStatus(status);
  }

  useEffect(() => changeForm(formStatus), [formStatus]);

  const newPasswordChallangeHandler = (user: CognitoUser) => {
    setAuthUser(user);
    changeForm('passwordChange');
  };

  const newPasswordSuccess = () => {
    changeForm('main');
    history.push('/');
  };

  return (
    <LoginLayout>
      <div
        ref={formWrap}
        className="login-card__wrapper"
      >
        <MainForm
          onNewPasswordChallangeHandler={newPasswordChallangeHandler}
        />
        {
          formStatus === 'passwordChange'
            ? (
              <NewPasswordForm
                onSuccess={newPasswordSuccess}
                onAbort={() => changeForm('main')}
                authUser={authUser}
              />
            )
            : null
        }
      </div>
    </LoginLayout>
  );
};

export default Login;
