import React, { useState } from 'react';
import {
  Form,
  Input,
} from 'antd';
import {
  useHistory,
  useLocation,
} from 'react-router';
import { RuleObject } from 'rc-field-form/lib/interface';
import Auth from '@aws-amplify/auth';
import {
  Text,
  Title,
} from '../../../../components/typography/typography';
import REQUIRED_RULES from '../../../../common/validationRules/requiredRules';
import LoginLayoutError from '../../../../components/LoginLayout/components/LoginLayoutError/LoginLayoutError';

interface ResetPasswordFormProps {
  onSuccess: () => void;
}

interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm = ({ onSuccess }: ResetPasswordFormProps): JSX.Element => {
  const history = useHistory();
  const [aboveErrorText, setAboveErrorText] = useState<string | null>(null);
  const [passwordResetForm] = Form.useForm<ResetPasswordFormData>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const code = params.get('code');
  if (!email || !code) {
    history.push('/');
  }

  const resetPassword = async ({ password }: ResetPasswordFormData) => {
    if (email && code) {
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
        setAboveErrorText(null);
        onSuccess();
      } catch (error) {
        const { message } = error as Error;
        setAboveErrorText(message);
      }
    }
  };

  const validatePasswordConfirm = {
    validator(_: RuleObject, value: string) {
      if (value && passwordResetForm.getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Passwords do not match!'));
    },
  };

  return (
    <div className="login-card__content login-card__password-reset">
      <div className="login-title">
        <Title modifiers={['thin']}>Reset your password</Title>
      </div>
      {aboveErrorText ? (
        <LoginLayoutError text={aboveErrorText ?? 'User not found'} />
      ) : null}
      <Form
        form={passwordResetForm}
        onFinish={resetPassword}
        name="normal_login"
        className="login-form"
      >
        <Form.Item
          name="password"
          rules={REQUIRED_RULES}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="New password"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          validateTrigger="onBlur"
          rules={[validatePasswordConfirm]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Confirm new password"
            autoComplete="new-password"
          />
        </Form.Item>
        <button
          type="submit"
          className="sign-field login-regular"
          disabled={
            !!passwordResetForm
              .getFieldsError()
              .filter(({ errors }) => errors.length).length
          }
        >
          <Text modifiers={['white', 'italic']}>Change password</Text>
        </button>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
