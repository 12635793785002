import './style.css';
import React, { useMemo } from 'react';
import {
  Button,
  Calendar,
  DatePicker,
  TimePicker,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Card from '../../components/Card/Card';
import { ReactComponent as LeftOutlined } from '../../assets/icons/left-outlined.svg';
import {
  useCalendar,
  useAutoGoogleSignIn,
  useNewEvent,
} from './Planner.hooks';
import { Itinerary } from './itinerary';

const Planner = (): JSX.Element => {
  useAutoGoogleSignIn();
  const { range, date, disabled, handleCreateEvent, handleDateChange, handleRangeChange } = useNewEvent();
  const history = useHistory();
  const { selectedDate, handleChange, handleDateCellRender, handlePanelChange } = useCalendar();
  const value = useMemo(() => moment(selectedDate), [selectedDate]);

  return (
    <Card
      className="planner-card"
      float
    >
      <div className="planner-card__header">
        <div className="planner-card__header__breadcrumbs">
          <div className="planner-card__header__breadcrumbs__item planner-card__header__breadcrumbs__item_active">
            <Button
              type="link"
              onClick={() => history.goBack()}
              className="planner-card__header__button"
            >
              <LeftOutlined />
              {' Back'}
            </Button>
            <DatePicker
              showHour
              showMinute
              value={date}
              onChange={handleDateChange}
            />
            <TimePicker.RangePicker
              value={range}
              showSecond={false}
              onChange={handleRangeChange}
            />
            <Button
              type="link"
              disabled={disabled}
              onClick={handleCreateEvent}
              className="planner-card__header__button"
            >
              TEST: Create Event
            </Button>
          </div>
        </div>
      </div>
      <div className="planner-card__content">
        <div className="planner-card__aside">
          <Itinerary selectedDate={selectedDate} />
        </div>
        <div className="planner-card__main">
          <div className="planner-card__main__tabs">
            <Calendar
              value={value}
              onChange={handleChange}
              onPanelChange={handlePanelChange}
              dateCellRender={handleDateCellRender}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Planner;
