import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const isSignedInSelector = createSelector<
  RootState,
  RootState['google'],
  boolean
>(
  ({ google }) => google,
  (google) => !google.isAuthenticationPending && google.isAuthenticated
);
