import React from 'react';
import { useHistory } from 'react-router';

interface AccessVerificationProps {
  children: React.ReactNode;
  condition: boolean;
  shouldRedirect?: boolean,
}

const AccessVerification = ({ children, condition, shouldRedirect }: AccessVerificationProps): JSX.Element | null => {
  const history = useHistory();
  if (condition) {
    return <>{ children }</>;
  }
  if (shouldRedirect) {
    history.replace('/home');
  }
  return null;
};

export default AccessVerification;
