import {
  AnyAction,
  configureStore,
} from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  ThunkAction,
  ThunkDispatch,
} from 'redux-thunk';

import auth from './auth';
import entities from './entities';
import { googleSlice } from './google';

const store = configureStore({
  reducer: {
    auth: auth.reducer,
    entities: entities.reducer,
    google: googleSlice.reducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

// Use throughout entire app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): ThunkDispatch<RootState, null, AnyAction> => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
