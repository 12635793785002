import './style.css';
import React from 'react';
import { Tooltip } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import {
  GlobalOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import Card, { CardProps } from '../Card/Card';
import {
  Title,
  Link,
  Text,
} from '../typography/typography';
import { ReactComponent as UserPlaceholderIcon } from '../../assets/icons/user-placeholder.svg';
import { ReactComponent as SkyscraperIcon } from '../../assets/icons/skyscraper.svg';
import { ReactComponent as IdCardIcon } from '../../assets/icons/id-card.svg';
import Colors from '../../common/enums/Colors';
import {
  Member,
  Opportunity,
} from '../../graphql-generate/graphql-types';
import getCurrentCareer from '../../common/utils/getCurrentCareer';
import getUserFullName from '../../common/utils/getUserFullName';

interface SliderCardProps extends CardProps {
  member?: Member;
  oppotrunity?: Opportunity;
}

interface Options {
  title: string;
  description: string;
  avatarURL?: string;
  viewLink: string,
  details: {
    id: string,
    icon: React.ReactNode;
    text: string;
  }[];
}

const SliderCard = ({ member, oppotrunity }: SliderCardProps): JSX.Element => {
  let options: Options | null = null;
  if (member) {
    const memberCurrentCareer = getCurrentCareer(member);
    options = {
      avatarURL: member.user?.avatar ?? '',
      title: getUserFullName(member.user),
      description: member.bio ?? '',
      viewLink: `/members/profile/${member.id}`,
      details: [
        {
          id: 'jobTitle',
          icon: <IdCardIcon />,
          text: memberCurrentCareer?.jobTitle ?? '',
        },
        {
          id: 'companyName',
          icon: <SkyscraperIcon />,
          text: memberCurrentCareer?.companyName ?? '',
        },
        {
          id: 'cityName',
          icon: <GlobalOutlined style={{ color: Colors.ICONS_GREY_COLOR }} />,
          text: member.user?.city?.name ?? '',
        },
      ],
    };
  }
  if (oppotrunity) {
    options = {
      avatarURL: undefined,
      title: oppotrunity.title ?? '',
      description: oppotrunity.details ?? '',
      viewLink: '',
      details: [
        {
          id: 'opportunityType',
          icon: <ClockCircleOutlined style={{ color: Colors.ICONS_GREY_COLOR }} />,
          text: oppotrunity.opportunityType ?? '',
        },
        {
          id: 'companyName',
          icon: <SkyscraperIcon />,
          text: oppotrunity.companyName ?? '',
        },
        {
          id: 'companyCity',
          icon: <GlobalOutlined style={{ color: Colors.ICONS_GREY_COLOR }} />,
          text: oppotrunity.companyCity?.name ?? '',
        },
      ],
    };
  }
  if (!options) {
    throw new Error('Slider card should have at leat one of the following porperties: opportunity, member');
  }

  const getCuttedText = (string: string, maxSymbols: number) => {
    if (string.length > maxSymbols) {
      return `${string.slice(0, maxSymbols)}...`;
    }
    return string;
  };

  const { title, avatarURL, details, description } = options;

  return (
    <Card
      white
      float
      className="slider-card"
    >
      <div className="slider-card__header">
        <Title modifiers={['semiBold']}>
          {title}
        </Title>
        <RouterLink to={options.viewLink}><Link>View</Link></RouterLink>
      </div>
      <div className="slider-card__data">
        <div className="slider-card__avatar">
          { avatarURL ? (
            <img
              src={avatarURL}
              alt=""
            />
          ) : <UserPlaceholderIcon />}
        </div>
        <ul className="slider-card__details">
          {details.map((detail) => (
            <li
              key={detail.id}
              className="slider-card__details-item"
            >
              <div className="slider-card__details-icon">{detail.icon}</div>
              {detail?.text?.length < 20 ? (
                <Text modifiers={['accent']}>{detail.text}</Text>
              ) : (
                <Tooltip
                  placement="top"
                  title={detail.text}
                >
                  <span><Text modifiers={['accent']}>{getCuttedText(detail.text, 17)}</Text></span>
                </Tooltip>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="slider-card__description">
        <Text>{getCuttedText(description, 150)}</Text>
      </div>
    </Card>
  );
};

export default SliderCard;
