import { loader } from 'graphql.macro';
import {
  DeleteMemberNotesInput,
  DeleteMemberNotesPayload,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/mutations/memberDeleteNotes.gql');

interface IDeleteMemberNotesInput {
  deleteMemberNotesInput: DeleteMemberNotesInput;
}

export default async function deleteMembersNotes(notesToDelete: number[]): Promise<DeleteMemberNotesPayload> {
  const deleteMemberNotesInput = { notesToDelete };
  const notesMutationRequest = await graphQLClient.request<DeleteMemberNotesPayload, IDeleteMemberNotesInput>(query, { deleteMemberNotesInput });
  return notesMutationRequest;
}
