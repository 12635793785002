import './style.css';
import React from 'react';
import {
  Row,
  Col,
  Tooltip,
} from 'antd';
import { useHistory } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../store/store';
import { ReactComponent as BellIcon } from '../../../assets/icons/bell.svg';
import { ReactComponent as ArrowExpandIcon } from '../../../assets/icons/arrow-expander.svg';
import { ReactComponent as SignOutIcon } from '../../../assets/icons/sign-out.svg';
import { ReactComponent as ArrowRigth } from '../../../assets/icons/arrow_rigth.svg';
import { ReactComponent as MembersIcon } from '../../../assets/icons/members.svg';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';
import { ReactComponent as UserPlaceholderIcon } from '../../../assets/icons/user-placeholder.svg';
import Colors from '../../../common/enums/Colors';
import Logo from './Logo';
import AccessVerification from '../../helpers/AccessVerification/AccessVerification';
import { Text } from '../../typography/typography';
import getUserFullName from '../../../common/utils/getUserFullName';
import hasAccess from '../../../common/helpers/hasAccess';
import { useTodaySchedule } from '../../../views/Planner/Planner.hooks';
import { googleSignOut } from '../../../store/google';

const LayoutHeader = (): JSX.Element => {
  const history = useHistory();
  const { events } = useTodaySchedule();
  const user = useAppSelector(({ auth }) => auth.user);
  const dispatch = useAppDispatch();
  const dropdownMenuList = [
    {
      text: 'Sign out',
      action: () => {
        Auth.signOut();
        dispatch(googleSignOut());
      },
      icon: <SignOutIcon />,
    },
  ];

  if (user && hasAccess(user, 'SEE_MY_TEAM_MENU')) {
    dropdownMenuList.splice(0, 0,
      {
        text: 'My Team',
        action: () => history.push('/admin/team'),
        icon: <MembersIcon fill={Colors.WHITE} />,
      });
    if (process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable') {
      dropdownMenuList.splice(0, 0, {
        text: 'Edit profile',
        action: () => history.push('/admin/account'),
        icon: <ArrowRigth />,
      });
    }
  }

  const notificationsList = events.map(({ start, attendees = [] }) => {
    const time = start.dateTime ? format(parseISO(start.dateTime), 'hh:mm aa') : 'N/A';
    const member = attendees[0] || {};
    return {
      title: time,
      type: 'Follow up call',
      text: `You have a call with ${member.displayName || member.email}`,
      action: () => history.push('/planner'),
    };
  });

  const userFullName = getUserFullName(user);

  return (
    <>
      <AccessVerification condition={!!user && hasAccess(user, 'SEE_ADMIN_ALERT_BANNER')}>
        <Row
          justify="center"
          className="admin-label"
        >
          <Text modifiers={['italic', 'thin', 'white']}>You are in administrator mode</Text>
        </Row>
      </AccessVerification>
      <div className="header">
        <Row
          justify="space-between"
          align="middle"
        >
          <Col>
            <Logo />
          </Col>

          {
            process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
              <Col className="header__search">
                <form onSubmit={(e) => e.preventDefault()}>
                  <input
                    placeholder="Search..."
                    type="search"
                  />
                  <button type="submit">Search</button>
                </form>
              </Col>
            ) : null
          }

          <Col className="header-user">
            <div className="header-notifications">
              <div className="header-notifications__icon">
                <DropdownMenu
                  trigger={['click']}
                  placement="bottomLeft"
                  list={notificationsList}
                >
                  <BellIcon />
                </DropdownMenu>
              </div>
              <div className="header-notifications__number"></div>
            </div>
            <DropdownMenu
              trigger={['click']}
              placement="bottomRight"
              list={dropdownMenuList}
            >
              <div className="header-user">
                <div className="header-user__avatar">
                  {user?.avatar ? (
                    <img
                      src={user.avatar}
                      alt=""
                    />
                  ) : <UserPlaceholderIcon />}
                </div>
                <div className="header-user__name">
                  {
                    userFullName.length > 20
                      ? <Tooltip title={userFullName}><span>{`${userFullName.slice(0, 20)}...`}</span></Tooltip>
                      : <span>{userFullName}</span>
                  }
                </div>
                <div className="header-user__expand">
                  <ArrowExpandIcon />
                </div>
              </div>
            </DropdownMenu>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LayoutHeader;
