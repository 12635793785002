import React from 'react';
import moment from 'moment';
import {
  Radio,
  Form,
  Input,
  Button,
  Upload,
  DatePicker,
  Select,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import uploadFile from '../../../../common/utils/uploadFile';
import SelectOption from '../../../../common/interfaces/SelectOption';
import {
  City,
  Country,
  Gender,
  User,
} from '../../../../graphql-generate/graphql-types';
import './style.css';

const { Option } = Select;
const fieldRules = {
  required: true,
  message: 'This field is required',
};

interface PersonalDetailsProps {
  user?: User;
  city?: City[];
  сountry?: Country[];
  citizenship?: SelectOption[];
  titleLayout: {
    wrapperCol: {
      offset: number,
    }
  };
  PageType: 'add' | 'edit';
  isEditMode: 'default' | 'edit';
}

const PersonalDetails = ({
  user,
  city,
  сountry,
  PageType,
  isEditMode,
  citizenship,
  titleLayout,
} : PersonalDetailsProps): JSX.Element => (
  <>
    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Personal details</h2>
    </Form.Item>

    <Form.Item
      getValueFromEvent={uploadFile}
      name="uploadPhoto"
      label="Upload photo:"
      valuePropName="fileList"
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <></>
      ) : (
        <Upload
          action=""
          name="UploadPhoto"
          listType="picture"
        >
          <Button
            className="PersonalDetails-card__upload-button"
            icon={<UploadOutlined />}
          >
            upload
          </Button>
        </Upload>
      )}
    </Form.Item>

    <Form.Item
      name="firstName"
      label="First Name:"
      rules={[fieldRules]}
      initialValue={user?.firstName ?? ''}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.firstName}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="lastName"
      label="Last Name:"
      rules={[fieldRules]}
      initialValue={user?.lastName ?? ''}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.lastName}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="email"
      label="Email:"
      rules={[fieldRules]}
      initialValue={user?.email ?? ''}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.email}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="altEmail"
      label="Alt Email:"
      initialValue={user?.altEmail}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.altEmail}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>

    <Form.Item
      name="gender"
      label="Gender:"
      rules={[fieldRules]}
      initialValue={user?.gender ?? ''}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.gender}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          {Object.values(Gender).map((item) => (
            <Radio.Button
              value={item}
              key={item}
            >
              {item}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="date"
      label="Date of Birth:"
      rules={[fieldRules]}
      initialValue={user?.dateOfBirth ? moment(user.dateOfBirth) : ''}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.dateOfBirth}</span>
      ) : (
        <DatePicker className="Membership-card__datepicker" />
      )}
    </Form.Item>

    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Address</h2>
    </Form.Item>

    <Form.Item
      name="country"
      label="Country:"
      initialValue={user?.city?.country.name}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.city?.country.name}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {сountry?.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="city"
      label="City:"
      initialValue={user?.city?.name}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{user?.city?.name}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {city?.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="citizenship"
      label="Citizenship:"
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span></span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {citizenship?.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  </>
);

export default PersonalDetails;
