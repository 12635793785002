import './style.css';
import React from 'react';
import LoginNotification from '../../../../common/interfaces/LoginNotification';

interface LoginLayoutNotificationProps {
  notification: LoginNotification;
}

const LoginLayoutNotification = ({ notification }: LoginLayoutNotificationProps): JSX.Element => (
  <div className={`login-layout-notification login-layout-notification_${notification.type}`}>
    {notification.text}
  </div>
);

export default LoginLayoutNotification;
