import React from 'react';
import './style.css';
import MembersListCard from '../../../../components/MembersListCard/MembersListCard';
import { Member } from '../../../../graphql-generate/graphql-types';

interface ProfileMembersListCardProps {
  member: Member;
  rating: number;
  showShareButton?: boolean;
  onShowShare: (member: Member) => void;
  onShowView: (member: Member) => void;
}

const ProfileMembersListCard = ({
  member,
  rating,
  showShareButton,
  onShowShare,
  onShowView,
}: ProfileMembersListCardProps): JSX.Element => (
  <>
    <MembersListCard
      showShareButton={showShareButton}
      onShowShare={() => onShowShare(member)}
      onShowView={() => onShowView(member)}
      member={member}
      rating={rating}
    />
  </>
);
export default ProfileMembersListCard;
