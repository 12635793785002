import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  Radio,
  Form,
  Input,
  Select,
  Button,
  Upload,
} from 'antd';
import './style.css';
import {
  useParams,
  useHistory,
  Link as RouterLink,
} from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';
import { UploadOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { UploadFile } from 'antd/lib/upload/interface';
import { nanoid } from '@reduxjs/toolkit';
import { ReactComponent as LeftOutlined } from '../../assets/icons/left-outlined.svg';
import Card from '../../components/Card/Card';
import { Link } from '../../components/typography/typography';
import CardMember from '../../components/CardMember/CardMember';
import NotesAsideItem from './components/NotesAsideItem/NotesAsideItem';
import { Members } from '../../api/Members';
import { NotesOptions } from '../../api/NotesOptions';
import getUserFullName from '../../common/utils/getUserFullName';
import {
  ArrangedCallType,
  NeedType,
  NoteStatus,
  NoteType,
  Note,
} from '../../graphql-generate/graphql-types';
import { useAppSelector } from '../../store/store';
import { getMemberFiltersEntities } from '../../store/entities';
import NoteItem from './interfaces/NoteItem';
import {
  createNoteInput,
  updateNoteInput,
} from '../../common/utils/createNoteInput';
import uploadFile from '../../common/utils/uploadFile';
import getRandomNumberId from '../../common/utils/getRandomNumberId';
import NotesLayoutNotification from './components/NotesLayoutNotification/NotesLayoutNotification';
import INotesLayoutNotification from './interfaces/NotesLayoutNotification';
import { ArrangeCall } from './components/ArrangeCall';
import {
  CreatedEvent,
  useCreateCalendarEvent,
} from './components/ArrangeCall/ArrangeCall.hooks';
import { useDeleteEvent } from '../../api/schedule';

// helpers
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const dropdownAlign = { dropdownAlign: { offset: [0, -35] } };
const fieldRules = {
  required: true,
  message: 'This field is required',
};

const acceptexExtensions = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'jpg',
  'jpeg',
  'png',
  'jpg',
]
  .map((item) => `.${item}`)
  .join(',');

const validateFile = (file: UploadFile) => {
  const isLt20M = file.size / 1024 / 1024 < 20;
  return isLt20M;
};

const NOTIFICATION_HIDE_TIMEOUT = 8_000;
const DEFAULT_NOTE_SAVE_ERROR_MESSAGE = 'Error happened while saving notes';
const NOTE_NO_CHANGES_MESSAGE = 'No changes to save';

const itemNoteFromNoteData = (noteData: Note): ItemNote => {
  const newNote = new ItemNote({
    ...noteData,
    id: noteData.id,
    uploadedFiles: [],
    attachments:
      noteData?.attachments?.map((file) => ({
        uid: String(file.id),
        name: file.name ?? nanoid(),
        status: 'done',
        url: file.link,
        type: 'file',
      })) ?? [],
    // NOTE: Maybe filelds below should be mandatory
    specifics: noteData.specifics ?? '',
    url: noteData.url ?? '',
    subTypeId: noteData.needTypeId ?? undefined,
    type: noteData.type ?? NoteType.Activity,
    createdDateTime: noteData.createdDateTime ?? new Date().toISOString,
  });
  return newNote;
};

interface NotesParams {
  memberId: string;
  pageMode: string;
  selectedNoteId?: string;
}

enum ItemNoteStatus {
  NEW = 'NEW',
  EDITED = 'EDITED',
  DELETED = 'DELETED',
  NOT_TOUCHED = 'NOT_TOUCHED',
}
class ItemNote implements NoteItem {
  id: number;

  type: NoteType;

  subTypeId?: NeedType['id'];

  specifics?: string;

  url?: string;

  uploadedFiles?: UploadFile[];

  attachments?: NoteItem['attachments'];

  status: ItemNoteStatus;

  needStatus?: NoteStatus = NoteStatus.Open;

  createdDateTime: string;

  arrangeCall?: boolean;

  meetingUrl?: string;

  meetingDate?: moment.Moment;

  meetingTime?: moment.Moment;

  constructor(item: NoteItem, itemNoteStatus?: ItemNoteStatus) {
    this.id = item.id;
    this.type = item.type;
    this.subTypeId = item.subTypeId;
    this.specifics = item.specifics ?? '';
    this.url = item.url;
    this.attachments = item.attachments;
    this.arrangeCall = item.arrangeCall;
    this.needStatus = item.needStatus;
    this.createdDateTime = item.createdDateTime;
    this.status = itemNoteStatus ?? ItemNoteStatus.NOT_TOUCHED;
    this.uploadedFiles = item.uploadedFiles;
    this.meetingUrl = item.meetingUrl;
    this.meetingDate = item.meetingDate;
    this.meetingTime = item.meetingTime;
  }

  isValid() {
    let isValid = Boolean(this.specifics) && Boolean(this.subTypeId);

    if (isValid && this.arrangeCall) {
      isValid = Boolean(this.meetingDate) && Boolean(this.meetingTime);
    }

    return isValid;
  }
}

const Notes = (): JSX.Element => {
  const { newCalendarEvent } = useCreateCalendarEvent();
  const { mutateAsync: deleteCalendarEvent } = useDeleteEvent();
  const history = useHistory();
  const [form] = Form.useForm<NoteItem>();
  const { memberId, selectedNoteId, pageMode } = useParams<NotesParams>();
  const [notes, setNotes] = useState<ItemNote[]>([]);
  const computedNotes = useMemo(
    () => [...notes].sort((a, b) => {
      const firstDate = new Date(a.createdDateTime).getTime();
      const secondDate = new Date(b.createdDateTime).getTime();
      return secondDate - firstDate;
    }),
    [notes]
  );
  const [typeNotes, setTypeNotes] = useState<NoteType>(NoteType.Need);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [notesLoading, setNotesLoading] = useState<boolean>(true);
  const memberQuery = useQuery([Members.single.key, memberId], () => Members.single.exec(+memberId));
  const entities = useAppSelector((state) => getMemberFiltersEntities(state.entities));
  const optionsNeed = entities?.needType;
  const currentNote = useMemo(
    () => notes.find((note) => note.id === Number(selectedNoteId)),
    [notes, selectedNoteId]
  );
  const [notification, setNotification]
    = useState<INotesLayoutNotification | null>(null);

  const getNeedTypeNameById = (id?: number) => optionsNeed?.find((item) => item.id === id);
  const onChangeTypeNotes = (e: RadioChangeEvent) => {
    form.resetFields();
    const newNotes = notes.map((item) => {
      let itemNote = item;
      if (item.id === Number(selectedNoteId)) {
        const note = {
          ...item,
          type: e.target.value,
        };
        itemNote = new ItemNote(note);
      }
      return itemNote;
    });
    setNotes(newNotes);
    setTypeNotes(e.target.value);
    form.setFieldsValue({ type: e.target.value });
  };

  const onValuesChangeForm = (values: NoteItem) => {
    const newNotes = notes.map((item) => {
      let itemNote = item;
      if (item.id === Number(selectedNoteId)) {
        itemNote.status
          = itemNote.status === ItemNoteStatus.NEW
            ? ItemNoteStatus.NEW
            : ItemNoteStatus.EDITED;
        if (values?.specifics?.trim() === '') {
          itemNote.specifics = '';
          form.setFieldsValue({ specifics: '' });
        } else if (Array.isArray(values.uploadedFiles)) {
          itemNote.uploadedFiles = [...values.uploadedFiles].filter(
            validateFile
          );
        } else {
          itemNote = Object.assign(itemNote, values);
        }
      }
      return itemNote;
    });
    setNotes(newNotes);
  };

  const createNewNote = useCallback(() => {
    const idNote = getRandomNumberId();
    const newNote = new ItemNote(
      {
        id: idNote,
        type: NoteType.Need,
        createdDateTime: new Date().toISOString(),
      },
      ItemNoteStatus.NEW
    );
    setNotes([newNote, ...notes]);
    setTypeNotes(NoteType.Need);
    form.resetFields();
    form.setFieldsValue({ type: NoteType.Need });
    history.replace(String(idNote));
  }, [notes, form, history]);

  const onEditNote = useCallback(
    (id: number) => {
      history.replace(String(id));
    },
    [history]
  );

  const onDeleteNote = (id: number) => {
    const noteToDelete = notes.find((item) => item.id === id);
    if (!noteToDelete) return;

    // If we delete current note, select first one in the list.
    // or create new if none
    if (id === Number(selectedNoteId)) {
      if (notes.length > 0) {
        notes.forEach((note) => {
          if (id !== note.id) {
            history.replace(String(note.id));
          }
        });
      } else {
        createNewNote();
      }
    }

    if (noteToDelete?.status === ItemNoteStatus.NEW) {
      setNotes(notes.filter((note) => note.id !== id));
    } else {
      setNotes(
        notes.map((note) => {
          if (note.id === id) {
            return Object.assign(note, {
              ...note,
              status: ItemNoteStatus.DELETED,
            });
          }
          return note;
        })
      );
    }
  };

  const onUndoDeleteNote = (id: number) => {
    const newNotes = notes.map((note) => {
      if (note.id === id) {
        return Object.assign(note, {
          ...note,
          status: ItemNoteStatus.EDITED,
        });
      }
      return note;
    });
    setNotes(newNotes);
  };

  const onSaveNotes = async () => {
    if (!Number(memberId)) return;
    const newNotes = notes.filter((note) => note.status === ItemNoteStatus.NEW);

    const notesToUpdatePromises = notes
      .filter((note) => note.status === ItemNoteStatus.EDITED)
      .map((note) => updateNoteInput(note));
    const notesToUpdate = await Promise.all(notesToUpdatePromises);

    const notesToDeleteIds = notes
      .filter((note) => note.status === ItemNoteStatus.DELETED)
      .map((note) => note.id);

    try {
      if (
        notesToUpdate.length === 0
        && newNotes.length === 0
        && notesToDeleteIds.length === 0
      ) {
        setNotification({
          type: 'info',
          text: NOTE_NO_CHANGES_MESSAGE,
        });
        return;
      }
      if (notesToUpdate.length > 0) {
        await NotesOptions.updateNotes.exec({ notesToUpdate });
      }
      if (newNotes.length > 0) {
        if (!memberQuery.data?.user) return;
        const user = memberQuery.data?.user;

        const settledCreatedEvents = await newCalendarEvent({
          user,
          notes,
        });

        const allSuccessful = settledCreatedEvents.every(
          (result) => result.status === 'fulfilled'
        );

        if (!allSuccessful) {
          // clean up
          await Promise.all(
            settledCreatedEvents.map(async (result) => {
              if (result.status === 'fulfilled') {
                const eventId = result.value.event.id;
                return deleteCalendarEvent(eventId);
              }

              return Promise.resolve();
            })
          );

          const settledCreatedEvent = settledCreatedEvents.find(
            ({ status }) => status === 'rejected'
          );

          if (settledCreatedEvent?.status === 'rejected') {
            throw settledCreatedEvent.reason;
          }

          throw new Error('Unexpected Error');
        }

        // We already know that all events are fulfilled at this stage
        const createdEvents: CreatedEvent[] = (
          settledCreatedEvents as PromiseFulfilledResult<CreatedEvent>[]
        ).map((result) => result.value);

        const notesToCreatePromises = notes.map((note) => {
          const { event }
            = createdEvents.find(
              (createdEvent) => createdEvent.note.id === note.id
            ) || {};
          if (event) {
            return createNoteInput(note, event.id, ArrangedCallType.Google);
          }

          return createNoteInput(note);
        });
        const notesToCreate = await Promise.all(notesToCreatePromises);

        await NotesOptions.createNotes.exec({
          memberId: Number(memberId),
          notesToCreate,
        });
      }

      if (notesToDeleteIds.length > 0) {
        await NotesOptions.deleteNotes.exec(notesToDeleteIds);
      }
      history.goBack();
    } catch (error) {
      setNotification({
        type: 'error',
        text: DEFAULT_NOTE_SAVE_ERROR_MESSAGE,
      });
      setTimeout(() => setNotification(null), NOTIFICATION_HIDE_TIMEOUT);
    }
  };

  useEffect(() => {
    if (!memberQuery.isSuccess) return;
    if (notesLoading) return;
    if (!Number(selectedNoteId)) {
      history.replace(String(getRandomNumberId()));
    } else {
      const selectedNote = notes.find(
        (item) => item.id === Number(selectedNoteId)
      );
      if (selectedNote) {
        form.setFieldsValue(selectedNote);
        setTypeNotes(selectedNote.type);
      } else {
        createNewNote();
      }
    }
  }, [
    createNewNote,
    form,
    history,
    pageMode,
    memberQuery.isSuccess,
    notes,
    notesLoading,
    selectedNoteId,
  ]);

  useEffect(() => {
    // We are not using useQueryHook here as we don't need any fetching or caching.
    NotesOptions.getMemberNotes.exec(+memberId).then((notesData) => {
      if (!notesData) return;
      const fetchedNotesItems = notesData.edges.map((edge) => itemNoteFromNoteData(edge.node));
      setNotes(fetchedNotesItems);
      setNotesLoading(false);
    });
  }, [memberId]);

  // Remove note-drafts from localStorage;
  useEffect(() => {
    const storageNotes = JSON.parse(localStorage.getItem('notes') as string);
    if (storageNotes?.historyKey !== history?.location?.key) {
      localStorage.removeItem('notes');
    }
  }, [history.location.key]);

  // Save note-drafts to localStorage
  useEffect(() => {
    if (notes.length !== 0) {
      localStorage.setItem(
        'notes',
        JSON.stringify({
          items: notes,
          historyKey: history.location?.key,
        })
      );
    }
    const invalidNotes = notes?.filter((item) => !item.isValid());
    setDisabled(invalidNotes.length > 0);
  }, [history.location?.key, notes]);

  return (
    <Card
      className="notes-card"
      float
    >
      {notification ? (
        <NotesLayoutNotification
          onClose={() => setNotification(null)}
          notification={notification}
        />
      ) : null}
      <div className="notes-card__header">
        <div className="notes-card__header__breadcrumbs">
          <div className="notes-card__header__breadcrumbs__item notes-card__header__breadcrumbs__item_active">
            <RouterLink to="/members">
              <Link>Members</Link>
            </RouterLink>
          </div>
          <div className="notes-card__header__breadcrumbs__divider">/</div>
          <div className="notes-card__header__breadcrumbs__item_active">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="notes-card__header__breadcrumbs__item__back"
            >
              {getUserFullName(memberQuery.data?.user)}
            </button>
          </div>
          <div className="notes-card__header__breadcrumbs__divider">/</div>
          <div className="notes-card__header__breadcrumbs__item">{pageMode === 'edit' ? 'Edit note' : 'New notes'}</div>
        </div>
      </div>
      <div className="notes-card__main">
        <div className="notes-card__aside">
          <div className="notes-card__person">
            <CardMember
              avatar={memberQuery.data?.user?.avatar}
              lastName={memberQuery.data?.user?.lastName}
              firstName={memberQuery.data?.user?.firstName}
            />
            <button
              onClick={() => createNewNote()}
              disabled={disabled}
              type="button"
              className="notes-card__person__newnote"
            >
              + new note
            </button>
            <ul className="notes-card__person__block">
              {computedNotes.map((item) => (
                <NotesAsideItem
                  onEdit={onEditNote}
                  onDelete={onDeleteNote}
                  onUndoDelete={onUndoDeleteNote}
                  isSoftDeleted={item.status === ItemNoteStatus.DELETED}
                  disabledEdit={
                    disabled || item.status === ItemNoteStatus.DELETED
                  }
                  idWorkNote={Number(selectedNoteId)}
                  id={item.id}
                  title={item.type}
                  subtitle={getNeedTypeNameById(item.subTypeId)}
                  specifics={item.specifics}
                  key={item.id}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="notes-card__form">
          <div className="notes-card__form__buttongroup">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="notes-card__form__buttongroup__cancel"
            >
              <LeftOutlined />
              Cancel
            </button>
            <button
              type="submit"
              onClick={onSaveNotes}
              disabled={disabled}
              className="notes-card__form__buttongroup__save"
            >
              Save notes
            </button>
          </div>
          <Form
            onValuesChange={onValuesChangeForm}
            form={form}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            className="notes-card__form__body"
          >
            <Form.Item
              initialValue={history.location.state}
              rules={[fieldRules]}
              label="Type:"
              name="type"
            >
              <Radio.Group
                onChange={onChangeTypeNotes}
                className="radio__group__button"
              >
                <Radio.Button value={NoteType.Need}>Need</Radio.Button>
                <Radio.Button value={NoteType.Activity}>Activity</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {typeNotes === NoteType.Need ? (
              <>
                <Form.Item
                  rules={[fieldRules]}
                  label="Need type:"
                  name="subTypeId"
                >
                  <Select
                    {...dropdownAlign}
                    defaultActiveFirstOption={false}
                    placeholder="Please select"
                    className="notes-card__form__select"
                    dropdownClassName="notes-card__form__dropdown"
                  >
                    {optionsNeed?.map((item) => (
                      <Option
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  rules={[fieldRules]}
                  label="Type specifics:"
                  name="specifics"
                >
                  <TextArea rows={11} />
                </Form.Item>

                <Form.Item
                  initialValue={NoteStatus.Open}
                  label="Need status:"
                  name="needStatus"
                >
                  <Radio.Group className="radio__group__button">
                    <Radio.Button value={NoteStatus.Open}>Open</Radio.Button>
                    <Radio.Button
                      disabled
                      value={NoteStatus.Closed}
                    >
                      Closed
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {/* eslint-disable indent */}
                {currentNote?.attachments
                && currentNote?.attachments.length !== 0 ? (
                  <Form.Item
                    label="Documents"
                    name="attachments"
                    valuePropName="fileList"
                    getValueFromEvent={uploadFile}
                  >
                    <Upload
                      name="attachments"
                      action=""
                        // Return false to prevent immidiate upload.
                      beforeUpload={() => false}
                      listType="picture"
                    />
                  </Form.Item>
                  ) : null}
                {/* eslint-enable indent */}
                <Form.Item
                  label="Upload document:"
                  name="uploadedFiles"
                  valuePropName="fileList"
                  getValueFromEvent={uploadFile}
                >
                  <Upload
                    name="uploadedFiles"
                    accept={acceptexExtensions}
                    action=""
                    // Return false to prevent immidiate upload.
                    beforeUpload={() => false}
                    listType="picture"
                  >
                    <Button
                      icon={<UploadOutlined />}
                      className="notes-card__upload-button"
                    >
                      upload
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  label="URL:"
                  name="url"
                >
                  <Input className="EditMembers__input" />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  rules={[fieldRules]}
                  label="Activity type:"
                  name="subTypeId"
                >
                  <Select
                    {...dropdownAlign}
                    defaultActiveFirstOption={false}
                    placeholder="Please select"
                    className="notes-card__form__select"
                    dropdownClassName="notes-card__form__dropdown"
                  >
                    {optionsNeed?.map((item) => (
                      <Option
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  rules={[fieldRules]}
                  label="Type specifics:"
                  name="specifics"
                >
                  <TextArea rows={11} />
                </Form.Item>
              </>
            )}

            <ArrangeCall />
          </Form>
        </div>
      </div>
    </Card>
  );
};

export default Notes;
