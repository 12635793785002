import React from 'react';
import './style.css';
import {
  Modal,
  Button,
  ModalProps,
} from 'antd';
import {
  Text,
  Title,
} from '../../../../../../components/typography/typography';
import InfoAsideLayout from '../../../../../../components/InfoAsideLayout/InfoAsideLayout';
import ViewOpportunityModalItem from './components/ViewOpportunityModalItem/ViewOpportunityModalItem';
import DetailInfo from '../../../../../../common/interfaces/DetailInfo';
import Opportunity from '../../../../../../common/interfaces/Opportunity';

interface ViewOpportunityModalProps extends ModalProps {
  opportunity: Opportunity;
  details: DetailInfo[];
}

const ViewOpportunityModal = ({ opportunity, details, onOk, ...rest }: ViewOpportunityModalProps): JSX.Element => {
  const asideTagsList = {
    title: 'Tags',
    tags: opportunity?.tags ?? [],
  };
  return (
    <Modal
      {...rest}
      onOk={onOk}
      width={950}
      footer={null}
      closable={false}
      className="base-viewOpportunity"
    >
      <div className="viewOpportunity_modal">
        <div className="viewOpportunity-card__aside">
          <InfoAsideLayout
            details={details}
            tagLists={[asideTagsList]}
            avatarPalceholderType="opportunity"
            personTitle={opportunity?.title}
            avatar={opportunity?.companyLogo}
          />
        </div>
        <div className="viewOpportunity_modal__main">
          <div className="viewOpportunity__main-item_text">
            <div className="viewOpportunity__main-item_title">
              <Title modifiers={['big']}>
                {opportunity.title}
                {', '}
              </Title>
              <span className="viewOpportunity__main-item__title_sub">{opportunity.companyName}</span>
            </div>
            <Text>{opportunity?.description}</Text>
          </div>
          <ViewOpportunityModalItem opportunity={opportunity} />
        </div>
      </div>
      <div className="viewOpportunity_modal__footer">
        <Button
          onClick={onOk}
          type="primary"
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ViewOpportunityModal;
