import React, {
  useEffect,
  useState,
} from 'react';
import './style.css';
import {
  DatePicker,
  Button,
  Upload,
  Radio,
  Input,
  Card,
  Form,
} from 'antd';
import moment from 'moment/moment';
import { UploadOutlined } from '@ant-design/icons';
import {
  Role,
  User,
} from '../../../../../../graphql-generate/graphql-types';
import getAllUserRoles from '../../../../../../common/utils/getAllUserRoles';
import { FormValues } from '../../interfaces/interface';
import uploadFile from '../../../../../../common/utils/uploadFile';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const fieldRules = {
  required: true,
  message: 'This field is required',
};

const acceptexExtensions = ['jpeg', 'png', 'jpg']
  .map((item) => `.${item}`)
  .join(',');

interface AdminEditProfileFormProps {
  onSave: (formValues: FormValues | null) => void;
  onCancel: () => void;
  user?: User | null;
}

const AdminEditProfileForm = ({ onSave, onCancel, user }: AdminEditProfileFormProps): JSX.Element => {
  const [profileForm] = Form.useForm<FormValues>();
  const [formValues, setFormValues] = useState<FormValues | null>(null);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  useEffect(() => {
    const emptyValue = Object.values(profileForm.getFieldsValue()).find((item) => item === '' || item === null);
    if (emptyValue === '' || emptyValue === null) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [profileForm, formValues, user]);

  return (
    <Card
      className="admin-form"
    >
      <Form
        {...layout}
        onValuesChange={(_, values) => setFormValues(values)}
        onFinish={() => onSave(formValues)}
        form={profileForm}
        name="adminEditProfileForm"
        className="admin-form__form"
      >
        <div className="admin-form__header">
          <span className="admin-form__header__title">Account details </span>
          <div className="admin-form__header__buttons">
            <Button
              onClick={onCancel}
              className="admin-form__header__button__cancel"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={disabledButton}
            >
              Save
            </Button>
          </div>
        </div>
        <Form.Item
          label="Upload photo"
          name="uploadPhoto"
          valuePropName="fileList"
          getValueFromEvent={uploadFile}
        >
          <Upload
            name="uploadPhoto"
            accept={acceptexExtensions}
            action=""
            beforeUpload={() => false}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[fieldRules]}
          initialValue={user?.firstName}
        >
          <Input className="admin-form__input" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[fieldRules]}
          initialValue={user?.lastName}
        >
          <Input className="admin-form__input" />
        </Form.Item>
        <Form.Item
          label="Job role"
          name="jobRole"
          rules={[fieldRules]}
          initialValue={user && getAllUserRoles(user)}
        >
          <Radio.Group
            className="admin-form__radio"
          >
            {[...Object.values(Role), 'Admin + Pathfinder'].map((item) => {
              if (item === Role.Member) return null;
              return (
                <Radio.Button
                  value={item}
                  key={item}
                >
                  {item}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Role type"
          name="roleType"
          rules={[fieldRules]}
          initialValue="Full time"
        >
          <Radio.Group
            className="admin-form__radio"
          >
            <Radio.Button value="Part time">Part time</Radio.Button>
            <Radio.Button value="Full time">Full time</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Date of birth"
          name="dateBirth"
          rules={[fieldRules]}
          initialValue={user?.dateOfBirth && moment(user.dateOfBirth)}
        >
          <DatePicker className="admin-form__input__date" />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AdminEditProfileForm;
