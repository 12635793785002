import React from 'react';

// images
import { ReactComponent as UserPlaceholderIcon } from '../../assets/icons/user-placeholder.svg';

// styles
import './style.css';

// types
interface CardMemberProps {
  avatar?: string | null;
  lastName?: string | null;
  firstName?: string | null;
}

const CardMember = ({
  avatar,
  lastName,
  firstName,
}: CardMemberProps):JSX.Element => (
  <div className="CardMember-card__person__image">
    {avatar ? (
      <img
        src={avatar}
        alt={`${firstName} ${lastName}'s avatar`}
        className="CardMember-list__card__avatar"
      />
    ) : (
      <UserPlaceholderIcon className="CardMember-list__card__avatar" />
    )}

    <div className="CardMember-card__person__title">
      {firstName}
      {' '}
      {lastName}
    </div>
  </div>
);

export default CardMember;
