import './style.css';
import React from 'react';

const Dashboard = (): JSX.Element => (
  <iframe
    width="100%"
    height="100%"
    frameBorder="0"
    title="Dashboard"
    src="https://datastudio.google.com/embed/u/0/reporting/574b01b5-e190-4eb4-814e-72a6b63c420d/page/R8lOC"
  />

);

export default Dashboard;
