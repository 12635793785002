import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import moment from 'moment';
import React from 'react';
import NoteItem, { ChannelType } from '../../interfaces/NoteItem';

export const ArrangeCall : React.FC = () => {
  const requiredRule = {
    required: true,
    message: 'This field is required',
  };

  return (
    <Form.Item<NoteItem>
      noStyle
      shouldUpdate={(newNote, oldNote) => newNote.arrangeCall !== oldNote.arrangeCall || newNote.channel === oldNote.channel}
    >
      {({ getFieldValue }) => {
        const isGoogleMeet = getFieldValue('channel') === ChannelType.GoogleMeet;
        const isEnabled: boolean = getFieldValue('arrangeCall');
        return (
          <>
            <Row>
              <Col
                offset={5}
                span={7}
              >
                <Form.Item
                  rules={[requiredRule]}
                  name="arrangeCall"
                  valuePropName="checked"
                  noStyle
                >
                  <Switch />
                </Form.Item>
                {' '}
                <span className="notes-card_switch_text">
                  Arrange follow up call
                </span>
              </Col>

              { isEnabled && (
                <Col span={9}>

                  <Form.Item
                    rules={[requiredRule]}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    name="channel"
                    label="Select Channel"
                    initialValue={ChannelType.GoogleMeet}
                  >
                    <Select
                      placeholder="Select channel"
                    >
                      <Select.Option value={ChannelType.GoogleMeet}>Google Meet</Select.Option>
                      <Select.Option value={ChannelType.Other}>Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) }
            </Row>
            { isEnabled && (
              <Row>
                <Col
                  span={12}
                >
                  <Form.Item
                    labelCol={{ span: 10 }}
                    name="meetingDate"
                    label="Date"
                    rules={[requiredRule]}
                  >
                    <DatePicker
                      className="notes-card__form__date-picker"
                      disabledDate={(date) => date.isBefore(moment.now())}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    name="meetingTime"
                    label="Time"
                    rules={[requiredRule]}
                  >
                    <TimePicker
                      className="notes-card__form__time-picker"
                      showSecond={false}
                      format="H:mm"
                      showNow
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {isEnabled && !isGoogleMeet && (
              <Form.Item<NoteItem>
                label="Meeting link:"
                name="meetingUrl"
              >
                <Input placeholder="Enter the meeting URL here" />
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.Item>
  );
};
