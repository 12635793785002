import { loader } from 'graphql.macro';
import {
  QueryMembersConnection,
  QueryMembersArgs,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/queries/getMembers.gql');

interface IMemberRequestData {
  members: QueryMembersConnection
}

export default async function getMembersConnection(filters: QueryMembersArgs = {}): Promise<QueryMembersConnection> {
  const membersRequest = await graphQLClient.request<IMemberRequestData, QueryMembersArgs>(query, filters);
  return membersRequest.members;
}
