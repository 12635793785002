import { UploadFile } from 'antd/lib/upload/interface';
import { nanoid } from 'nanoid';
import createPresignedPost from '../../api/common/graphql/requests/createPresignedPost';
import {
  ArrangedCallType,
  AttachmentInput,
  AttachmentScope,
  CreatePresignedPostInput,
  NoteCreateInput,
  NoteStatus,
  NoteUpdateInput,
} from '../../graphql-generate/graphql-types';
import NoteItem from '../../views/Notes/interfaces/NoteItem';

export const uploadAttachment = async (file: UploadFile, scope: AttachmentScope): Promise<AttachmentInput> => {
  const presignedInputData: CreatePresignedPostInput = {
    fileName: file.name,
    scope,
  };
  const presignedPost = await createPresignedPost(presignedInputData);
  if (!presignedPost) {
    throw new Error('Error while trying to get presigned post.');
  }
  const formData = new FormData();

  presignedPost.fields.forEach((kvPair) => {
    formData.append(kvPair.key, kvPair.value);
  });

  formData.append('file', file.originFileObj);
  const uploadResponse = await fetch(presignedPost.uploadUrl, {
    method: 'POST',
    body: formData,
  });

  if (uploadResponse.ok) {
    return {
      name: file.name,
      link: presignedPost.fileUrl,
    };
  }
  throw new Error('Error while trying to upload file to S3');
};

export async function updateNoteInput(note: NoteItem): Promise<NoteUpdateInput> {
  const createInput = await createNoteInput(note);
  return {
    ...createInput, id: note.id,
  };
}

export async function createNoteInput(note: NoteItem, arrangedCallId?: string, arrangedCallType?: ArrangedCallType): Promise<NoteCreateInput> {
  const attachmentInputs: AttachmentInput[] = [];
  if (note.uploadedFiles) {
    const uploadRequests: Promise<AttachmentInput>[] = [];
    note.uploadedFiles.forEach((file) => {
      uploadRequests.push(uploadAttachment(file, AttachmentScope.Attachment));
    });
    try {
      const uploadedFilesInputs = await Promise.all(uploadRequests);
      attachmentInputs.push(...uploadedFilesInputs);
    } catch (error) {
      // TODO: Handle upload error;
    }
  }
  if (note.attachments) {
    note.attachments.forEach((attachment) => {
      const attachmentInput = {
        id: Number(attachment.uid),
        link: attachment.url ?? '',
        name: attachment.name ?? nanoid(), // Generate name if not provided
      };
      attachmentInputs.push(attachmentInput);
    });
  }

  return {
    arrangeCall: Boolean(note.arrangeCall),
    arrangedCallType,
    arrangedCallId,
    url: note.url,
    specifics: note.specifics ?? '',
    type: note.type,
    attachments: attachmentInputs,
    status: note.needStatus ?? NoteStatus.Open,
    needTypeId: Number(note.subTypeId) ?? undefined,
  };
}
