import { QueryMembersArgs } from '../../graphql-generate/graphql-types';

// API allows to use only:
// first with after
// last with before.
// So this functuion accepts pageSize instead of "first" and "last" parameters
// and then safely wraps it to build valid arguments for API
const getSafeMembersConnectionAgrs = ({ before, after, filterBy }: QueryMembersArgs, pageSize: number): QueryMembersArgs => ({
  before,
  after,
  first: !before ? pageSize : undefined,
  last: before ? pageSize : undefined,
  filterBy,
});

export default getSafeMembersConnectionAgrs;
