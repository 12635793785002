import React from 'react';
import './style.css';
import {
  Modal,
  Button,
  ModalProps,
} from 'antd';
import { useQuery } from 'react-query';
import { Members } from '../../../../api/Members';
import ProfileMainInfo from '../ProfileMainTab/components/ProfileMainInfo/ProfileMainInfo';
import ProfileAside from '../ProfileAside/ProfileAside';

interface ViewMemberModalProps extends ModalProps {
  userId: string | number;
}

const ViewMemberModal = ({ userId, onOk, ...rest }: ViewMemberModalProps): JSX.Element => {
  const memberQuery = useQuery([Members.single.key, userId], () => Members.single.exec(+userId));

  return (
    <Modal
      {...rest}
      onOk={onOk}
      width={950}
      footer={null}
      className="base-ViewMemberModal"
    >
      <div className="viewMemberModal_modal">
        <div className="viewMemberModal-card__aside">
          <ProfileAside member={memberQuery.data ?? null} />
        </div>
        <div className="viewMemberModal__main">
          <ProfileMainInfo member={memberQuery.data ?? null} />
        </div>
      </div>
      <div className="viewMemberModal_modal__footer">
        <Button
          onClick={onOk}
          type="primary"
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ViewMemberModal;
