import React from 'react';
import {
  Radio,
  Form,
  Input,
  DatePicker,
  Select,
} from 'antd';
import SelectOption from '../../../../common/interfaces/SelectOption';
import './style.css';

const { Option } = Select;
const { TextArea } = Input;

interface MembershipProps {
  FLAGS: SelectOption[];
  membershipType: SelectOption[];
  membershipLevel: SelectOption[];
  freeTrialNextSteps: SelectOption[];
  PageType: 'add' | 'edit';
  isEditMode: 'default' | 'edit';
  titleLayout: {
    wrapperCol: {
      offset: number,
    }
  };
  value?: {
    date: string;
    FLAGS: string;
    payingMember: string;
    membershipType: string;
    isAParticipant: string;
    howDidTheyJoin: string;
    membershipLevel: string;
    portalInviteStatus: string;
    freeTrialNextSteps: string;
    updatedMemberStatus: string;
    corporateIdentifier: string;
    virtualAssistantPilot: string;
  }
}

const Membership = ({
  value,
  FLAGS,
  PageType,
  isEditMode,
  titleLayout,
  membershipType,
  membershipLevel,
  freeTrialNextSteps,
}: MembershipProps): JSX.Element => (
  <>
    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Payment details</h2>
    </Form.Item>

    <Form.Item
      name="PayingMember"
      label="Paying Member:"
      initialValue={value?.payingMember}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.payingMember}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Yes">
            Yes
          </Radio.Button>
          <Radio.Button value="No">
            No
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="ISAParticipant"
      label="ISA Participant:"
      initialValue={value?.isAParticipant}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.isAParticipant}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Yes">
            Yes
          </Radio.Button>
          <Radio.Button value="No">
            No
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="FreeTrialNextSteps"
      label="Free trial next steps:"
      initialValue={value?.freeTrialNextSteps}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.freeTrialNextSteps}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {freeTrialNextSteps.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      {...titleLayout}
    >
      <h2 className="EditMembers-card__form__title">Membership details</h2>
    </Form.Item>

    <Form.Item
      name="MembershipType"
      label="Membership Type:"
      initialValue={value?.membershipType}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.membershipType}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {membershipType.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="MembershipLevel"
      label="Membership Level:"
      initialValue={value?.membershipLevel}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.membershipLevel}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {membershipLevel.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="FLAGS"
      label="FLAGS:"
      initialValue={value?.FLAGS}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.FLAGS}</span>
      ) : (
        <Select
          allowClear
          defaultActiveFirstOption={false}
          className="EditMembers-card__form__select"
        >
          {FLAGS.map((item) => (
            <Option
              key={item.id}
              value={item.name}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>

    <Form.Item
      name="UpdatedMemberStatus"
      label="Updated Member Status:"
      initialValue={value?.updatedMemberStatus}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.updatedMemberStatus}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Standard">
            Standard
          </Radio.Button>
          <Radio.Button value="Premium">
            Premium
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="Date"
      label="Date of Birth:"
      initialValue={value?.date}
      rules={[{ required: true }]}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.date}</span>
      ) : (
        <DatePicker className="Membership-card__datepicker" />
      )}
    </Form.Item>

    <Form.Item
      name="HowDidTheyJoin"
      label="How did they join?:"
      initialValue={value?.howDidTheyJoin}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.howDidTheyJoin}</span>
      ) : (
        <TextArea
          rows={3}
          className="Membership-card__textarea"
        />
      )}
    </Form.Item>

    <Form.Item
      name="PortalInviteStatus"
      label="Portal invite status:"
      initialValue={value?.portalInviteStatus}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.portalInviteStatus}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Logged in">
            Logged in
          </Radio.Button>
          <Radio.Button value="Not yet">
            Not yet
          </Radio.Button>
          <Radio.Button value="Yes">
            Yes
          </Radio.Button>
          <Radio.Button value="No">
            No
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="VirtualAssistantPilot"
      label="Virtual Assistant Pilot:"
      initialValue={value?.virtualAssistantPilot}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.virtualAssistantPilot}</span>
      ) : (
        <Radio.Group className="radio__group__button">
          <Radio.Button value="Interested">
            Interested
          </Radio.Button>
          <Radio.Button value="Invited">
            Invited
          </Radio.Button>
        </Radio.Group>
      )}
    </Form.Item>

    <Form.Item
      name="CorporateIdentifier"
      label="Corporate identifier:"
      initialValue={value?.corporateIdentifier}
    >
      {(isEditMode === 'default' && PageType === 'edit') ? (
        <span>{value?.corporateIdentifier}</span>
      ) : (
        <Input className="EditMembers__input" />
      )}
    </Form.Item>
  </>
);

export default Membership;
