import './App.css';
import React, { useEffect } from 'react';
import {
  Hub,
  HubCapsule,
} from '@aws-amplify/core';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Auth,
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth';
import {
  authActions,
  authorizeUser,
} from './store/auth';
import BasicLayout from './components/BasicLayout/BasicLayout';
import Home from './views/Home/Home';
import MembersList from './views/MembersList/MembersList';
import PrivateRoute from './components/helpers/PrivateRoute/PrivateRoute';
import Login from './views/Login/Login';
import Profile from './views/Profile/Profile';
import Notes from './views/Notes/Notes';
import Admin from './views/Admin/Admin';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ChangePassword from './views/ChangePassword/ChangePassword';
import Dashboard from './views/Dashboard/Dashboard';
import { Entities } from './api/Entities';
import { entitiesActions } from './store/entities';
import { useAppDispatch } from './store/store';
import LoadingWrapper from './components/helpers/LoadingWrapper/LoadingWrapper';
import AdminTeamMemberTabs from './views/Admin/components/AdminTeamMemberTabs/AdminTeamMember';
import Planner from './views/Planner/Planner';
import { scriptInit } from './store/google';
import EditMembers from './views/EditMembers/EditMembers';

export const queryClient = new QueryClient();

function App(): JSX.Element {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const enableBetaFeatures = process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable';

  useEffect(() => {
    // Handle cognito auth response.
    // TODO: Change Cognito callback url to handle this error with another route.
    const urlParams = new URLSearchParams(search);
    const errorMessage = urlParams.get('error_description');
    if (errorMessage) {
      // A hack to handle specific error which triggers if user tries to sign in with Google using already existing identity
      // So we have to run the auth flow again
      if (errorMessage.startsWith('Already found an entry for username')) {
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      }
      if (errorMessage.startsWith('PreSignUp failed with error')) {
        const errorMessageWithoutCognitoPrefix = errorMessage.replace('PreSignUp failed with error', '').trim();
        dispatch(authActions.setFederatedError(errorMessageWithoutCognitoPrefix));
      }
    }
  }, [dispatch, search]);

  useEffect(() => {
    // Check and authorize user
    dispatch(authorizeUser());
  }, [dispatch, history]);

  useEffect(() => {
    // initialize google script
    dispatch(scriptInit());
  }, [dispatch]);

  useEffect(() => {
    // Fetch entities
    queryClient.fetchQuery(Entities.filters.key, () => Entities.filters.exec())
      .then((data) => dispatch(entitiesActions.setEntities(data)));
  }, [dispatch]);

  useEffect(() => {
    const hubListener = (capsule: HubCapsule) => {
      const { event } = capsule.payload;
      if (event === 'signOut') {
        dispatch(authActions.removeUser());
        history.push('/login');
      }
    };
    Hub.listen('auth', hubListener);
    return () => Hub.remove('auth', hubListener);
  }, [dispatch, history]);

  // Scroll reset
  history.listen((location, action) => {
    if (action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <LoadingWrapper>
        <div className="App">
          <Switch>
            <Route
              exact
              path="/login"
              component={Login}
            />
            <Route
              exact
              path="/login/forgot-password"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/login/change-password"
              component={ChangePassword}
            />
            <PrivateRoute
              path="/"
              authenticationPath="/login"
            >
              <BasicLayout>
                <Switch>
                  <Route
                    path="/home"
                    component={Home}
                  />
                  <Route
                    path="/members"
                    exact
                    component={MembersList}
                  />
                  <Route
                    path="/members/profile/:memberId/edit"
                    exact
                  >
                    <EditMembers PageType="edit" />
                  </Route>
                  <Route
                    path="/members/profile/:memberId/:tagId?"
                    component={Profile}
                  />
                  <Route
                    path="/members/:memberId/notes/:pageMode/:selectedNoteId?"
                    component={Notes}
                  />
                  <Route path="/dashboard">
                    { enableBetaFeatures ? <Dashboard /> : <Redirect to="/home" />}
                  </Route>
                  <Route path="/planner">
                    { enableBetaFeatures ? <Planner /> : <Redirect to="/home" />}
                  </Route>

                  <Route
                    path="/admin/:tagId"
                    exact
                    component={Admin}
                  />
                  <Route
                    path="/account"
                    exact
                    component={Admin}
                  />
                  <Route
                    path="/admin/team/:memberId/:tagTeamId"
                    component={AdminTeamMemberTabs}
                  />
                  <Redirect to="/home" />
                </Switch>
              </BasicLayout>
            </PrivateRoute>

            {/* 404 */}
            <Redirect to="/" />
          </Switch>
        </div>
      </LoadingWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
