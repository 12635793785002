import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import './style.css';
import {
  Select,
  Checkbox,
  Button,
  message,
} from 'antd';
import { useQuery } from 'react-query';
import {
  CheckboxChangeEvent,
  CheckboxOptionType,
} from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useAppSelector } from '../../../../store/store';
import { getMemberFiltersEntities } from '../../../../store/entities';
import getUserFullName from '../../../../common/utils/getUserFullName';
import AssignedMembers from '../../../../common/interfaces/AssignedMembers';
import formatLocationFromMemberDetails from '../../../../common/utils/formatLocationFromMemberDetails';
import AssignedModal from './components/AssignedModal/AssignedModal';
import MembersListCard from '../../../../components/MembersListCard/MembersListCard';
import MembersListShimmer from '../../../../components/MembersListShimmer/MembersListShimmer';
import ViewMemberModal from '../../../Profile/components/ViewMemberModal/ViewMemberModal';
import {
  Member,
  MemberFilter,
} from '../../../../graphql-generate/graphql-types';
import { getNodesFromConnection } from '../../../../common/utils/getNodesFromConnection';
import DetailsSelect from '../../common/interfaces/DetailsSelect';
import getSafeMembersConnectionAgrs from '../../../../common/utils/getSafeMembersConnectionArgs';
import OneStepPagination, { ChangePageData } from '../../../../components/OneStepPagination/OneStepPagination';

import { Members } from '../../../../api/Members';
import ListNoResults from '../../../../components/ListNoResults/ListNoResults';

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const durationPopupMessage = 4;

interface AdminAssignedTabProps {
  memberName: string;
  assignedMembersPage?: boolean;
}

const AdminAssignedTab = ({ memberName, assignedMembersPage }: AdminAssignedTabProps): JSX.Element => {
  const PAGE_SIZE = 5;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [assignedMembers, setAssignedMembers] = useState<AssignedMembers[]>([]);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [afterCursor, setAfterCursor] = useState<string | null>(null);
  const [beforeCursor, setBeforeCursor] = useState<string | null>(null);
  const [sectorFilterId, setSectorFilterId] = useState<number | null>(null);
  const [countryFilterId, setCountryFilterId] = useState<number | null>(null);
  const [cityFilterId, setCityFilterId] = useState<number | null>(null);
  const [skillFilterId, setSkillFilterId] = useState<number | null>(null);

  const [showAssignedMember, setShowAssignedMember] = useState<boolean>(false);
  const [viewIdMember, setViewIdMember] = useState<string | null>(null);
  const [showViewMember, setShowViewMember] = useState<boolean>(false);

  const entities = useAppSelector((state) => getMemberFiltersEntities(state.entities));

  const memberFilters = useMemo((): MemberFilter => ({
    countryIds: countryFilterId ? [countryFilterId] : [],
    cityIds: cityFilterId ? [cityFilterId] : [],
    sectorIds: sectorFilterId ? [sectorFilterId] : [],
    skillIds: skillFilterId ? [skillFilterId] : [],
  }), [countryFilterId, cityFilterId, sectorFilterId, skillFilterId]);

  const membersConnectionQuery = useQuery(
    [Members.filterByParams.key, memberFilters, beforeCursor, afterCursor],
    () => Members.filterByParams.exec(getSafeMembersConnectionAgrs({
      before: beforeCursor,
      after: afterCursor,
      filterBy: memberFilters,
    }, PAGE_SIZE))
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [memberFilters]);

  const computedMemberList = useMemo(() => {
    if (!membersConnectionQuery.data) return [];
    return getNodesFromConnection(membersConnectionQuery.data);
  }, [membersConnectionQuery.data]);

  const changePaginationPage = (changePageData: ChangePageData) => {
    if (!membersConnectionQuery.data) {
      return;
    }
    setAfterCursor(changePageData.after ?? null);
    setBeforeCursor(changePageData.before ?? null);
    setCurrentPage(changePageData.pageNumber);
    window.scrollTo(0, 0);
    setIndeterminate(false);
    setAssignedMembers([]);
    setCheckedList([]);
    setCheckAll(false);
  };

  const modalSuccess = () => {
    window.scrollTo(0, 0);
    setShowAssignedMember(false);
    message.success(`You have successfully ${assignedMembersPage ? 'reassigned' : 'assigned '} members${(assignedMembersPage && memberName) ? '' : (` to ${memberName}`)}.`, durationPopupMessage);
  };

  const onShowViewMember = (member: Member) => {
    setShowViewMember(true);
    setViewIdMember(String(member.id));
  };

  const onAllChangeCheckbox = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions.map((item) => (item.value)) : []);
    setCheckAll(e.target.checked);
    setIndeterminate(false);
    if (e.target.checked) {
      setAssignedMembers((computedMemberList || []).map((item) => {
        let location = '';
        if (item.user.city) {
          location = formatLocationFromMemberDetails(item.user.city.name, item.user.city.country?.name);
        }

        return ({
          id: item.id,
          name: getUserFullName(item.user),
          location,
        });
      }));
    } else {
      setAssignedMembers([]);
    }
  };

  const onAssignedMembersChange = (e: CheckboxChangeEvent, member: Member) => {
    let location = '';
    if (member.user.city) {
      location = formatLocationFromMemberDetails(member.user.city.name, member.user.city.country?.name);
    }

    const assignedMember = {
      id: member.id,
      name: getUserFullName(member.user),
      location,
    };
    if (e.target.checked) {
      setAssignedMembers((prev) => [...prev, assignedMember]);
    } else {
      setAssignedMembers((prev) => prev.filter((item) => item.id !== assignedMember.id));
    }
  };

  const onRowChangeCheckbox = (checkedValue: CheckboxValueType[]) => {
    setCheckedList(checkedValue);
    setIndeterminate(!!checkedValue.length && checkedValue.length < plainOptions.length);
    setCheckAll(checkedValue.length === plainOptions.length);
  };

  const plainOptions: CheckboxOptionType[] = computedMemberList.map((member) => ({
    label: (<MembersListCard
      onShowView={() => onShowViewMember(member)}
      key={member.id}
      member={member}
    />),
    onChange: (e: CheckboxChangeEvent) => onAssignedMembersChange(e, member),
    value: member.id,
  }));

  const detailsSelect = useMemo((): DetailsSelect<number>[] => [{
    id: 'Sector',
    title: 'Sector',
    optionItems: entities?.sectors ?? [],
    onChange: (id?: number) => setSectorFilterId(id ?? null),
  }, {
    id: 'Country',
    title: 'Country',
    optionItems: entities?.countries ?? [],
    onChange: (id?: number) => setCountryFilterId(id ?? null),
  }, {
    id: 'City',
    title: 'City',
    optionItems: entities?.cities ?? [],
    onChange: (id?: number) => setCityFilterId(id ?? null),
  }, {
    id: 'Skills',
    title: 'Skills',
    optionItems: entities?.skills ?? [],
    onChange: (id?: number) => setSkillFilterId(id ?? null),
  }, {
    id: 'Years of Experience',
    title: 'Years of Experience',
    optionItems: [],
  }], [entities]);

  return (
    <div className="Admin__main Admin-assigned__main">
      <div className="Admin-assigned__main__container">
        <div className="Admin-assigned__selects">
          {detailsSelect.map((item) => (
            <div
              key={item.id}
              className="Admin-assigned__selects-item"
            >
              <span className="Admin-assigned__selects__title">{item.title}</span>
              <Select
                allowClear
                onChange={item.onChange}
                defaultActiveFirstOption={false}
                placeholder="All"
                className="Admin-assigned__select"
                dropdownClassName="Admin-assigned__dropdown"
              >
                {item.optionItems.map((itemOption) => (
                  <Option
                    key={itemOption.id}
                    value={itemOption.id}
                    label={itemOption.name}
                  >
                    {itemOption.name}
                  </Option>
                ))}
              </Select>
            </div>
          ))}
        </div>
        <div className="Admin-assigned__members">
          <div className="Admin-assigned__members__header">
            <div className="Admin-assigned__members__header__title">
              {(membersConnectionQuery.status === 'success' && membersConnectionQuery?.data.totalCount) ? (
                <span>
                  {'showing '}
                  {membersConnectionQuery.data.totalCount}
                  {' results'}
                </span>
              ) : null}
            </div>
            <div className="Admin-assigned__members__header__checkbox">
              <span className="Admin-assigned__members__header__checkbox__text">Select all</span>
              <Checkbox
                onChange={(e: CheckboxChangeEvent) => onAllChangeCheckbox(e)}
                checked={checkAll}
                value={checkedList}
                indeterminate={indeterminate}
              />
            </div>
          </div>
          {membersConnectionQuery.status === 'loading'
            ? <MembersListShimmer />
            : (
              <CheckboxGroup
                onChange={onRowChangeCheckbox}
                value={checkedList}
                options={plainOptions}
              />
            )}
          {(membersConnectionQuery.status === 'success' && computedMemberList.length === 0) ? (
            <div className="Admin-assigned__members__no-results">
              <ListNoResults />
            </div>
          ) : null}
          <ViewMemberModal
            onOk={() => setShowViewMember(false)}
            onCancel={() => setShowViewMember(false)}
            userId={viewIdMember ?? '1'}
            visible={showViewMember}
          />
        </div>
      </div>
      <footer className="Admin-assigned__footer">
        <div>
          <OneStepPagination
            pageInfo={membersConnectionQuery.data?.pageInfo ?? null}
            className="Admin-assigned__list-pagination"
            total={membersConnectionQuery.data?.totalCount ?? 0}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onChangePage={changePaginationPage}
          />
        </div>
        <div className="Admin-assigned__footer_buttons">
          <Button
            onClick={() => setShowAssignedMember(true)}
            disabled={assignedMembers.length === 0}
            type="primary"
          >
            {(assignedMembersPage) ? ('Reassign selected') : ('Assign selected')}
          </Button>
        </div>
        <AssignedModal
          assignedMembersPage={assignedMembersPage}
          memberName={memberName}
          assignedMembers={assignedMembers}
          visible={showAssignedMember}
          onOk={modalSuccess}
          onCancel={() => setShowAssignedMember(false)}
        />
      </footer>
    </div>
  );
};

export default AdminAssignedTab;
