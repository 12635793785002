import React from 'react';
interface FactoryInterface {
  children?: React.ReactNode;
  modifiers?: Array<string>;
}

export default function typographyFactory<PropsType extends FactoryInterface>(
  { modifiers, children, ...otherProps }: PropsType,
  defaultClass: string
): JSX.Element {
  const modifiersList
    = modifiers?.map((modifier) => `${defaultClass}__${modifier}`) ?? [];
  const computedClassName = `${defaultClass} ${modifiersList.join(' ')}`;
  return (
    <span
      {...otherProps}
      className={computedClassName}
    >
      {children}
    </span>
  );
}
