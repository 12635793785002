import './style.css';
import typographyFactory from '../common/typographyFactory';

type TextModifiers = ['thin', 'bold', 'semiBold', 'italic', 'accent', 'secondary', 'gray', 'white', 'gold'];
interface TextProps {
  children?: React.ReactNode
  modifiers?: TextModifiers[number][]
}

const Text = (props: TextProps): JSX.Element => typographyFactory<TextProps>(props, 'default-text');

export default Text;
