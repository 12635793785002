import { loader } from 'graphql.macro';
import {
  CreateMemberNotesInput,
  CreateMemberNotesPayload,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/mutations/memberCreateNewNotes.gql');

interface ICreateMemberNotesInput{
  createInput: CreateMemberNotesInput;
}

export default async function createMemberNotes(input: CreateMemberNotesInput): Promise<CreateMemberNotesPayload> {
  const notesMutationRequest = await graphQLClient.request<CreateMemberNotesPayload, ICreateMemberNotesInput>(query, { createInput: input });
  return notesMutationRequest;
}
