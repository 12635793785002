import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  useHistory,
  useParams,
  Link as RouterLink,
} from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import {
  Form,
  Button,
  Steps,
  Menu,
} from 'antd';
import { useQuery } from 'react-query';
import { Link } from '../../components/typography/typography';
import Card from '../../components/Card/Card';
import Career from './components/Career/Career';
import Membership from './components/Membership/Membership';
import Affiliation from './components/Affiliation/Affiliation';
import CardMember from '../../components/CardMember/CardMember';
import Communication from './components/Communication/Communication';
import PersonalDetails from './components/PersonalDetails/PersonalDetails';
import { ReactComponent as LeftOutlined } from '../../assets/icons/left-outlined.svg';
import { useAppSelector } from '../../store/store';
import { options } from './dummyData/dummyDataOptions';
import getUserFullName from '../../common/utils/getUserFullName';
import { getMemberFiltersEntities } from '../../store/entities';
import './style.css';
import { Members } from '../../api/Members';

type TPageType = 'add' | 'edit';
type TIsEditModeType = 'default' | 'edit';

interface EditMembersProps {
  PageType: TPageType;
}

interface MemberParams {
  memberId: string;
}

const { Step } = Steps;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const titleLayout = { wrapperCol: { offset: 6 } };
const lastPage = 4;

const EditMembers = ({ PageType }: EditMembersProps):JSX.Element => {
  const history = useHistory();
  const { memberId } = useParams<MemberParams>();
  const [EditMemberForm] = Form.useForm();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const isEditMode = history.location.state as TIsEditModeType;
  const entities = useAppSelector((state) => getMemberFiltersEntities(state.entities));
  const memberQuery = useQuery([Members.single.key, memberId], () => Members.single.exec(+memberId));

  const nextStep = () => {
    setCurrentPage(currentPage + 1);
  };
  const onCurrentPage = (id: number) => {
    setCurrentPage(id - 1);
  };

  const steps = useMemo(() => {
    if (!memberQuery.data?.user) {
      return ([{
        id: 1,
        title: '',
        content: <></>,
      }]
      );
    }

    return ([{
      id: 1,
      title: 'Personal details',
      content:
  <PersonalDetails
    user={memberQuery.data.user}
    city={entities?.cities}
    сountry={entities?.countries}
    citizenship={options.citizenship}
    isEditMode={isEditMode}
    PageType={PageType}
    titleLayout={titleLayout}
  />,
    },
    {
      id: 2,
      title: 'Career',
      content:
  <Career
    grade={options.grade}
    addSkills={options.addSkills}
    industrySector={options.industrySector}
    quallification={options.quallification}
    academicInstitution={options.academicInstitution}
    isEditMode={isEditMode}
    PageType={PageType}
    titleLayout={titleLayout}
  />,
    },
    {
      id: 3,
      title: 'Affiliation',
      content:
  <Affiliation
    afiliationEntity={options.afiliationEntity}
    affiliationCategory={options.affiliationCategory}
    affiliationWithTheRoom={options.affiliationWithTheRoom}
    PageType={PageType}
    isEditMode={isEditMode}
    titleLayout={titleLayout}
  />,
    },
    {
      id: 4,
      title: 'Membership',
      content:
  <Membership
    FLAGS={options.FLAGS}
    membershipType={options.membershipType}
    membershipLevel={options.membershipLevel}
    freeTrialNextSteps={options.freeTrialNextSteps}
    PageType={PageType}
    isEditMode={isEditMode}
    titleLayout={titleLayout}
  />,
    },
    {
      id: 5,
      title: 'Communication',
      content:
  <Communication
    pathfinder={options.pathfinder}
    customerSegment={options.customerSegment}
    engagementLevel={options.engagementLevel}
    missionOpportunitiesForm={options.missionOpportunitiesForm}
    PageType={PageType}
    isEditMode={isEditMode}
    titleLayout={titleLayout}
  />,
    }]);
  }, [memberQuery, entities, isEditMode, PageType]);

  let nextButton: JSX.Element | null = null;
  if (currentPage < lastPage) {
    nextButton = (
      <Button
        onClick={nextStep}
        type="primary"
      >
        Next
      </Button>
    );
  } else {
    nextButton = (
      <>
        <Button
          type="primary"
          className="EditMembers-card__form__button-submit"
        >
          Submit
        </Button>
        <Button>Submit & Edit another member</Button>
      </>
    );
  }

  useEffect(() => {
    if (isEditMode === 'edit') {
      EditMemberForm.validateFields();
    }
  }, [EditMemberForm, isEditMode]);

  return (
    <Card
      className="EditMembers-card"
      float
    >
      <div className="EditMembers-card__header">
        <div className="EditMembers-card__header__breadcrumbs">
          <div className="EditMembers-card__header__breadcrumbs__item EditMembers-card__header__breadcrumbs__item_active">
            <RouterLink to="/members">
              <Link>Members</Link>
            </RouterLink>
          </div>

          <div className="EditMembers-card__header__breadcrumbs__divider">/</div>
          <div className="EditMembers-card__header__breadcrumbs__item_active">
            <RouterLink to={`/members/profile/${memberId}`}>
              <Link>{getUserFullName(memberQuery.data?.user)}</Link>
            </RouterLink>
          </div>

          <div className="EditMembers-card__header__breadcrumbs__divider">/</div>
          <div className="EditMembers-card__header__breadcrumbs__item">
            Edit profile
          </div>
        </div>
        <div className="EditMembers-card__header__editButtons">
          <div
            className={`EditMembers-card__header__editButton ${isEditMode === 'default' ? 'editButton__disabled' : ''} `}
          >
            <EditOutlined />
            Edit mode
          </div>
        </div>
      </div>
      <div className="EditMembers-card__main">
        <div className="EditMembers-card__aside">
          <div className="EditMembers-card__aside__wrapper">

            {(PageType === 'add') ? (
              <Steps
                direction="vertical"
                current={currentPage}
              >
                {steps.map((item) => (
                  <Step
                    title={item.title}
                    key={item.id}
                  />
                ))}

              </Steps>
            ) : (
              <>
                <CardMember
                  avatar={memberQuery.data?.user?.avatar}
                  firstName={memberQuery.data?.user?.firstName}
                  lastName={memberQuery.data?.user?.lastName}
                />
                <Menu
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  className="EditMembers-card__aside_menu"
                >
                  {steps.map((item) => (
                    <Menu.Item
                      key={item.id}
                      onClick={() => onCurrentPage(item.id)}
                    >
                      {item.title}
                    </Menu.Item>
                  ))}
                </Menu>
              </>
            )}
          </div>
        </div>
        <div className="EditMembers-card__form">
          <RouterLink to={`/members/profile/${memberQuery.data && memberQuery.data.id}`}>
            <LeftOutlined className="EditMembers-card__form__buttongroup__cancel_icon" />
            Back to profile
          </RouterLink>

          <Form
            {...layout}
            form={EditMemberForm}
            name="adminEditProfileForm"
            className="EditMembers-card__form__body"
          >

            {steps[currentPage].content}

            {(memberQuery.data?.user) && (
              <Form.Item {...titleLayout}>
                {(PageType === 'edit') ? (
                  (isEditMode === 'default') ? (
                    <RouterLink
                      to={{ state: 'edit' }}
                      className="EditMembers-card__form__footer__button"
                    >
                      Edit personal details
                    </RouterLink>
                  ) : (
                    <Form.Item
                      shouldUpdate
                      className="submit"
                    >
                      {() => (
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={
                            EditMemberForm.getFieldsError().filter(({ errors }) => errors.length).length > 0
                          }
                        >
                          Update personal details
                        </Button>
                      )}
                    </Form.Item>
                  )) : nextButton}
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </Card>
  );
};

export default EditMembers;
