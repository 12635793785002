import addMinutes from 'date-fns/addMinutes';
import set from 'date-fns/set';
import { useCallback } from 'react';
import {
  CreatedEventError,
  useCreateEvent,
} from '../../../../api/schedule';
import getUserFullName from '../../../../common/utils/getUserFullName';
import { User } from '../../../../graphql-generate/graphql-types';
import NoteItem, { ChannelType } from '../../interfaces/NoteItem';

export type NewCalendarEventOpts = {
    notes: NoteItem[];
    user: User,
};

export type CreatedEvent = {
  event: gapi.client.calendar.Event;
  note: NoteItem;
};

export type useCreateCalendarEventReturn = {
    newCalendarEvent: (params: NewCalendarEventOpts) => Promise<PromiseSettledResult<CreatedEvent>[]>
};

export function useCreateCalendarEvent() : useCreateCalendarEventReturn {
  const { mutateAsync: createEventAsync } = useCreateEvent();

  const newCalendarEvent = useCallback(({ notes, user } : NewCalendarEventOpts) => {
    const displayName = getUserFullName(user);
    const summary = `Follow up with ${displayName}`;
    const notesWithEvent = notes.filter(({ arrangeCall }) => arrangeCall);
    const member = {
      displayName,
      email: user.email,
    };

    return Promise.allSettled(notesWithEvent.map(async (note) => {
      const { channel = ChannelType.GoogleMeet, meetingDate, meetingTime, meetingUrl } = note;
      const startingDate: Date = meetingDate?.toDate() as Date;
      const startingTime: Date = meetingTime?.toDate() as Date;
      const startDate = set(startingDate, {
        hours: startingTime.getHours(),
        minutes: startingTime.getMinutes(),
        seconds: 0,
      });
      const endDate = addMinutes(startDate, 30);
      const googleMeet = channel === ChannelType.GoogleMeet;
      try {
        const event = await createEventAsync({
          summary,
          start: startDate,
          end: endDate,
          googleMeet,
          meetingUrl,
          member,
        });
        return Promise.resolve({
          event, note,
        });
      } catch (e) {
        // e could be an standard Google error or a Error from createEventAsync where meta would be the request raw response
        // we don't care what is the error so we always replace meta with note to give context to the caller

        const error = e as CreatedEventError<NoteItem>;
        error.meta = note;
        return Promise.reject(error);
      }
    }));
  }, [createEventAsync]);

  return { newCalendarEvent };
}
