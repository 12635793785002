import React, { useMemo } from 'react';
import './style.css';
import isNull from 'lodash/isNull';
import {
  useParams,
  useHistory,
  Link as RouterLink,
} from 'react-router-dom';
import {
  GlobalOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import { ReactComponent as CardPayIcon } from '../../assets/icons/card-pay.svg';
import { ReactComponent as ProfileCardIcon } from '../../assets/icons/profile-card.svg';
import { useAppSelector } from '../../store/store';
import Card from '../../components/Card/Card';
import AdminMainTab from './components/AdminMainTab/AdminMainTab';
import AdminMyTeamTab from './components/AdminMyTeamTab/AdminMyTeamTab';
import InfoAsideLayout from '../../components/InfoAsideLayout/InfoAsideLayout';
import Colors from '../../common/enums/Colors';
import hasAccess from '../../common/helpers/hasAccess';
import DetailInfo from '../../common/interfaces/DetailInfo';
import getUserFullName from '../../common/utils/getUserFullName';
import formatLocationFromMemberDetails from '../../common/utils/formatLocationFromMemberDetails';
import getAllUserRoles from '../../common/utils/getAllUserRoles';

interface AdminParams {
  tagId: string;
}

let newStaffMemberButton = (
  <RouterLink
    to={{ pathname: '/admin/account' }}
    className="admin-card__main__tabs__link"
  >
    + New Staff members
  </RouterLink>
);

const Admin = (): JSX.Element => {
  const history = useHistory();
  const { tagId } = useParams<AdminParams>();
  const user = useAppSelector(({ auth }) => auth.user);

  const tabs = useMemo(() => {
    const avaliableTabs = [
      {
        link: 'account',
        label: 'Account',
        component: <AdminMainTab user={user} />,
      },
    ];

    const myTeamTab = {
      link: 'team',
      label: 'My team',
      component: (<AdminMyTeamTab />),
    };

    if (user && hasAccess(user, 'SEE_MY_TEAM_MENU')) {
      avaliableTabs.push(myTeamTab);
    }

    return avaliableTabs;
  }, [user]);

  const details = useMemo((): DetailInfo[] => {
    if (!user) return [];
    const localTimeString: string | null = null;
    let location: string | null = null;
    if (user.city) {
      location = formatLocationFromMemberDetails(user.city.name, user.city.country?.name);
    }
    let email : string | null = null;
    if (user.email) {
      email = user.email;
    }

    return [
      {
        id: 'role',
        label: getAllUserRoles(user),
        icon: <ProfileCardIcon fill={Colors.ICONS_BASE_COLOR} />,
      },
      {
        id: 'location',
        label: location,
        icon: <GlobalOutlined />,
      },
      {
        id: 'localTimeString',
        label: localTimeString,
        icon: <ClockCircleOutlined />,
      },
      {
        id: 'email',
        label: email,
        icon: <CardPayIcon style={{ color: Colors.ICONS_BASE_COLOR }} />,
      },
    ];
  }, [user]);

  if (user && !hasAccess(user, 'ASSIGN_NEW_MEMBERS')) {
    newStaffMemberButton = <></>;
  }

  return (
    <Card
      float
      className="admin-card"
    >
      <div className="admin-card__header">
        <div className="admin-card__header__breadcrumbs">
          <div className="admin-card__header__breadcrumbs__item">
            {(tagId === 'team') ? ('My team') : ('Account')}
          </div>
        </div>
      </div>
      <div className="admin-card__content">
        <div className="admin-card__aside">
          <InfoAsideLayout
            loading={isNull(user)}
            avatarPalceholderType="user"
            details={details}
            avatar={user?.avatar}
            personTitle={getUserFullName(user)}
          />
        </div>
        <div className="admin-card__main">
          <div className="admin-card__main__tabs">
            <Tabs
              animated={{
                inkBar: false,
                tabPane: false,
              }}
              activeKey={tagId}
              onChange={(index) => history.replace(index)}
              tabBarExtraContent={{ right: newStaffMemberButton }}
              type="card"
            >
              {tabs.map((item) => (
                <Tabs.TabPane
                  key={item.link}
                  tab={item.label}
                >
                  <Card className="admin-card__main__content">
                    {item.component}
                  </Card>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Admin;
