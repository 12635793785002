export const options = {
  citizenship: [{
    id: '1', name: 'Citizenship',
  }],
  industrySector: [{
    id: '1', name: 'Industry sector',
  }],
  quallification: [{
    id: '1', name: 'Quallification',
  }],
  addSkills: [{
    id: '1', name: 'AddSkills',
  }],
  grade: [{
    id: '1', name: 'Grade',
  }],
  academicInstitution: [{
    id: '1', name: 'Academic institution',
  }],
  affiliationWithTheRoom: [{
    id: '1', name: 'Affiliation with The Room',
  }],
  afiliationEntity: [{
    id: '1', name: 'Afiliation Entity',
  }],
  affiliationCategory: [{
    id: '1', name: 'Affiliation Category',
  }],
  freeTrialNextSteps: [{
    id: '1', name: 'Free trial next steps',
  }],
  membershipType: [{
    id: '1', name: 'Membership Type',
  }],
  membershipLevel: [{
    id: '1', name: 'Membership Level',
  }],
  FLAGS: [{
    id: '1', name: 'FLAGS',
  }],
  pathfinder: [{
    id: '1', name: 'Pathfinder',
  }],
  customerSegment: [{
    id: '1', name: 'Customer Segment',
  }],
  missionOpportunitiesForm: [{
    id: '1', name: 'Mission/Opportunities form?',
  }],
  engagementLevel: [{
    id: '1', name: 'Engagement Level',
  }],
};
