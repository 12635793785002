import { loader } from 'graphql.macro';
import {
  Member,
  NoteConnection,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/queries/getMemberNotes.gql');

interface IGetMemberNotesArgs{
  id: number
}

interface INotesRequestData {
  member: Member
}

export default async function getMemberNotesConnection(memberId: number): Promise<NoteConnection | null> {
  const membersRequest = await graphQLClient.request<INotesRequestData, IGetMemberNotesArgs>(query, { id: memberId });
  return membersRequest.member.notes ?? null;
}
