import { loader } from 'graphql.macro';
import {
  UpdateMemberNotesInput,
  UpdateMemberNotesPayload,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/mutations/memberUpdateNotes.gql');

interface IUpdateMemberNotesInput{
  updateInput: UpdateMemberNotesInput;
}

export default async function updateMemberNotes(input: UpdateMemberNotesInput): Promise<UpdateMemberNotesPayload> {
  const notesMutationRequest = await graphQLClient.request<UpdateMemberNotesPayload, IUpdateMemberNotesInput>(query, { updateInput: input });
  return notesMutationRequest;
}
