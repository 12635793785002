enum Colors {
  ICONS_BASE_COLOR = '#3D4B57',
  ICONS_GREY_COLOR = '#999999',
  ICONS_LIGHT_GREY_COLOR = '#BDBDBD',
  ICONS_DARK_GREY_COLOR = '#828282',
  ICONS_KHAKI = '#BDAB8A',
  CONTLORS_BLUE_COLOR = '#1890FF',
  CONTLORS_LIGHT_BLUE_COLOR = '#40A9FF',
  WHITE = '#FFFFFF'

}

export default Colors;
