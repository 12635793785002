import './style.css';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Modal,
  Button,
  ModalProps,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  DataText,
  Text,
  Title,
} from '../../../../../../components/typography/typography';
import { ReactComponent as CompanyIcon } from '../../../../../../assets/icons/company-icon.svg';
import { ReactComponent as UserPlaceholderIcon } from '../../../../../../assets/icons/user-placeholder.svg';
import DetailInfo from '../../../../../../common/interfaces/DetailInfo';

interface ShareModalProps extends ModalProps {
  email: string;
  title: string,
  shareName: string,
  buttonText: string,
  description: string,
  details: DetailInfo[],
  id: string;
  typeModal: 'member' | 'opportunity';
  tags?: {
    id: number | string;
    name: string;
  }[],
  avatar?: React.ReactNode;
}

interface FormValues {
  channel: string;
  message?: string;
}

const ShareModal = ({ onOk, id, typeModal, details, title, buttonText, description, shareName, tags, email, avatar, ...rest }: ShareModalProps): JSX.Element => {
  const [shareModalForm] = Form.useForm<FormValues>();
  const [formValues, setFormValues] = useState<FormValues>();
  const [disabledButton, setDisabledButton] = useState(true);
  const [emptyField, setEmptyField] = useState(true);
  const [channelValidationEnabled, setChannelValidationEnabled] = useState<boolean>(false);

  useEffect(() => {
    shareModalForm.validateFields().then(() => {
      setDisabledButton(false);
    }).catch((data) => {
      if (data.errorFields.length !== 0) {
        setDisabledButton(true);
      }
    });
    if (shareModalForm.getFieldsValue().channel === '' || formValues?.channel === '') {
      setEmptyField(true);
    } else {
      setEmptyField(false);
    }
  }, [formValues, email, shareModalForm]);

  useEffect(() => {
    shareModalForm.setFieldsValue({ channel: email });
    setFormValues({ channel: email });
  }, [email, shareModalForm]);

  const onCloseModal = () => {
    shareModalForm.resetFields();
    setFormValues({ channel: email });
    shareModalForm.setFieldsValue({ channel: email });
  };

  return (
    <Modal
      {...rest}
      afterClose={onCloseModal}
      forceRender
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={disabledButton || emptyField}
          onClick={onOk}
        >
          {buttonText}
        </Button>,
      ]}
      className="base-modal share-modal"
      width={950}
      title={<Title modifiers={['italic', 'semiBold', 'grey']}>{title}</Title>}
    >
      <Row className="share-modal__content">
        <Col
          className="share-modal__side"
          span={12}
        >
          <Row>
            <Col className="share-modal__avatar-wrapper">
              <div className="share-modal__avatar">
                {
                  avatar || (
                    <div className="share-modal__avatar__container">
                      {(typeModal === 'member') ? (<UserPlaceholderIcon />) : (<CompanyIcon />)}
                    </div>
                  )
                }

              </div>
            </Col>
            <Col>
              <div className="share-modal__data">
                <div className="share-modal__title"><Title>{shareName}</Title></div>
                <div className="share-modal__details">
                  {details.map((detail) => (
                    <Row
                      className="share-modal__details-item"
                      key={detail.id}
                    >
                      <div className="share-modal__details-item__icon">{detail.icon}</div>
                      <div className="share-modal__details-item__label">
                        <DataText>{detail.label}</DataText>
                      </div>
                    </Row>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <div className="share-modal__description">{description}</div>
            <Row className="share-modal__tags">
              {tags?.map((item) => (
                <div
                  key={item.id}
                  className="share-modal__tags-item"
                >
                  {item.name}
                </div>
              ))}
            </Row>
          </div>
        </Col>
        <Col
          className="share-modal__side"
          span={12}
        >
          <Form
            onValuesChange={(_, values) => setFormValues(values)}
            form={shareModalForm}
            name={`shareModal-${id}`}
            className="share-modal__form"
            autoComplete="off"
          >
            <Form.Item className="share-modal__form-item__wrapper">
              <p><Text>Email</Text></p>
              <Form.Item
                name="channel"
                validateStatus={!channelValidationEnabled ? 'success' : undefined}
                help={!channelValidationEnabled ? '' : undefined}
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid email address',
                  },
                ]}
                className="email-special-validation"
              >
                <Input
                  placeholder="Enter email"
                  onBlur={() => setChannelValidationEnabled(true)}
                  onFocus={() => setChannelValidationEnabled(false)}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item className="share-modal__form-item__wrapper">
              <p><Text>Message (Optional)</Text></p>
              <Form.Item name="message">
                <TextArea
                  rows={7}
                  className="share-modal__textarea"
                />
              </Form.Item>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default ShareModal;
