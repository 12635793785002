import { FiltersList } from '../common/interfaces/FiltersList';
import getEntities from './common/graphql/requests/getEntities';

const Entities = {
  filters: {
    key: 'FILTERS_LIST',
    exec: async (): Promise<FiltersList> => getEntities(),
  },
};

export { Entities };
