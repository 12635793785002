import { loader } from 'graphql.macro';
import { FiltersList } from '../../../../common/interfaces/FiltersList';
import {
  City,
  Country,
  MembershipLevel,
  NeedType,
  Sector,
  Skill,
} from '../../../../graphql-generate/graphql-types';
import graphQLClient from '../base';
const query = loader('../documents/queries/getEntities.gql');

interface IEntitiesListData {
  sectors: Sector[],
  countries: Country[],
  cities: City[],
  skills: Skill[],
  needType: NeedType[],
  membershipLevel: MembershipLevel[]
}

export default async function getEntities(): Promise<FiltersList> {
  const filtersListQuery = await graphQLClient.request<IEntitiesListData>(query);
  return { ...filtersListQuery };
}
