import './style.css';
import React from 'react';
import { ReactComponent as CopyrightIcon } from '../../../assets/icons/copyright.svg';

const LayoutFooter = (): JSX.Element => (
  <footer className="footer">
    <CopyrightIcon />
    <span>The Room 2021</span>
  </footer>
);

export default LayoutFooter;
