import React from 'react';
import { Skeleton } from 'antd';
import Card from '../../../Card/Card';
import '../../style.css';

const CarouselShimmer = (): JSX.Element => (
  <Card
    className="slider-card default-card__float"
  >
    <Skeleton
      active
      avatar={{ size: 'large' }}
      paragraph={{ rows: 3 }}
    />
    <Skeleton
      active
      title={false}
      paragraph={{ rows: 3 }}
    />
  </Card>
);

export default CarouselShimmer;
