import {
  Career,
  Member,
} from '../../graphql-generate/graphql-types';

const getCurrentCareer = (member: Member): Career | null => {
  if (!member.careers || member.careers?.length === 0) return null;
  const sortedCareers = [...member.careers].sort((a, b) => {
    const firstDate = new Date(a.endDate).getTime();
    const secondDate = new Date(b.endDate).getTime();
    return secondDate - firstDate;
  });
  return sortedCareers[0];
};

export default getCurrentCareer;
