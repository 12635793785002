import React from 'react';
import { Text } from '../../../../../../../../components/typography/typography';

interface ProfileColumnItemProps {
  title: string;
  text?: string | null;
}

const ProfileColumnItem = ({ title, text }: ProfileColumnItemProps): JSX.Element => (
  <div className="profile-card__content__description-column__item">
    <div className="profile-card__content__description-column__subtitle">
      <Text>{title}</Text>
    </div>
    <div className="profile-card__content__description-column__description">
      <Text modifiers={['thin']}>{text}</Text>
    </div>
  </div>
);

export default ProfileColumnItem;
