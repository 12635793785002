import React from 'react';
import './style.css';
import { Divider } from 'antd';
import {
  Title,
  Text,
  DataText,
} from '../typography/typography';
import { ReactComponent as CompanyIcon } from '../../assets/icons/company-icon.svg';
import { ReactComponent as UserPlaceholderIcon } from '../../assets/icons/user-placeholder.svg';
import DetailInfo from '../../common/interfaces/DetailInfo';
import InfoAsideLayoutShimmer from './components/InfoAsideLayoutShimmer/InfoAsideLayoutShimmer';

interface InfoAsideLayoutProps {
  loading?: boolean;
  personTitle: string;
  details?: DetailInfo[];
  avatarPalceholderType: 'user' | 'opportunity';
  tagLists?: {
    title: string,
    tags: { id: number | string, name: string }[]
  }[];
  avatar?: string | null;
}

const InfoAsideLayout = ({
  loading,
  details,
  tagLists,
  avatarPalceholderType,
  personTitle,
  avatar,
}: InfoAsideLayoutProps): JSX.Element => (
  loading ? <InfoAsideLayoutShimmer /> : (
    <>
      <div className="InfoAsideLayout-card__person">
        <div className="InfoAsideLayout-card__person__image">
          {avatar ? (
            <img
              src={avatar}
              alt=""
            />
          ) : (
            (avatarPalceholderType === 'user') ? (
              <UserPlaceholderIcon />
            ) : (
              <CompanyIcon />
            )
          )}
        </div>
        <div className="InfoAsideLayout-card__person__title">
          <Title>{personTitle}</Title>
        </div>
        <div className="InfoAsideLayout-card__person__details">
          {details?.map((detail) => (
            <div
              key={detail.id}
              className="InfoAsideLayout-card__person__details-item"
            >
              <div className="InfoAsideLayout-card__person__details-item__icon">
                {detail.icon}
              </div>
              <div className="InfoAsideLayout-card__person__details-item__text">
                <DataText>{detail.label}</DataText>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Divider />
      {
        tagLists?.map((tagList, index) => (
          <div key={tagList.title}>
            <div
              className="InfoAsideLayout-card__skills"
            >
              <div className="InfoAsideLayout-card__section__title">
                <Text modifiers={['bold']}>{tagList.title}</Text>
              </div>
              <ul className="InfoAsideLayout-card__skills__list">
                {tagList.tags?.map((item) => (
                  <li
                    key={item.id}
                    className="tag-label"
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
            { (index !== tagLists.length - 1) ? <Divider /> : null}
          </div>
        ))
      }
    </>
  )
);
export default InfoAsideLayout;
