import './style.css';
import React, {
  useMemo,
  useState,
} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  Divider,
  Input,
  Pagination,
} from 'antd';
import { chunk } from 'lodash';
import { useQuery } from 'react-query';
import SelectMenu from '../../../../components/SelectMenu/SelectMenu';
import ProfileNoteListCard from '../ProfileNotesListCard/ProfileNotesListCard';
import ListNoResults from '../../../../components/ListNoResults/ListNoResults';
import ProfileNotesListCardShimmer from '../ProfileNotesListCardShimmer/ProfileNotesListCardShimmer';
import {
  NoteConnection,
  NoteType,
} from '../../../../graphql-generate/graphql-types';
interface ProfileNotesTabProps {
  fetchActivities: {
    key: string,
    exec: () => Promise<NoteConnection | null>
  };
  notesType: NoteType;
  showTags?: boolean;
  showFilters?: boolean;
}

const filtersSelectList = [
  {
    label: 'Most recent',
    value: '0',
  },
  {
    label: 'New',
    value: '1',
  },
];

const skeletonNotesList = ([1, 2, 3, 4, 5, 6, 7].map((item) => <ProfileNotesListCardShimmer key={item} />));

const ProfileNotesTab = ({
  fetchActivities,
  notesType,
  showTags,
  showFilters,
}: ProfileNotesTabProps): JSX.Element => {
  const PAGE_SIZE = 5;
  const title = notesType === NoteType.Activity ? 'activities' : 'needs';
  const [currentPage, setCurrentPage] = useState(1);
  const notesQuery = useQuery(fetchActivities.key, fetchActivities.exec);

  const computedActivitiesList = useMemo(() => {
    if (!notesQuery.data) return [];
    const notesNodes = notesQuery.data?.edges
      .map((edge) => edge.node)
      .filter((note) => note.type === notesType);
    return chunk(notesNodes, PAGE_SIZE);
  }, [notesQuery.data, notesType]);

  const totalCount = useMemo(() => computedActivitiesList.flat().length, [computedActivitiesList]);

  const changePaginationPage = (value: number) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  return (
    <div>
      <div className="profile-card__main__content__wrapper">
        {notesQuery.isLoading || totalCount !== 0 ? (
          <div className="profile-card__activity">
            <div className="profile-card__activity__filters">
              {showFilters ? (
                <SelectMenu
                  list={filtersSelectList}
                  className="profile-card__activity__filters__select"
                />
              ) : null}
              {notesQuery.data ? (
                <Input
                  className="profile-card__activity__filters__input"
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                />
              ) : null }

            </div>
            <ul className="profile-card__activity__list">
              {
                notesQuery.status === 'loading' && !totalCount ? (skeletonNotesList) : (
                  computedActivitiesList[currentPage - 1]?.map((item, index) => (
                    <ProfileNoteListCard
                      key={item.id}
                      activity={item}
                      showTags={showTags}
                      hideDivider={index === (computedActivitiesList[currentPage - 1].length) - 1}
                    />
                  ))
                )
              }
            </ul>
          </div>
        ) : (
          <ListNoResults text={`Member has no ${title}`} />
        )}
      </div>
      <Divider />
      <footer className="profile-card__content__footer">
        <div>
          <Pagination
            hideOnSinglePage
            className="profile-card__content__list-pagination"
            pageSize={PAGE_SIZE}
            onChange={changePaginationPage}
            current={currentPage}
            total={totalCount}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        </div>
      </footer>
    </div>
  );
};

export default ProfileNotesTab;
