import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import './style.css';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  Radio,
  Pagination,
} from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { chunk } from 'lodash';
import { useQuery } from 'react-query';
import { ReactComponent as TerminalIcon } from '../../../../assets/icons/terminal.svg';
import ProfileMembersListCard from '../ProfileMembersListCard/ProfileMembersListCard';
import ProfileRecommendationsListCard from '../ProfileRecommendationsListCard/ProfileRecommendationsListCard';
import ShareModal from '../ProfileRecommendationsListCard/components/ShareModal/ShareModal';
import Opportunity from '../../../../common/interfaces/Opportunity';
import DetailInfo from '../../../../common/interfaces/DetailInfo';
import MembersListShimmer from '../../../../components/MembersListShimmer/MembersListShimmer';
import ViewMemberModal from '../ViewMemberModal/ViewMemberModal';
import ProfileRecommendationsListCardShimmer from '../ProfileRecommendationsListCardShimmer/ProfileRecommendationsListCardShimmer';
import { Member } from '../../../../graphql-generate/graphql-types';
import formatLocationFromMemberDetails from '../../../../common/utils/formatLocationFromMemberDetails';
import getCurrentCareer from '../../../../common/utils/getCurrentCareer';
import getUserFullName from '../../../../common/utils/getUserFullName';

const dummyRating = 9.5;

interface ProfileRecommendationsTabProps {
  email: string;
  fetchMembers: {
    key: string,
    exec: () => Promise<Member[]>;
  };
  showShareButton?: boolean;
  fetchRecommendations: {
    key: string,
    exec: () => Promise<Opportunity[]>;
  };
}

const ProfileRecommendationsTab = ({
  email,
  fetchMembers,
  fetchRecommendations,
  showShareButton,
}: ProfileRecommendationsTabProps): JSX.Element => {
  const membersQuery = useQuery(fetchMembers.key, fetchMembers.exec);
  const recommendationsQuery = useQuery(fetchRecommendations.key, fetchRecommendations.exec);
  const PAGE_SIZE = 10;
  // Share member modal
  const [showShareMember, setShowShareMember] = useState(false);
  const [shareDataMember, setShareDataMember] = useState<Member | null>(null);
  // Share Opportunity modal
  const [showShareOpportunity, setShowShareOpportunity] = useState(false);
  const [shareDataOpportunity, setShareDataOpportunity] = useState<Opportunity>();
  // Show view member modal
  const [showViewMember, setShowViewMember] = useState(false);
  const [viewIdMember, setViewIdMember] = useState<string | null>(null);

  const [PageType, setPageType] = useState<'opportunities' | 'members'>('opportunities');
  const [currentPage, setCurrentPage] = useState(1);
  const currentResultsLength = useMemo<number>(() => {
    if (PageType === 'opportunities') {
      return recommendationsQuery.data?.length ?? 0;
    }
    return membersQuery.data?.length ?? 0;
  }, [PageType, membersQuery.data, recommendationsQuery.data]);
  const computedMemberList = useMemo(() => chunk(membersQuery.data, PAGE_SIZE), [PAGE_SIZE, membersQuery.data]);
  const computedRecommendationsList = useMemo(() => chunk(recommendationsQuery.data, PAGE_SIZE), [PAGE_SIZE, recommendationsQuery.data]);

  useEffect(() => setCurrentPage(1), [PageType]);

  const changePaginationPage = (value: number) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const onPageType = (e: RadioChangeEvent) => {
    setPageType(e.target.value);
  };

  const onShowViewMember = (member: Member) => {
    setShowViewMember(true);
    setViewIdMember(String(member.id));
  };

  const onShowShareMember = (member: Member) => {
    setShowShareMember(true);
    setShareDataMember(member);
  };

  const onShowShareOpportunity = (opportunity: Opportunity) => {
    setShowShareOpportunity(true);
    setShareDataOpportunity(opportunity);
  };

  const memberAvatar = (
    shareDataMember?.user?.avatar ? (
      <img
        className="members-list-card__avatar__image"
        src={shareDataMember?.user?.avatar}
        alt={`${getUserFullName(shareDataMember.user)}'s avatar`}
      />
    ) : (
      null
    )
  );

  const opportunityAvatar = (
    shareDataOpportunity?.companyLogo ? (
      <img
        className="members-list-card__avatar__image"
        src={shareDataOpportunity?.companyLogo}
        alt={`${shareDataOpportunity?.companyName}'s avatar`}
      />
    ) : (
      null
    )
  );

  const shareModalDetailsMember = useMemo(() : DetailInfo[] => {
    let location: string | null = null;
    if (!shareDataMember) return [];
    const shareMemberCurrentCareer = getCurrentCareer(shareDataMember);
    if (shareDataMember?.user?.city) {
      location = formatLocationFromMemberDetails(shareDataMember.user.city.name, shareDataMember.user.city.country?.name);
    }

    return [
      {
        id: 'job_name',
        label: shareMemberCurrentCareer?.jobTitle ?? '',
        icon: <TerminalIcon />,
      },
      {
        id: 'location',
        label: location,
        icon: <GlobalOutlined />,
      },
    ];
  }, [shareDataMember]);

  const shareModalDetailsOpportunity: DetailInfo[] = [
    {
      id: 'job_name',
      label: shareDataOpportunity?.companyName ?? '',
      icon: <TerminalIcon />,
    },
    {
      id: 'location',
      label: shareDataOpportunity?.companyCity ?? '',
      icon: <GlobalOutlined />,
    },
  ];

  let renderList: React.ReactNode = null;
  if (PageType === 'opportunities') {
    if (recommendationsQuery.status === 'success') {
      renderList = computedRecommendationsList[currentPage - 1]?.map((item) => (
        <ProfileRecommendationsListCard
          onShowShare={onShowShareOpportunity}
          opportunity={item}
          key={item.id}
        />
      ));
    } else if (recommendationsQuery.status === 'loading') {
      renderList = [1, 2, 3, 4, 5, 6, 7].map((item) => <ProfileRecommendationsListCardShimmer key={item} />);
    }
  }
  if (PageType === 'members') {
    if (membersQuery.status === 'success') {
      renderList = computedMemberList[currentPage - 1]?.map((item) => (
        <ProfileMembersListCard
          onShowView={onShowViewMember}
          onShowShare={onShowShareMember}
          showShareButton={showShareButton}
          rating={dummyRating}
          member={item}
          key={item.id}
        />
      ));
    } else if (membersQuery.status === 'loading') {
      renderList = <MembersListShimmer />;
    }
  }

  return (
    <div>
      <div className="profile-card__main__content__wrapper">
        <div className="profile-card__main__content">
          <div className="profile-card__recommendation">
            <div className="profile-card__recommendation__filters">
              {process.env.REACT_APP_BETA_VERSION_FEATURES === 'enable' ? (
                <Radio.Group
                  onChange={onPageType}
                  defaultValue="opportunities"
                  className="radio__group__button"
                >
                  <Radio.Button value="opportunities">
                    Opportunities
                  </Radio.Button>
                  <Radio.Button value="members">
                    Members
                  </Radio.Button>
                </Radio.Group>
              ) : null}

              <span className="profile-card__recommendation__filters_show">
                {'showing '}
                {currentResultsLength}
                {' results'}
              </span>
            </div>
            <ul className="profile-card__recommendation__list">
              {renderList}
              <ViewMemberModal
                onOk={() => setShowViewMember(false)}
                onCancel={() => setShowViewMember(false)}
                userId={viewIdMember ?? '1'}
                visible={showViewMember}
              />
              <ShareModal
                id="Member"
                onOk={() => setShowShareMember(false)}
                onCancel={() => setShowShareMember(false)}
                visible={showShareMember}
                title="Share Member profile"
                buttonText="Share member"
                typeModal="member"
                email={email}
                shareName={getUserFullName(shareDataMember?.user)}
                avatar={memberAvatar}
                description={shareDataMember?.bio ?? ''}
                details={shareModalDetailsMember}
                tags={shareDataMember?.skills ?? []}
              />
              <ShareModal
                id="Opportunity"
                onOk={() => setShowShareOpportunity(false)}
                onCancel={() => setShowShareOpportunity(false)}
                visible={showShareOpportunity}
                title="Share Opportunity profile"
                buttonText="Share opportunity"
                typeModal="opportunity"
                email={email}
                shareName={`${shareDataOpportunity?.title}`}
                avatar={opportunityAvatar}
                description={shareDataOpportunity?.description ?? ''}
                details={shareModalDetailsOpportunity}
                tags={shareDataOpportunity?.tags}
              />
            </ul>
          </div>
        </div>
      </div>
      <footer className="profile-card__content__footer">
        <div>
          <Pagination
            hideOnSinglePage
            total={currentResultsLength}
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={changePaginationPage}
            className="profile-card__content__list-pagination"
          />
        </div>
      </footer>
    </div>
  );
};

export default ProfileRecommendationsTab;
