import { User } from '../../graphql-generate/graphql-types';

export default function getUserFullName(user?: User | null): string {
  if (!user) return '';
  const { firstName, lastName } = user;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  return '';
}
