import './style.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { Text } from '../typography/typography';
import { ReactComponent as SkyscraperIcon } from '../../assets/icons/skyscraper.svg';
import { ReactComponent as IdCardIcon } from '../../assets/icons/id-card.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share-button.svg';
import { ReactComponent as UserPlaceholderIcon } from '../../assets/icons/user-placeholder.svg';
import getCuttedText from '../../common/utils/getCuttedText';
import Colors from '../../common/enums/Colors';
import DetailInfo from '../../common/interfaces/DetailInfo';
import { Member } from '../../graphql-generate/graphql-types';
import getCurrentCareer from '../../common/utils/getCurrentCareer';
import getUserFullName from '../../common/utils/getUserFullName';

interface MembersListCardProps {
  onShowShare?: () => void;
  onShowView?: () => void;
  member: Member;
  rating?: number;
  showShareButton?: boolean;
}

type OptionsDetail = DetailInfo & { bold?: boolean };

interface Options {
  description: string;
  imageURL: string;
  skills: Member['skills'];
  details: OptionsDetail[];
}

const MembersListCard = ({
  onShowShare,
  onShowView,
  member,
  rating,
  showShareButton,
}: MembersListCardProps): JSX.Element => {
  const memberCurrentCareer = getCurrentCareer(member);
  const options: Options = {
    imageURL: member.user?.avatar ?? '',
    description: member.bio ?? '',
    skills: member.skills ?? [],
    details: [
      {
        id: 'name',
        icon: <IdCardIcon style={{ color: Colors.ICONS_GREY_COLOR }} />,
        label: getUserFullName(member.user),
        bold: true,
      },
      {
        id: 'job_name',
        icon: <IdCardIcon style={{ color: Colors.ICONS_GREY_COLOR }} />,
        label: memberCurrentCareer?.jobTitle ?? '',
      },
      {
        id: 'company_name',
        icon: <SkyscraperIcon style={{ color: Colors.ICONS_GREY_COLOR }} />,
        label: memberCurrentCareer?.companyName ?? '',
      },
      {
        id: 'city',
        icon: <GlobalOutlined style={{ color: Colors.ICONS_GREY_COLOR }} />,
        label: member.user?.city?.name ?? '',
      },
    ],
  };

  const memberAvatar = (
    options.imageURL ? (
      <img
        className="members-list-card__avatar__image"
        src={options.imageURL}
        alt={`${getUserFullName(member.user)}'s avatar`}
      />
    ) : (
      null
    )
  );

  let additionalButton: React.ReactNode | null = null;
  if (showShareButton) {
    additionalButton = (
      <button
        type="button"
        onClick={onShowShare}
        className="members-list-card__wrap-buttons__contacts"
      >
        <ShareIcon />
      </button>
    );
  }

  return (
    <div className="members-list-card">
      <div className="members-list-card__avatar">
        {memberAvatar || <UserPlaceholderIcon />}
        {(rating) && (
          <div className="members-list-card_rating">
            <Text modifiers={['gold']}>{rating}</Text>
          </div>
        )}
      </div>
      <ul className="members-list-card__details">
        {options.details.map((detail) => (
          <li
            key={detail.id}
            className={`${detail.bold ? 'members-list-card__details__main' : ''
            }`}
          >
            <div className="members-list-card__details__icon">
              {detail.icon}
            </div>
            <div className="members-list-card__details__name">
              {detail.bold ? (
                <Text modifiers={['semiBold']}>{detail.label}</Text>
              ) : (
                <Text>{detail.label}</Text>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="members-list-card__wrap">
        <p className="members-list-card__wrap__title">
          <Text>{getCuttedText(options.description, 250)}</Text>
        </p>
        <ul className="members-list-card__wrap__technology-list">
          {options.skills ? options.skills.slice(0, 3).map((skill) => (
            <li
              key={skill.id}
              className="members-list-card__wrap__technology-list__item"
            >
              {skill.name}
            </li>
          )) : null}
        </ul>
      </div>
      <div className="members-list-card__wrap-buttons">
        <div className="members-list-card__wrap-buttons_up">
          {additionalButton}
        </div>
        {(onShowView) ? (
          <Button
            onClick={onShowView}
            className="members-list-card__wrap-buttons__view-button"
          >
            View
          </Button>
        ) : (
          <Link
            className="members-list-card__wrap-link"
            to={`/members/profile/${member.id}`}
          >
            View
          </Link>
        )}
      </div>
    </div>
  );
};

export default MembersListCard;
