import './style.css';
import React from 'react';
export interface CardProps {
  children?: React.ReactNode;
  className?: string,
  float?: boolean,
  white?: boolean
}

const Card = (props: CardProps): JSX.Element => {
  const { className, children } = props;
  const classList = ['default-card'];

  const propsKeys = Object.keys(props) as Array<keyof CardProps>;
  const modifiers = propsKeys
    .filter((key) => typeof props[key] === 'boolean')
    .map((modifier) => `default-card__${modifier}`);

  let classString = classList.join(' ');
  if (className) classString += ` ${className}`;

  return (
    <div className={[classString, ...modifiers].join(' ')}>
      {children}
    </div>
  );
};

export default Card;
