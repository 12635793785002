import React, {
  useState,
  useMemo,
} from 'react';
import './style.css';
import { GlobalOutlined } from '@ant-design/icons';
import { ReactComponent as CakeIcon } from '../../../../assets/icons/cake.svg';
import { ReactComponent as ProfileCardIcon } from '../../../../assets/icons/profile-card.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/icons/share-button.svg';
import Opportunity from '../../../../common/interfaces/Opportunity';
import DetailInfo from '../../../../common/interfaces/DetailInfo';
import ViewOpportunityModal from './components/ViewOpportunityModal/ViewOpportunityModal';

export interface ProfileRecommendationsListCardProps {
  opportunity: Opportunity;
  onShowShare: (opportunity: Opportunity) => void;
}

const ProfileRecommendationListCard = ({ opportunity, onShowShare }: ProfileRecommendationsListCardProps): JSX.Element => {
  const { title, companyCity, companyName, created, opportunityType, companyStatus, sharedByMember } = opportunity;

  const [showViewOpportunity, setShowViewOpportunity] = useState(false);

  const details = useMemo((): DetailInfo[] => {
    if (!opportunity) return [];

    return [
      {
        id: 'companyName',
        label: opportunity.companyName,
        icon: <ProfileCardIcon />,
      },
      {
        id: 'companyJob',
        label: opportunity.companyJob || null,
        icon: <ProfileCardIcon />,
      },
      {
        id: 'companyCity',
        label: opportunity.companyCity,
        icon: <GlobalOutlined />,
      },
      {
        id: 'companyStatus',
        label: opportunity.companyStatus || null,
        icon: <CakeIcon />,
      },
      {
        id: 'tillStatus',
        label: `${opportunity?.companyStatus} till ${opportunity?.created}` || null,
        icon: <CakeIcon />,
      },
    ];
  }, [opportunity]);

  return (
    <li className="profile-card__recommendation__list-item">
      <ViewOpportunityModal
        details={details}
        opportunity={opportunity}
        onOk={() => setShowViewOpportunity(false)}
        onCancel={() => setShowViewOpportunity(false)}
        visible={showViewOpportunity}
      />
      <div className="profile-card__recommendation__list-item_opportunity">
        <span className="profile-card__recommendation__list-item_title">{title}</span>
        <span className="profile-card__recommendation__list-item_text">{opportunityType}</span>
      </div>
      <div className="profile-card__recommendation__list-item_organisation">
        <span className="profile-card__recommendation__list-item_title">{companyName}</span>
        <span className="profile-card__recommendation__list-item_text">{companyCity}</span>
      </div>
      <div className="profile-card__recommendation__list-item_status">
        <span className="profile-card__recommendation__list-item_title">
          {companyStatus}
          {' till'}
        </span>
        <span className="profile-card__recommendation__list-item_text">{created}</span>
      </div>
      <div className="profile-card__recommendation__list-item_buttons">
        {(!sharedByMember) && (
          <button
            onClick={() => onShowShare(opportunity)}
            aria-label="Share icon"
            type="button"
            className="profile-card__recommendation_button_share"
          >
            <ShareIcon />
          </button>
        )}
        <button
          onClick={() => setShowViewOpportunity(true)}
          type="button"
          className="profile-card__recommendation_button"
        >
          View
        </button>
      </div>
    </li>
  );
};

export default ProfileRecommendationListCard;
